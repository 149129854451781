import { ErrorHandlerService } from './../errorHandling/error-handling.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, retry, timeout, catchError, Observable, throwError, of, from, mergeMap } from 'rxjs';
import { BuildingBlock } from '../modules/shared/model/building-block';
import { Employee } from '../modules/shared/model/employee';
import { Configuration } from 'webpack';
import { WorkQ } from '../modules/shared/model/work-q';
import { Recipe } from '../modules/shared/model/recipe';
import { HttpErrorHandler } from 'app/common/errorHandler';
import { ValidationTeam } from 'app/modules/shared/model/validation-team';
import { BoardType } from 'app/modules/shared/model/board-type';
import { LabCreate } from 'app/modules/shared/model/lab';
import { ToolsIntegration } from "app/modules/shared/model/tools-integration";
import { BuildingBlockType } from 'app/modules/shared/model/building-block-type';
import { BuildingBlockModule } from 'app/modules/shared/model/building-block-module';
import { Product } from 'app/modules/shared/model/product';
import { RmEmail } from 'app/modules/shared/model/rm-email';

import { Person } from 'app/modules/shared/model/person';
import { Config } from 'app/modules/shared/model/config';
import { RM_NotificationService } from 'app/common/notificationService';
import { AccessToken } from 'app/modules/shared/model/access-token';
import { AuthenticationHeader } from 'app/common/authentication-header';

@Injectable({
  providedIn: 'root'
})

export class CreateGateway extends AuthenticationHeader {
  private baseUrl = environment.services.environmentPrefix;
  private createUrl = environment.services.CreateUrl;

  constructor(private _http: HttpClient, public httpErrorHandler: HttpErrorHandler, public notificationService: RM_NotificationService) {
    super(_http);
  }

  public getHeader(): any {
    return this.header;
  }

  public getBuildingBlock(id: string, createdByWwid: string, supressNotification = false): Observable<BuildingBlock> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<BuildingBlock>(`${this.baseUrl}${this.createUrl}/buildingBlocks/${id}?createdByWwid=${createdByWwid}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlock: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlock. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createBuildingBlock(buildingBlock: BuildingBlock, supressNotification = false): Observable<BuildingBlock> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      console.log('createBuildingBlock: ' + JSON.stringify(buildingBlock.checkList) );
      return this._http.post<BuildingBlock>(`${this.baseUrl}${this.createUrl}/buildingBlocks/create`, buildingBlock, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createBuildingBlock: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createBuildingBlock. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateBuildingBlock(loadedItem: BuildingBlock, updates: any[], updateRecipes: boolean, supressNotification = false): Observable<BuildingBlock> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<BuildingBlock>(`${this.baseUrl}${this.createUrl}/buildingBlocks/${loadedItem.id}?createdByWwid=${loadedItem.createdBy.wwid}&updateRecipes=${updateRecipes}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateBuildingBlock: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateBuildingBlock. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteBuildingBlock(buildingBlock: BuildingBlock, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      const options = {
        headers: this.header,
        body: {
          email: buildingBlock.updatedBy.email,
          name: buildingBlock.updatedBy.name,
          wwid: buildingBlock.updatedBy.wwid
        },
      };
      var url = `${this.baseUrl}${this.createUrl}/buildingBlocks/${buildingBlock.id}?createdByWwid=${buildingBlock.createdBy.wwid}`;
      return this._http.delete(url, options)
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteBuildingBlock: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteBuildingBlock. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public getRecipe(id: string, createdByWwid: string, supressNotification = false): Observable<Recipe> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Recipe>(`${this.baseUrl}${this.createUrl}/recipes/${id}?createdByWwid=${createdByWwid}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getRecipe: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in getRecipe. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createRecipe(recipe: Recipe, supressNotification = false): Observable<Recipe> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
 //     console.log(JSON.stringify(recipe));
      return this._http.post<Recipe>(`${this.baseUrl}${this.createUrl}/recipes/create`, recipe, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createRecipe: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createRecipe. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateRecipe(loadedItem: Recipe, updates: any[], supressNotification = false): Observable<Recipe> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<Recipe>(`${this.baseUrl}${this.createUrl}/recipes/${loadedItem.id}?createdByWwid=${loadedItem.createdBy.wwid}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`UpdateRecipe: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateRecipe. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteRecipe(recipe: Recipe, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      const options = {
        headers: this.header,
        body: {
          email: recipe.updatedBy.email,
          name: recipe.updatedBy.name,
          wwid: recipe.updatedBy.wwid
        },
      };
      var url = `${this.baseUrl}${this.createUrl}/recipes/${recipe.id}?createdByWwid=${recipe.createdBy.wwid}`
      return this._http.delete(url, options)
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteRecipe: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteRecipe. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createWorkQ(workQCase: WorkQ, supressNotification = false): Observable<any> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<WorkQ>(`${this.baseUrl}${this.createUrl}/workq/create`, workQCase, {headers: this.header})
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
				        this.notificationService.addErrorNotification(`createWorkQ: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
			      console.log('Caught in createWorkQ. Returning 0');
            return throwError(() => error);
          }));
    }));
  }

  updateWorkQ(loadedItem: WorkQ, updates: any[], supressNotification = false): Observable<WorkQ> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<WorkQ>(`${this.baseUrl}${this.createUrl}/workq/${loadedItem.id}?createdByWwid=${loadedItem.createdBy.wwid}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateWorkQ: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateWorkQ. Returning 0');
            return throwError(() => error);
          }));
    }));
  }

  public deleteWorkQ(workq: WorkQ, supressNotification = false): Observable<boolean> {
    var url = `${this.baseUrl}${this.createUrl}/workq/${workq.id}?createdByWwid=${workq.createdBy.wwid}`
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      const options = {
        headers: this.header,
        body: {
          email: workq.updatedBy.email,
          name: workq.updatedBy.name,
          wwid: workq.updatedBy.wwid
        },
      };
      return this._http.delete<boolean>(url, options)
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteWorkQ: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteWorkQ. Returning 0');
            return throwError(() => error);
          }));
    }));
  }

  public getConfiguration(ownerWwid: string, supressNotification = true): Observable<Config> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Config>(`${this.baseUrl}${this.createUrl}/configuration/${ownerWwid}`, { headers: this.header, })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getConfiguration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in getConfiguration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createConfiguration(userConfiguration: Config, supressNotification = true): Observable<Config> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<Config>(`${this.baseUrl}${this.createUrl}/configuration/create`, userConfiguration, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createConfiguration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createConfiguration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateConfiguration(id: string, updates: any[], supressNotification = true) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<Config>(`${this.baseUrl}${this.createUrl}/configuration/${id}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateConfiguration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateConfiguration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteConfiguration(userConfiguration: Config, supressNotification = false): Observable<boolean> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete<boolean>(`${this.baseUrl}${this.createUrl}/configuration/${userConfiguration.id}`, { body: userConfiguration.updatedBy, headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateConfiguration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateConfiguration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createBoardType(boardType: BoardType, supressNotification = false): Observable<BoardType> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      console.log('boardType', JSON.stringify(boardType));
      return this._http.post<BoardType>(`${this.baseUrl}${this.createUrl}/boardtype/create`, boardType, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createBoardType: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createBoardType. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateBoardType(id: string, updates: any[], supressNotification = false): Observable<BoardType> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<BoardType>(`${this.baseUrl}${this.createUrl}/boardtype/${id}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateBoardType: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateBoardType. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteBoardType(boardType: BoardType, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete(`${this.baseUrl}${this.createUrl}/boardtype/${boardType.id}`, { body: boardType.updatedBy, headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteBoardType: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteBoardType. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createBuildingBlockType(buildingBlockType: BuildingBlockType, supressNotification = false): Observable<BuildingBlockType> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<BuildingBlockType>(`${this.baseUrl}${this.createUrl}/buildingblocktype/create`, buildingBlockType, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createBuildingBlockType: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createBuildingBlockType. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateBuildingBlockType(id: string, updates: any[], supressNotification = false): Observable<BuildingBlockType> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<BuildingBlockType>(`${this.baseUrl}${this.createUrl}/buildingblocktype/${id}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateBuildingBlockType: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateBuildingBlockType. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteBuildingBlockType(buildingBlockType: BuildingBlockType, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete(`${this.baseUrl}${this.createUrl}/buildingblocktype/${buildingBlockType.id}`, { body: buildingBlockType.updatedBy, headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteBuildingBlockType: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteBuildingBlockType. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createBuildingBlockModule(buildingBlockModule: BuildingBlockModule, supressNotification = false): Observable<BuildingBlockModule> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<BuildingBlockModule>(`${this.baseUrl}${this.createUrl}/buildingblockmodule/create`, buildingBlockModule, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createBuildingBlockModule: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createBuildingBlockModule. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateBuildingBlockModule(id: string, updates: any[], supressNotification = false): Observable<BuildingBlockModule> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<BuildingBlockModule>(`${this.baseUrl}${this.createUrl}/buildingblockmodule/${id}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateBuildingBlockModule: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateBuildingBlockModule. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteBuildingBlockModule(buildingBlockModule: BuildingBlockModule, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete(`${this.baseUrl}${this.createUrl}/buildingblockmodule/${buildingBlockModule.id}`, { body: buildingBlockModule.updatedBy, headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteBuildingBlockModule: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteBuildingBlockModule. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createLab(lab: LabCreate, supressNotification = false): Observable<LabCreate> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<LabCreate>(`${this.baseUrl}${this.createUrl}/lab/create`, lab, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`createLab: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in createLab. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public updateLab(id: string, updates: any[], supressNotification = false): Observable<LabCreate> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<LabCreate>(`${this.baseUrl}${this.createUrl}/lab/${id}`, updates, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`updateLab: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in updateLab. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public deleteLab(lab: LabCreate, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete(`${this.baseUrl}${this.createUrl}/lab/${lab.id}`, { body: lab.updatedBy, headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`deleteLab: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in deleteLab. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public createProduct(product: Product, supressNotification = false): Observable<Product> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<Product>(`${this.baseUrl}${this.createUrl}/product/create`, product, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`createProduct: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in createProduct. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public updateProduct(id: string, updates: any[], supressNotification = false): Observable<Product> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<Product>(`${this.baseUrl}${this.createUrl}/product/${id}`, updates, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`updateProduct: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in updateProduct. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public deleteProduct(product: Product, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete(`${this.baseUrl}${this.createUrl}/product/${product.id}`, { body: product.updatedBy, headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`deleteProduct: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in deleteProduct. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public createValidationTeam(team: ValidationTeam, supressNotification = false): Observable<ValidationTeam> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<ValidationTeam>(`${this.baseUrl}${this.createUrl}/validationteam/create`, team, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`createValidationTeam: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in createValidationTeam. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  public updateValidationTeam(id: string, updates: any[], supressNotification = false): Observable<ValidationTeam> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.patch<ValidationTeam>(`${this.baseUrl}${this.createUrl}/validationteam/${id}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`updateValidationTeam: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in updateValidationTeam. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteValidationTeam(team: ValidationTeam, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.delete(`${this.baseUrl}${this.createUrl}/validationteam/${team.id}`, { body: team.updatedBy, headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteValidationTeam: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteValidationTeam. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public sendEmail(email: RmEmail, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post(`${this.baseUrl}${this.createUrl}/email/send`, email, { headers: this.header } ).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`sendEmail: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in sendEmail. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  //Lab Manager Integration Code

  public getToolsIntegration(id: string, createdByWwid: string, supressNotification = false): Observable<ToolsIntegration> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      //return this._http.get<ToolsIntegration>(`${this.baseUrl}${this.createUrl}/toolsintegration/${id}?createdByWwid=${createdByWwid}`, { headers: this.header })
      return this._http.get<ToolsIntegration>(`${this.baseUrl}${this.createUrl}/toolsintegration/${id}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getToolsIntegration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in getToolsIntegration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public getToolsIntegrationByWorkq(workq: string, recipeNumber: string, supressNotification = false): Observable<ToolsIntegration> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<ToolsIntegration>(`${this.baseUrl}${this.createUrl}/toolsintegration/workq/${workq}?recipeNumber=${recipeNumber}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getToolsIntegrationByWorkQ: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in getToolsIntegrationByWorkq. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public createToolsIntegration(item: ToolsIntegration, supressNotification = false): Observable<ToolsIntegration> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
 //     console.log(JSON.stringify(recipe));
      return this._http.post<ToolsIntegration>(`${this.baseUrl}${this.createUrl}/toolsintegration/create`, item, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`createToolsIntegration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in createToolsIntegration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public updateToolsIntegration(loadedItem: ToolsIntegration, updates: any[], supressNotification = false): Observable<ToolsIntegration> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      //return this._http.patch<ToolsIntegration>(`${this.baseUrl}${this.createUrl}/toolsintegration/${loadedItem.id}?createdByWwid=${loadedItem.createdBy.wwid}`, updates, { headers: this.header })
      return this._http.patch<ToolsIntegration>(`${this.baseUrl}${this.createUrl}/toolsintegration/${loadedItem.id}`, updates, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`UpdateToolsIntegration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in UpdateToolsIntegration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  public deleteToolsIntegration(item: ToolsIntegration, supressNotification = false) {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      const options = {
        headers: this.header,
        body: {
          email: item.updatedBy.email,
          name: item.updatedBy.name,
          wwid: item.updatedBy.wwid
        },
      };
      //var url = `${this.baseUrl}${this.createUrl}/toolsintegration/${item.id}?createdByWwid=${item.createdBy.wwid}`
      var url = `${this.baseUrl}${this.createUrl}/toolsintegration/${item.id}`
      return this._http.delete(url, options)
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`deleteToolsIntegration: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
            console.log('Caught in deleteToolsIntegration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }
}
