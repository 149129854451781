
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

// -- Rxjs Classes --
import { forkJoin, Observable } from 'rxjs';
import { ApiEnvironmentService, GetInfoParams, RestEsUtilityService } from '@apps-es/api';

const baseUrl = '/user';
// const baseUrl = 'https://hsdes-api.intel.com/rest/user';

@Injectable({
  providedIn: 'root'
})

export class UserService extends RestEsUtilityService {

  constructor(http: HttpClient, apiEnvironmentService: ApiEnvironmentService) {
    super(http, apiEnvironmentService);
  }

  // GET /user/byemailId
  getUserByEmailId(emailId: string): Observable<GetUserByEmailResponse> {
    const endpoint = baseUrl + '/byemailId?';

    let urlParams = new HttpParams();
    if (emailId) {
        urlParams = urlParams.append('emailId', emailId);
    }
    return this.request({
        method: 'get',
        endpoint: endpoint,
        queryVariables: urlParams
    });
  }

  // GET rest/user/{idsid}
  getUserDetails(params: {
        idsid: string;
        expand: string;
    }): Observable<GetUserDetailsResponse> {
        const endpoint = baseUrl + '/' + params.idsid;

        let urlParams = new HttpParams();
        if (params && params.expand) {
            urlParams = urlParams.append('expand', params.expand);
        }
        return this.request({
            method: 'get',
            endpoint: endpoint,
            queryVariables: urlParams
        });
    }
}

export interface GetUserByEmailResponse {
  data?: {
    'sys_user_wwid': string,
    'sys_user.site': string,
    'sys_user.email': string,
    'sys_user.orgunit_description': string,
    'sys_user.group': string,
    'sys_user.name': string,
    'sys_user.phone': string,
    'sys_user.badge_type': string,
    'sys_user.idsid': string,
    'sys_user.campus': string
}[];
}

export interface GetUserDetailsResponse {
  data?: {
      'sys_user.phone': string,
      'sys_user.group': string,
      'sys_user.site': string,
      'sys_user.badge_type': string,
      'sys_user.wwid': string,
      'sys_user.orgunit_description': string,
      'sys_user.name': string,
      'sys_user.campus': string,
      'sys_user.email': string
  }[];
  manager?: {
      'mgl1': string,
      'mgl2': string,
      'mgl3': string
  }[];
  groups?: {
      group_name: string,
      idsid: string
  }[];
}

export interface GetUserResponse {
  idsid: string;
  full_name: string;
  mail: string;
  wwid: string;
}
