import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CancelEvent, DataStateChangeEvent, EditEvent, GridComponent, MultipleSortSettings, RemoveEvent, SaveEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { CompositeFilterDescriptor, distinct, filterBy, process } from '@progress/kendo-data-query';
import { GridSettings } from 'app/common/grid-settings-interface';
import { CreateGateway } from 'app/gateways/create.gateway';
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { SearchGateway } from 'app/gateways/search.gateway';
import { ThirdPartyAppGateway } from 'app/gateways/third-party-app.gateway';
import { appSettings } from 'app/modules/shared/app-settings';
import { Subject, takeUntil, from } from 'rxjs';
import { BoardType } from '../shared/model/board-type';
import { BuildingBlockModule } from '../shared/model/building-block-module';
import { BuildingBlockType } from '../shared/model/building-block-type';
import { ItemProduct } from '../shared/model/item';
import { LabCreate, LabInfo } from '../shared/model/lab';
import { Person } from '../shared/model/person';
import { Product } from '../shared/model/product';
import { ValidationTeam } from '../shared/model/validation-team';
import { read, utils } from 'xlsx';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogAnimation } from '@progress/kendo-angular-dialog';
import { AnimationConfiguratorService } from 'app/common/animation-configurator-service';

interface Item {
  text: string;
  value: string;
}

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  @ViewChild("programsAutoComplete") public programsAutoComplete: any;
  @ViewChild("productsComboBox") public productsComboBox: any;

  public adminGridData: any = null;
  public exportData: any = null;
  public boardPBAIdErrorMessage: string = 'init';
  public boardPBAIdHasError: boolean = false;
  public boardTypeNameSelected: string = '';
  public currentOption: string = '';
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public filter: CompositeFilterDescriptor | undefined;
  public formGroup: FormGroup;
  public gridComponent: any = null;
  public gridIsLoading: boolean = false;
  public isBoardTypeButtonDisabled: boolean = false;
  public isEditable: boolean = true;
  public itemPendingDeletion: any = null;
  public labsList: any[] = [];
  public productsList: any[] = [];
  public programsAutoCompleteIsLoading: boolean = false;
  public programNamesList: any[] = [];
  public programsList: any[] = [];
  public programsKeyword: string = '';
  public searchGridColumns: any[] = [];
  public searchResults: any[] = [];
  public selectableSettings: SelectableSettings;
  public selectedItem: any = null;
  public selectedProduct: any = null;
  public showDialog: boolean = false;
  public showImportDialog: boolean = false;
  public showMenuButtons: boolean = false;
  public userEmail: string = '';
  public userName: string = '';
  public userWwid: string = '';
  public gridSettings: GridSettings = {
    state: {
      skip: 0,
      take: 1000,

      filter: {
        logic: 'and',
        filters: [],
      },
    }
  };
  public importRestrictions: FileRestrictions = {
    allowedExtensions: [".xlsx"],
  };
  public listSearchOptions: Array<Item> = [
    { text: 'Select...', value: '' },
    { text: 'Board Types', value: 'board' },
    { text: 'Building Block Modules', value: 'bbModule' },
    { text: 'Building Block Types', value: 'bbType' },
    { text: 'Sites', value: 'lab' },
    { text: 'Products', value: 'product' },
    { text: 'Validation Teams', value: 'team' }
  ];
  public selectedListSearchItem: Item = this.listSearchOptions[0];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple'
  };

  private boardPBAIdTextBoxValue: string = '';
  private boardPBAIdValue: string = '';
  private editedRowIndex: number = -1;
  private rmAppName: string = 'recipe-management';

  /* Modal Pop-up */

  public errorDialogOpened: boolean = false;
  public promptDialogOpened: boolean = false;
  public actionOnItem: string = "";
  public alertDialogOpen: boolean = true;
  public messageDescription: string = "";
  public messageTitle: string = "";
  public animation: boolean | DialogAnimation = {};
  public animationConfig: AnimationConfiguratorService = new AnimationConfiguratorService();

  constructor(private authService: MsalService, private routes: Router, private searchGateway: SearchGateway, private createGateway: CreateGateway,
    private graphUsersGateway: GraphUsersGateway, private thirdPartyAppGateway: ThirdPartyAppGateway,private clipboard: Clipboard) {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
    this.formGroup = new FormGroup({});
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    // Re-route user to their profile configuration page if they do not have admin access and attempt to access this module.
    setTimeout(() => {
      if (!appSettings.isUserAMemberOf("RM_Admins")) {
        this.routes.navigate(['/configuration']);
      }
    }, 5000);

    // Get current user's details.
    var account = this.authService.instance.getActiveAccount();
    if (account != undefined) {
      var accountName = account.name;
      if (accountName != undefined) {
        this.userName = accountName;
        this.userEmail = account.username;
        this.setUserWwid();
      }
    }

    // Get all lab names for validation team menu option ahead of time.
    this.getLabNames();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public listSearchValueChange(value: any): void {
    this.currentOption = value.value;
    this.searchGridColumns = [];
    this.searchResults = [];

    this.filter = undefined;
    this.gridSettings = {
      state: {
        skip: 0,
        take: 1000,

        filter: {
          logic: 'and',
          filters: [],
        },
      }
    };

    if (this.gridComponent) {
      this.closeEditor(this.gridComponent);
    }

    if (value.value != '') {
      this.showMenuButtons = true;
      this.isEditable = true;

      if (value.value == 'board') {
        this.isEditable = false;
        this.getBoardTypes();
      }
      else if (value.value == 'bbModule') {
        this.getBuildingBlockModules();
      }
      else if (value.value == 'bbType') {
        this.getBuildingBlockTypes();
      }
      else if (value.value == 'lab') {
        this.getLabs();
      }
      else if (value.value == 'product') {
        this.isEditable = false;
        this.getProducts();
      }
      else if (value.value == 'team') {
        this.getValidationTeams();
      }
    }
    else {
      this.showMenuButtons = false;
      this.loadData();
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridSettings.state = state;
    this.gridIsLoading = true;
    this.adminGridData = process(this.searchResults, state);
    this.gridIsLoading = false;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridIsLoading = true;
    this.adminGridData = filterBy(this.searchResults, filter);
    this.gridIsLoading = false;
    this.exportData = this.adminGridData;
  }

  public distinctPrimitive(fieldName: string): any {
    return distinct(this.searchResults, fieldName);//.map((item) => item[fieldName]).sort();
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    this.gridComponent = args.sender;

    // define all editable fields validators and default values
    if (this.currentOption == 'board') {
      this.formGroup = new FormGroup({
        boardTypeName: new FormControl('', Validators.required),
        boardPBAId: new FormControl('', Validators.required)
      });
    }
    else if (this.currentOption == 'bbModule') {
      this.formGroup = new FormGroup({
        buildingBlockModuleName: new FormControl('', Validators.required)
      });
    }
    else if (this.currentOption == 'bbType') {
      this.formGroup = new FormGroup({
        buildingBlockTypeName: new FormControl('', Validators.required)
      });
    }
    else if (this.currentOption == 'lab') {
      this.formGroup = new FormGroup({
        lab: new FormControl('', Validators.required)
      });
    }
    else if (this.currentOption == 'product') {
      this.formGroup = new FormGroup({
        productName: new FormControl('', Validators.required),
        siliconProgramName: new FormControl('', Validators.required)
      });
    }
    else if (this.currentOption == 'team') {
      this.formGroup = new FormGroup({
        validationTeamName: new FormControl('', Validators.required),
        labs: new FormControl(null, Validators.required)
      });
    }

    // show the new row editor, with the `FormGroup` build above
    args.sender.addRow(this.formGroup);
  }

  public editHandler(args: EditEvent): void {
    // define all editable fields validators and default values
    const { dataItem } = args;
    this.selectedItem = dataItem;
    this.closeEditor(args.sender);
    this.gridComponent = args.sender;

    if (this.currentOption == 'bbModule') {
      this.formGroup = new FormGroup({
        buildingBlockModuleName: new FormControl(dataItem.buildingBlockModuleName)
      });
    }
    else if (this.currentOption == 'bbType') {
      this.formGroup = new FormGroup({
        buildingBlockTypeName: new FormControl(dataItem.buildingBlockTypeName)
      });
    }
    else if (this.currentOption == 'lab') {
      this.formGroup = new FormGroup({
        lab: new FormControl(dataItem.lab)
      });
    }
    else if (this.currentOption == 'team') {
      this.formGroup = new FormGroup({
        validationTeamName: new FormControl(dataItem.validationTeamName),
        labs: new FormControl(dataItem.labs)
      });
    }

    this.editedRowIndex = args.rowIndex;

    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const item = formGroup.value;

    if (this.currentOption == 'board') {
      this.boardPBAIdHasError = false;
      if (isNew) {
        if (this.boardPBAIdTextBoxValue !== this.boardPBAIdValue) {
          this.boardPBAIdHasError = true;
          this.boardPBAIdErrorMessage = 'Board PBA ID was changed without performing search.'
          return;
        }
        this.createBoardType(item);
      }
    }
    else if (this.currentOption == 'bbModule') {
      if (isNew) {
        this.createBuildingBlockModule(item);
      }
      else {
        this.updateBuildingBlockModule(item);
      }
    }
    else if (this.currentOption == 'bbType') {
      if (isNew) {
        this.createBuildingBlockType(item);
      }
      else {
        this.updateBuildingBlockType(item);
      }
    }
    else if (this.currentOption == 'lab') {
      if (isNew) {
        this.createLab(item);
      }
      else {
        this.updateLab(item);
      }
    }
    else if (this.currentOption == 'product') {
      if (isNew) {
        this.createProduct(item);
      }
    }
    else if (this.currentOption == 'team') {
      item.labs = this.convertArrayToLabInfoArray(item);
      if (isNew) {
        this.createValidationTeam(item);
      }
      else {
        this.updateValidationTeam(item);
      }
    }

    sender.closeRow(rowIndex);
  }

  public removeHandler(args: RemoveEvent): void {
    if (this.currentOption == 'board') {
      this.deleteBoardType(args.dataItem);
    }
    else if (this.currentOption == 'bbModule') {
      this.deleteBuildingBlockModule(args.dataItem);
    }
    else if (this.currentOption == 'bbType') {
      this.deleteBuildingBlockType(args.dataItem);
    }
    else if (this.currentOption == 'lab') {
      this.deleteLab(args.dataItem);
    }
    else if (this.currentOption == 'product') {
      this.deleteProduct(args.dataItem);
    }
    else if (this.currentOption == 'team') {
      this.deleteValidationTeam(args.dataItem);
    }
  }

  public boardPBAIdTextBoxOnChange(value: string): void {
    this.boardPBAIdTextBoxValue = value;
  }

  public searchBoardType(): void {
    this.boardPBAIdHasError = false;
    this.isBoardTypeButtonDisabled = true;
    this.boardPBAIdValue = this.boardPBAIdTextBoxValue;
    this.boardTypeNameSelected = '';

    this.thirdPartyAppGateway.getBoardTypes(this.boardPBAIdTextBoxValue, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.boardTypeNameSelected = JSON.parse(JSON.stringify(result)).boardTypeName;
        this.formGroup.controls['boardTypeName'].setValue(this.boardTypeNameSelected);
      },
      error: (e) => {
        this.isBoardTypeButtonDisabled = false;
        if (e.error?.status !== 404) {
          this.displayModalWindowWithError("Board Type Search Error." + e.error?.toString());
        }
        else {
          this.boardPBAIdHasError = true;
          this.boardPBAIdErrorMessage = "This PBA ID was not found.";
          this.formGroup.controls['boardTypeName'].setValue(this.boardTypeNameSelected);
        }
      },
      complete: () => {
        this.isBoardTypeButtonDisabled = false;
      }
    });
  }

  public programsValueChange(value: any): void {
    this.programsKeyword = value;

    // If user selected option from returned programs list, then populate the products drop-down.
    if (this.programsList.length > 0 && this.programsList.find(element => element.programName.toUpperCase() == this.programsKeyword.toUpperCase()) != undefined) {
      // Get all program IDs first.
      let products: any[] = [];
      this.programsList.filter(element => element.programName.toUpperCase() == this.programsKeyword.toUpperCase()).forEach(item => {
        products.push(item.programID);
      });

      // Retrieve products data based on program IDs.
      this.thirdPartyAppGateway.getProducts(products, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          this.productsList = JSON.parse(JSON.stringify(result));
        },
        error: (e) => {
          this.displayModalWindowWithError("Products Search Error." + e.error?.toString());
        }
      });
    }
    else {
      this.productsList = [];
    }
  }

  public searchPrograms(): void {
    this.programsAutoCompleteIsLoading = true;
    this.thirdPartyAppGateway.getSiliconPrograms(this.programsKeyword, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let programs: any[] = [];
        this.programsList = JSON.parse(JSON.stringify(result));
        this.programsList.forEach(item => {
          programs.push(item.programName);
        });
        this.programNamesList = programs.filter((n, i) => programs.indexOf(n) === i).sort();
      },
      error: (e) => {
        this.programsAutoCompleteIsLoading = false;
        if (e.error?.status !== 404) {
          this.displayModalWindowWithError("Programs Search Error." + e.error?.toString());
        }
        else {
          this.displayModalWindowWithError("No results were found.");
        }
      },
      complete: () => {
        this.programsAutoCompleteIsLoading = false;
        this.programsAutoComplete.toggle();
      }
    });
  }

  public closeDialog(status: string): void {
    this.showDialog = false;
    if (status == 'yes') {
      if (this.currentOption == 'board') {
        this.deleteBoardTypeCommit(this.itemPendingDeletion);
      }
      else if (this.currentOption == 'bbModule') {
        this.deleteBuildingBlockModuleCommit(this.itemPendingDeletion);
      }
      else if (this.currentOption == 'bbType') {
        this.deleteBuildingBlockTypeCommit(this.itemPendingDeletion);
      }
      else if (this.currentOption == 'lab') {
        this.deleteLabCommit(this.itemPendingDeletion);
      }
      else if (this.currentOption == 'product') {
        this.deleteProductCommit(this.itemPendingDeletion);
      }
      else if (this.currentOption == 'team') {
        this.deleteValidationTeamCommit(this.itemPendingDeletion);
      }
    }
    this.itemPendingDeletion = null;
  }

  public importData(): void {
    this.showImportDialog = true;
  }

  public closeImportDialog(status: string): void {
    this.showImportDialog = false;
  }

  public onImportSelect(ev: SelectEvent): void {
    if (ev.files) {
      ev.files.forEach((file: FileInfo) => {
        if (file.rawFile && !file.validationErrors) {
          const reader = new FileReader();

          reader.onloadend = () => {
            const wb = read(reader.result);
            const data = utils.sheet_to_json<any>(wb.Sheets[wb.SheetNames[0]]);

            this.showImportDialog = false;
            switch (this.currentOption) {
              case 'bbModule': {
                this.importBuildingBlockModules(data);
                break;
              }
              case 'bbType': {
                this.importBuildingBlockTypes(data);
                break;
              }
              case 'lab': {
                this.importLabs(data);
                break;
              }
              case 'team': {
                this.importValidationTeams(data);
                break;
              }
            }
          };

          reader.readAsArrayBuffer(file.rawFile);
        }
        else {
          this.showImportDialog = false;
          this.displayModalWindowWithError("Invalid file selected or invalid data provided.");
        }
      });
    }
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = -1;
    this.formGroup = new FormGroup({});;
  }

  private loadData(): void {
    // if (this.savedStateExists) {
    //   this.gridSettings = this.mapGridSettings(this.persistingService.get(this.gridSettingsName));
    // }
    if (this.filter) {
      let filteredData: any[];
      filteredData = filterBy(this.searchResults, this.filter);
      this.adminGridData = {
        data: filteredData.slice(this.gridSettings.state.skip, this.gridSettings.state.skip! + this.gridSettings.state.take!),
        total: filteredData.length
      };
    }
    else {
      this.adminGridData = {
        data: this.searchResults.slice(this.gridSettings.state.skip, this.gridSettings.state.skip! + this.gridSettings.state.take!),
        total: this.searchResults.length
      };
      this.exportData = this.adminGridData.data;
    }
  }

  private getBoardTypes(): void {
    this.gridIsLoading = true;
    this.searchGateway.getBoardTypes(false, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.searchGridColumns = [
          {field: 'boardPBAId', title: 'PBA ID', width: '225'},
          {field: 'boardTypeName', title: 'Board Type', width: '150'},
          {field: 'createdByName', title: 'Created By', width: '130'},
          {field: 'createdDate', title: 'Create Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'},
          {field: 'updatedByName', title: 'Last Updated By', width: '130'},
          {field: 'updatedDate', title: 'Last Update Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'}
        ];
        this.searchResults = JSON.parse(JSON.stringify(result));
        this.searchResults = this.searchResults.map(item => {
          item.createdDate = new Date(item.createdDate);
          item.updatedDate = new Date(item.updatedDate);
          item.createdByName = item.createdBy.name;
          item.updatedByName = item.updatedBy.name;
          return item;
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.loadData();
        this.gridIsLoading = false;
      }
    });
  }

  private createBoardType(item: BoardType): void {
    item.createdBy = new Person();
    item.updatedBy = new Person();

    this.gridIsLoading = true;
    item.boardPBAId = this.boardPBAIdTextBoxValue;
    item.boardTypeName = this.boardTypeNameSelected;
    item.createdBy.name = this.userName;
    item.createdBy.email = this.userEmail;
    item.createdBy.wwid = this.userWwid;
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.createBoardType(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Create Board Type Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBoardTypes();
      }
    });
  }

  private updateBoardType(item: BoardType): void {
    let dataChanges: any[] = [];

    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    dataChanges.push({ value: item.boardTypeName, path: 'boardTypeName', op: 'replace' });
    dataChanges.push({ value: item.boardPBAId, path: 'boardPBAId', op: 'replace' });
    dataChanges.push({ value: item.updatedBy, path: 'updatedBy', op: 'replace' });

    this.createGateway.updateBoardType(this.selectedItem.id, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Update Board Type Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBoardTypes();
      }
    });
  }

  private deleteBoardType(item: BoardType): void {
    let params: any = {
      boardType: item.boardTypeName,
      isDraft: false,
      isArchived: false
    };

    // Check if any dependecies and provide user warning before proceeding to delete record.
    from(this.hasDependencies(params)).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result == true) {
          this.itemPendingDeletion = item;
          this.showDialog = true;
        }
        else {
          this.deleteBoardTypeCommit(item);
        }
      }
    });
  }

  private deleteBoardTypeCommit(item: BoardType): void {
    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.deleteBoardType(item).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Delete Board Type Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBoardTypes();
      }
    });
  }

  private getBuildingBlockModules(): void {
    this.gridIsLoading = true;
    this.searchGateway.getBuildingBlockModules(false, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.searchGridColumns = [
          {field: 'buildingBlockModuleName', title: 'Building Block Module', width: '80'},
          {field: 'createdByName', title: 'Created By', width: '100'},
          {field: 'createdDate', title: 'Create Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'},
          {field: 'updatedByName', title: 'Last Updated By', width: '130'},
          {field: 'updatedDate', title: 'Last Update Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'}
        ];
        this.searchResults = JSON.parse(JSON.stringify(result));
        this.searchResults = this.searchResults.map(item => {
          item.createdDate = new Date(item.createdDate);
          item.updatedDate = new Date(item.updatedDate);
          item.createdByName = item.createdBy.name;
          item.updatedByName = item.updatedBy.name;
          return item;
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.loadData();
        this.gridIsLoading = false;
      }
    });
  }

  private createBuildingBlockModule(item: BuildingBlockModule): void {
    item.createdBy = new Person();
    item.updatedBy = new Person();

    this.gridIsLoading = true;
    item.createdBy.name = this.userName;
    item.createdBy.email = this.userEmail;
    item.createdBy.wwid = this.userWwid;
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.createBuildingBlockModule(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Create Building Block Module Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBuildingBlockModules();
      }
    });
  }

  private updateBuildingBlockModule(item: BuildingBlockModule): void {
    let dataChanges: any[] = [];

    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    dataChanges.push({ value: item.buildingBlockModuleName, path: 'buildingBlockModuleName', op: 'replace' });
    dataChanges.push({ value: item.updatedBy, path: 'updatedBy', op: 'replace' });

    this.createGateway.updateBuildingBlockModule(this.selectedItem.id, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("update Building Block Module Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBuildingBlockModules();
      }
    });
  }

  private deleteBuildingBlockModule(item: BuildingBlockModule): void {
    let params: any = {
      module: item.buildingBlockModuleName,
      isDraft: false,
      isArchived: false
    };

    // Check if any dependecies and provide user warning before proceeding to delete record.
    from(this.hasDependencies(params, false)).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result == true) {
          this.itemPendingDeletion = item;
          this.showDialog = true;
        }
        else {
          this.deleteBuildingBlockModuleCommit(item);
        }
      }
    });
  }

  private deleteBuildingBlockModuleCommit(item: BuildingBlockModule): void {
    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.deleteBuildingBlockModule(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Delete Building Block Module Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBuildingBlockModules();
      }
    });
  }

  private async importBuildingBlockModules(jsonData: any): Promise<void> {
    if (jsonData[0]['Building Block Module'] !== undefined) {
      let totalItems: number = 0;
      let numErrors: number = 0;
      let timer: number = 0;

      this.gridIsLoading = true;
      jsonData.forEach((element: any) => {
        let item: BuildingBlockModule = new BuildingBlockModule();
        item.createdBy = new Person();
        item.updatedBy = new Person();

        item.createdBy.name = this.userName;
        item.createdBy.email = this.userEmail;
        item.createdBy.wwid = this.userWwid;
        item.updatedBy.name = this.userName;
        item.updatedBy.email = this.userEmail;
        item.updatedBy.wwid = this.userWwid;
        item.application = this.rmAppName;
        item.buildingBlockModuleName = element['Building Block Module'];

        this.createGateway.createBuildingBlockModule(item, true).pipe(takeUntil(this.destroy$)).subscribe({
          next: (result) => {

          },
          error: (e) => {
            numErrors++;
            totalItems++;
          },
          complete: () => {
            totalItems++;
          }
        });
      });

      // Wait for all data to finish importing or exit loop if time exceeds two minutes.
      while (totalItems < jsonData.length && timer < 120) {
        await this.delay(1000);
        timer++;
      }

      this.gridIsLoading = false;

      if (numErrors > 0 && numErrors < totalItems) {
        this.displayModalWindowWithError("Errors were encountered during import. Not all items were created.");
      }
      else if (numErrors > 0 && numErrors == totalItems) {
        this.displayModalWindowWithError("Unable to import data.");
      }

      this.getBuildingBlockModules();
    }
    else {
      this.displayModalWindowWithError("Invalid or missing column names in Excel file. Cannot perform import.");
    }
  }

  private getBuildingBlockTypes(): void {
    this.gridIsLoading = true;
    this.searchGateway.getBuildingBlockTypes(false, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.searchGridColumns = [
          {field: 'buildingBlockTypeName', title: 'Building Block Type', width: '80'},
          {field: 'createdByName', title: 'Created By', width: '100'},
          {field: 'createdDate', title: 'Create Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'},
          {field: 'updatedByName', title: 'Last Updated By', width: '130'},
          {field: 'updatedDate', title: 'Last Update Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'}
        ];
        this.searchResults = JSON.parse(JSON.stringify(result));
        this.searchResults = this.searchResults.map(item => {
          item.createdDate = new Date(item.createdDate);
          item.updatedDate = new Date(item.updatedDate);
          item.createdByName = item.createdBy.name;
          item.updatedByName = item.updatedBy.name;
          return item;
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.loadData();
        this.gridIsLoading = false;
      }
    });
  }

  private createBuildingBlockType(item: BuildingBlockType): void {
    item.createdBy = new Person();
    item.updatedBy = new Person();

    this.gridIsLoading = true;
    item.createdBy.name = this.userName;
    item.createdBy.email = this.userEmail;
    item.createdBy.wwid = this.userWwid;
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.createBuildingBlockType(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Create Building Block Type Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBuildingBlockTypes();
      }
    });
  }

  private updateBuildingBlockType(item: BuildingBlockType): void {
    let dataChanges: any[] = [];

    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    dataChanges.push({ value: item.buildingBlockTypeName, path: 'buildingBlockTypeName', op: 'replace' });
    dataChanges.push({ value: item.updatedBy, path: 'updatedBy', op: 'replace' });

    this.createGateway.updateBuildingBlockType(this.selectedItem.id, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Update Building Block Type Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBuildingBlockTypes();
      }
    });
  }

  private deleteBuildingBlockType(item: BuildingBlockType): void {
    let params: any = {
      type: item.buildingBlockTypeName,
      isDraft: false,
      isArchived: false
    };

    // Check if any dependecies and provide user warning before proceeding to delete record.
    from(this.hasDependencies(params, false)).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result == true) {
          this.itemPendingDeletion = item;
          this.showDialog = true;
        }
        else {
          this.deleteBuildingBlockTypeCommit(item);
        }
      }
    });
  }

  private deleteBuildingBlockTypeCommit(item: BuildingBlockType): void {
    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.deleteBuildingBlockType(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Delete Building Block Type Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getBuildingBlockTypes();
      }
    });
  }

  private async importBuildingBlockTypes(jsonData: any): Promise<void> {
    if (jsonData[0]['Building Block Type'] !== undefined) {
      let totalItems: number = 0;
      let numErrors: number = 0;
      let timer: number = 0;

      this.gridIsLoading = true;
      jsonData.forEach((element: any) => {
        let item: BuildingBlockType = new BuildingBlockType();
        item.createdBy = new Person();
        item.updatedBy = new Person();

        item.createdBy.name = this.userName;
        item.createdBy.email = this.userEmail;
        item.createdBy.wwid = this.userWwid;
        item.updatedBy.name = this.userName;
        item.updatedBy.email = this.userEmail;
        item.updatedBy.wwid = this.userWwid;
        item.application = this.rmAppName;
        item.buildingBlockTypeName = element['Building Block Type'];

        this.createGateway.createBuildingBlockType(item, true).pipe(takeUntil(this.destroy$)).subscribe({
          next: (result) => {

          },
          error: (e) => {
            numErrors++;
            totalItems++;
          },
          complete: () => {
            totalItems++;
          }
        });
      });

      // Wait for all data to finish importing or exit loop if time exceeds two minutes.
      while (totalItems < jsonData.length && timer < 120) {
        await this.delay(1000);
        timer++;
      }

      this.gridIsLoading = false;

      if (numErrors > 0 && numErrors < totalItems) {
        this.displayModalWindowWithError("Errors were encountered during import. Not all items were created.");
      }
      else if (numErrors > 0 && numErrors == totalItems) {
        this.displayModalWindowWithError("Unable to import data.");
      }

      this.getBuildingBlockTypes();
    }
    else {
      this.displayModalWindowWithError("Invalid or missing column names in Excel file. Cannot perform import.");
    }
  }

  private getLabs(): void {
    this.gridIsLoading = true;
    this.searchGateway.getLabs(true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.searchGridColumns = [
          {field: 'lab', title: 'Site', width: '80'},
          {field: 'createdByName', title: 'Created By', width: '100'},
          {field: 'createdDate', title: 'Create Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'},
          {field: 'updatedByName', title: 'Last Updated By', width: '130'},
          {field: 'updatedDate', title: 'Last Update Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'}
        ];
        this.searchResults = JSON.parse(JSON.stringify(result));
        this.searchResults = this.searchResults.map(item => {
          item.lab = item.lab.toUpperCase();
          item.createdDate = new Date(item.createdDate);
          item.updatedDate = new Date(item.updatedDate);
          return item;
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.loadData();
        this.gridIsLoading = false;
      }
    });
  }

  private createLab(item: LabCreate): void {
    item.createdBy = new Person();
    item.updatedBy = new Person();

    this.gridIsLoading = true;
    item.createdBy.name = this.userName;
    item.createdBy.email = this.userEmail;
    item.createdBy.wwid = this.userWwid;
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.createLab(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Create Site Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getLabs();
        this.getLabNames();
      }
    });
  }

  private updateLab(item: LabCreate): void {
    let dataChanges: any[] = [];

    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    dataChanges.push({ value: item.lab, path: 'lab', op: 'replace' });
    dataChanges.push({ value: item.updatedBy, path: 'updatedBy', op: 'replace' });

    this.createGateway.updateLab(this.selectedItem.id, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Update Site Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getLabs();
        this.getLabNames();
      }
    });
  }

  private deleteLab(item: LabCreate): void {
    let params: any = {
      lab: item.lab,
      isDraft: false,
      isArchived: false
    };

    // Check if any dependecies and provide user warning before proceeding to delete record.
    from(this.hasDependencies(params)).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result == true) {
          this.itemPendingDeletion = item;
          this.showDialog = true;
        }
        else {
          this.deleteLabCommit(item);
        }
      }
    });
  }

  private deleteLabCommit(item: LabCreate): void {
    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.deleteLab(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Delete Site Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getLabs();
        this.getLabNames();
      }
    });
  }

  private async importLabs(jsonData: any): Promise<void> {
    // Displaying Lab Titles to Site 
    // Rajesh Updated this on 13/9/2023
    // if (jsonData[0]['Lab'] !== undefined) { 
    if (jsonData[0]['Site'] !== undefined) { 
      let totalItems: number = 0;
      let numErrors: number = 0;
      let timer: number = 0;

      this.gridIsLoading = true;
      jsonData.forEach((element: any) => {
        let item: LabCreate = new LabCreate();
        item.createdBy = new Person();
        item.updatedBy = new Person();

        item.createdBy.name = this.userName;
        item.createdBy.email = this.userEmail;
        item.createdBy.wwid = this.userWwid;
        item.updatedBy.name = this.userName;
        item.updatedBy.email = this.userEmail;
        item.updatedBy.wwid = this.userWwid;
        item.application = this.rmAppName;
        // item.lab = element['Lab'];
        item.lab = element['Site'];

        this.createGateway.createLab(item, true).pipe(takeUntil(this.destroy$)).subscribe({
          next: (result) => {

          },
          error: (e) => {
            numErrors++;
            totalItems++;
          },
          complete: () => {
            totalItems++;
          }
        });
      });

      // Wait for all data to finish importing or exit loop if time exceeds two minutes.
      while (totalItems < jsonData.length && timer < 120) {
        await this.delay(1000);
        timer++;
      }

      this.gridIsLoading = false;

      if (numErrors > 0 && numErrors < totalItems) {
        this.displayModalWindowWithError("Errors were encountered during import. Not all items were created.");
      }
      else if (numErrors > 0 && numErrors == totalItems) {
        this.displayModalWindowWithError("Unable to import data.");
      }

      this.getLabs();
    }
    else {
      this.displayModalWindowWithError("Invalid or missing column names in Excel file. Cannot perform import.");
    }
  }

  private getProducts(): void {
    this.gridIsLoading = true;

    this.searchGateway.getProducts(false, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.searchGridColumns = [
          {field: 'siliconProgramName', title: 'Silicon Program', width: '160'},
          {field: 'productId', title: 'Product ID', width: '100'},
          {field: 'productName', title: 'Product', width: '150'},
          {field: 'createdByName', title: 'Created By', width: '130'},
          {field: 'createdDate', title: 'Create Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'},
          {field: 'updatedByName', title: 'Last Updated By', width: '130'},
          {field: 'updatedDate', title: 'Last Update Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'}
        ];
        this.searchResults = JSON.parse(JSON.stringify(result));
        this.searchResults = this.searchResults.map(item => {
          item.createdDate = new Date(item.createdDate);
          item.updatedDate = new Date(item.updatedDate);
          item.createdByName = item.createdBy.name;
          item.updatedByName = item.updatedBy.name;
          return item;
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.loadData();
        this.gridIsLoading = false;
      }
    });
  }

  private createProduct(item: Product): void {
    item.createdBy = new Person();
    item.updatedBy = new Person();

    this.gridIsLoading = true;
    item.productId = this.selectedProduct.productID;
    item.productName = this.selectedProduct.productName;
    item.siliconProgramName = this.programsKeyword;
    item.createdBy.name = this.userName;
    item.createdBy.email = this.userEmail;
    item.createdBy.wwid = this.userWwid;
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.createProduct(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Create Product Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getProducts();
      }
    });
  }

  private updateProduct(item: Product): void {
    let dataChanges: any[] = [];

    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    dataChanges.push({ value: item.productName, path: 'productName', op: 'replace' });
    dataChanges.push({ value: item.updatedBy, path: 'updatedBy', op: 'replace' });

    this.createGateway.updateProduct(this.selectedItem.id, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Update Product Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getProducts();
      }
    });
  }

  private deleteProduct(item: Product): void {
    let params: any = {
      productName: item.productName,
      isDraft: false,
      isArchived: false
    };

    // Check if any dependecies and provide user warning before proceeding to delete record.
    from(this.hasDependencies(params)).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result == true) {
          this.itemPendingDeletion = item;
          this.showDialog = true;
        }
        else {
          this.deleteProductCommit(item);
        }
      }
    });
  }

  private deleteProductCommit(item: Product): void {
    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.deleteProduct(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Delete Product Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getProducts();
      }
    });
  }

  private getValidationTeams(): void {
    this.gridIsLoading = true;
    this.searchGateway.getValidationTeams(false, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.searchGridColumns = [
          {field: 'validationTeamName', title: 'Team', width: '80'},
          {field: 'labs', title: 'Sites', width: '100'},
          {field: 'createdByName', title: 'Created By', width: '100'},
          {field: 'createdDate', title: 'Create Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'},
          {field: 'updatedByName', title: 'Last Updated By', width: '130'},
          {field: 'updatedDate', title: 'Last Update Date', width: '160', type: 'date', format: '{0:MM/dd/yyyy hh:mm a}'}
        ];
        this.searchResults = JSON.parse(JSON.stringify(result));
        this.searchResults = this.searchResults.map(item => {
          let labList: any[] = [];
          item.labs.forEach((element: LabInfo) => {
            labList.push(element.name.toUpperCase());
          });
          //Fix on exporting site column 
          //Rajesh on 13/9/2023
          item.labs = labList.join(", "); 

          item.createdDate = new Date(item.createdDate);
          item.updatedDate = new Date(item.updatedDate);
          item.createdByName = item.createdBy.name;
          item.updatedByName = item.updatedBy.name;
          return item;
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.loadData();
        this.gridIsLoading = false;
      }
    });
  }

  private createValidationTeam(item: ValidationTeam): void {
    item.createdBy = new Person();
    item.updatedBy = new Person();

    this.gridIsLoading = true;
    item.createdBy.name = this.userName;
    item.createdBy.email = this.userEmail;
    item.createdBy.wwid = this.userWwid;
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.createValidationTeam(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Create Validation Team Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getValidationTeams();
      }
    });
  }

  private updateValidationTeam(item: ValidationTeam): void {
    let dataChanges: any[] = [];

    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    dataChanges.push({ value: item.validationTeamName, path: 'validationTeamName', op: 'replace' });
    dataChanges.push({ value: item.labs, path: 'labs', op: 'replace' });
    dataChanges.push({ value: item.updatedBy, path: 'updatedBy', op: 'replace' });

    this.createGateway.updateValidationTeam(this.selectedItem.id, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Update Validation Team Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getValidationTeams();
      }
    });
  }

  private deleteValidationTeam(item: ValidationTeam): void {
    let params: any = {
      validationTeam: item.validationTeamName,
      isDraft: false,
      isArchived: false
    };

    // Check if any dependecies and provide user warning before proceeding to delete record.
    from(this.hasDependencies(params)).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        if (result == true) {
          this.itemPendingDeletion = item;
          this.showDialog = true;
        }
        else {
          this.deleteValidationTeamCommit(item);
        }
      }
    });
  }

  private deleteValidationTeamCommit(item: ValidationTeam): void {
    this.gridIsLoading = true;
    item.updatedBy = new Person();
    item.updatedBy.name = this.userName;
    item.updatedBy.email = this.userEmail;
    item.updatedBy.wwid = this.userWwid;
    item.application = this.rmAppName;

    this.createGateway.deleteValidationTeam(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

      },
      error: (e) => {
        this.displayModalWindowWithError("Delete Validation Team Error." + e.error?.toString());
        this.gridIsLoading = false;
      },
      complete: () => {
        this.getValidationTeams();
      }
    });
  }

  private async importValidationTeams(jsonData: any): Promise<void> {
    if (jsonData[0]['Team'] !== undefined && jsonData[0]['Sites'] !== undefined) {
      let totalItems: number = 0;
      let numErrors: number = 0;
      let timer: number = 0;

      this.gridIsLoading = true;
      jsonData.forEach((element: any) => {
        let item: ValidationTeam = new ValidationTeam();
        item.createdBy = new Person();
        item.updatedBy = new Person();

        item.createdBy.name = this.userName;
        item.createdBy.email = this.userEmail;
        item.createdBy.wwid = this.userWwid;
        item.updatedBy.name = this.userName;
        item.updatedBy.email = this.userEmail;
        item.updatedBy.wwid = this.userWwid;
        item.application = this.rmAppName;
        item.validationTeamName = element['Team'];

        var labsList = element['Sites'].split(',');
        labsList.forEach((lab: any) => {
          if (lab.trim() !== '') {
            let labItem: LabInfo = new LabInfo();
            labItem.name = lab.trim();
            item.labs.push(labItem);
          }
        });

        this.createGateway.createValidationTeam(item, true).pipe(takeUntil(this.destroy$)).subscribe({
          next: (result) => {

          },
          error: (e) => {
            numErrors++;
            totalItems++;
          },
          complete: () => {
            totalItems++;
          }
        });
      });

      // Wait for all data to finish importing or exit loop if time exceeds two minutes.
      while (totalItems < jsonData.length && timer < 120) {
        await this.delay(1000);
        timer++;
      }

      this.gridIsLoading = false;

      if (numErrors > 0 && numErrors < totalItems) {
        this.displayModalWindowWithError("Errors were encountered during import. Not all items were created.");
      }
      else if (numErrors > 0 && numErrors == totalItems) {
        this.displayModalWindowWithError("Unable to import data.");
      }

      this.getValidationTeams();
    }
    else {
      this.displayModalWindowWithError("Invalid or missing column names in Excel file. Cannot perform import.");
    }
  }

  private setUserWwid(): void {
    if (this.userEmail != '') {
      this.graphUsersGateway.getUsers(this.userEmail, 1, '', true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          if (result[0].jobTitle != null) {
            this.userWwid = result[0].jobTitle;
          }
        },
        error: (e) => {
          this.displayModalWindowWithError("Get User Info Error." + e.error?.toString());
        }
      });
    }
  }

  private getLabNames(): void {
    this.searchGateway.getLabs(true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        this.labsList = [];
        JSON.parse(JSON.stringify(result)).forEach((element: any) => {
          this.labsList.push(element.lab);
        });
      },
      error: (e) => {
        this.displayModalWindowWithError("Search Error." + e.error?.toString());
      }
    });
  }

  private convertArrayToLabInfoArray(item: any): LabInfo[] {
    let labList: LabInfo[] = [];

    item.labs.forEach((element: string) => {
      let labElement: LabInfo = new LabInfo();
      labElement.name = element;
      labList.push(labElement);
    });

    return labList;
  }

  private searchBuildingBlocks(params: any): Promise<boolean> {
    let hasDependencies: boolean = false;

    return new Promise<boolean>(resolve => {
      this.searchGateway.getBuildingBlocks(params, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          var data = JSON.parse(JSON.stringify(result));
          if (data.length > 0) {
            hasDependencies = true;
          }
        },
        error: (e) => {
          this.displayModalWindowWithError("Search dependencies error while retrieving building blocks. " + e.error?.toString() + ".");
          this.gridIsLoading = false;
          return Promise.reject(e);
        },
        complete: () => {
          resolve(hasDependencies);
        }
      });
    });
  }

  private searchRecipes(params: any): Promise<boolean> {
    let hasDependencies: boolean = false;

    return new Promise<boolean>(resolve => {
      this.searchGateway.getRecipes(params, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          var data = JSON.parse(JSON.stringify(result));
          if (data.length > 0) {
            hasDependencies = true;
          }
        },
        error: (e) => {
          this.displayModalWindowWithError("Search dependencies error while retrieving building blocks." + e.error?.toString());
          this.gridIsLoading = false;
          return Promise.reject(e);
        },
        complete: () => {
          resolve(hasDependencies);
        }
      });
    });
  }

  private async hasDependencies(params: any, searchRecipes: boolean = true): Promise<boolean> {
    const bbCheck = await this.searchBuildingBlocks(params);

    if (bbCheck == true) {
      return true;
    }
    else {
      if (searchRecipes == true) {
        if (params['productName'] !== undefined) {
          params['product'] = params['productName'];
          delete params['productName'];
        }

        return await this.searchRecipes(params);
      }
      else {
        return false;
      }
    }
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.exportData, {
      }).data,
    };

    return result;
  }

  public displayModalWindowWithError(message: string): void{

    this.messageTitle = "Recipe Management Error";
    this.messageDescription = message;

    const { type, direction, duration, myAnimation } = this.animationConfig;
    this.animation = myAnimation ? { duration, type, direction } : false;
    this.errorDialogOpened = true;
  }

  public errorDialogAction(status: string): void {

    if(status.toLowerCase() == 'exit')
    {
      this.errorDialogOpened = false;
      this.alertDialogOpen = false;
    }
    else
    {
      this.alertDialogOpen = false;
      this.errorDialogOpened = false;
    }
  }

  public copyText(): void {

    this.clipboard.copy(this.messageDescription);
    this.errorDialogOpened = false;
    this.alertDialogOpen = false;
  }

  public dialogWindowClose(category: string): void {

    switch (category.toLowerCase()){
      case "error":
        this.errorDialogOpened = false;
        break;
      case "prompt":
        this.promptDialogOpened = false;
        break;
      default:
        break;
    }
  }

}
