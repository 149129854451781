import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  refreshUserInformationFunction: EventEmitter<any> = new EventEmitter();
  refreshUserInformationVar: Subscription = new Subscription();
  
  refreshUnauthorizedUserFunction: EventEmitter<any> = new EventEmitter();
  refreshUnauthorizedUserVar: Subscription = new Subscription();

  refreshPageFunction: EventEmitter<any> = new EventEmitter();
  refreshPageVar: Subscription = new Subscription();

  refreshSearchFunction: EventEmitter<any> = new EventEmitter();
  refreshSearchVar: Subscription = new Subscription();

  constructor() { }

  onUserInformationUpdate() {
    this.refreshUserInformationFunction.emit();
  }
  onUnauthorizedUser(){
    this.refreshUnauthorizedUserFunction.emit();
  }
  onRefreshPage(){
    this.refreshPageFunction.emit();
  }
  onSiteChange(){
    this.refreshSearchFunction.emit();
  }
}