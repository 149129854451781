// string-parsing.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringParsingService {
  parseConnectionString(connectionString: string, keyToFind: string): string | undefined {
    const pairs = connectionString.split(';');
    const keyValue = pairs.find(pair => pair.trim().startsWith(`${keyToFind}=`));

    return keyValue ? keyValue.split('=')[1].trim() : undefined;
  }
}
