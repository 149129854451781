import { Component, OnInit, Injector, OnDestroy, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { FoundationElement } from '@microsoft/fast-foundation';
import { BadgeAlign } from '@progress/kendo-angular-indicators';
import { EventEmitterService } from 'app/common/event-emitter-service';
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { appSettings } from 'app/modules/shared/app-settings';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  public rmSiteList: any[] = [];
  public rmSite = '';
  public enableSiteSelection = true;

  public isImageLoading = false;
  public imageToShow: any;
  public userName: string | null = '';

  currentRole = '';
  currentRoles: any = {}; 

  /* Support Menu URLs */
  public RmGtsUrl: string = '';
  public RmHelpUrl: string = '';
  public RmTicketsUrl: string = '';


  public badgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };

  constructor(
    private domSanitizer: DomSanitizer,
    private graphService: GraphUsersGateway,
    private msalService:MsalService,
    private eventEmitterService: EventEmitterService) {

      const siteList = localStorage.getItem('RmSiteList');


      if (siteList != null && siteList.length > 0){
          const allSites = siteList != null ? JSON.parse(siteList) : [];
          for(const site of allSites){
            this.rmSiteList.push(site.lab.toUpperCase());
          }
          // for (let i = 0; i < allSites.length; i++){
          //   this.rmSiteList.push(allSites[i].lab.toUpperCase());
          // }
      }

      if (localStorage.getItem('SelectedSite') != null)
      {
        const tmpSite =  localStorage.getItem('SelectedSite');
        this.rmSite = tmpSite != null ?  JSON.parse(tmpSite) : this.rmSiteList[0];
        appSettings.rmSite = this.rmSite;
      }
      else
      {
        this.rmSite = this.rmSiteList[0];
        appSettings.rmSite = this.rmSite;
      }
  }

  ngOnInit(): void {
      this.eventEmitterService.refreshUserInformationVar =
        this.eventEmitterService.refreshUserInformationFunction.subscribe((name: string) => {
          this.userName = sessionStorage.getItem('appSettings.appUser') != null ? sessionStorage.getItem('appSettings.appUser') : 'Guest';
          this.getProfilePicture();
          this.refreshRoles();

          // Support menu redirect URLs
          this.RmGtsUrl = environment.services.GtsSupportURL;
          this.RmHelpUrl = environment.services.RMHelpUrl;
          this.RmTicketsUrl = environment.services.RMTicketsUrl;

      });
      this.eventEmitterService.refreshUnauthorizedUserVar =
        this.eventEmitterService.refreshUnauthorizedUserFunction.subscribe((name: string) => {
          this.userName = null;
          this.isUserLoggedIn();
      });
  }

  public lstRmSiteSelection(value: string): void {
      appSettings.rmSite = value;
      this.rmSite = appSettings.rmSite;
      localStorage.setItem('SelectedSite', JSON.stringify(this.rmSite));
      this.eventEmitterService.onSiteChange();
      alert('You have changed site selection. You are now on \'' + this.rmSite + '\' site.');
  }
  public getProfilePicture(): void
  {
      this.isImageLoading = true;
      this.graphService.getUserImageMetadata(true).subscribe((result: any) => {
        if (result != null)
        {
          const resultStr = result as string;
          if (resultStr.includes('metadata#users'))
          {
            this.graphService.getUserImage().subscribe(
              (blob: Blob) => {
                try{
                  this.isImageLoading = false;
                  const urlCreator = window.URL || window.webkitURL;
                  this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
                }
                catch(error)
                {
                  const e = error as Error;
                  console.log(e.message);
                }
              },
              (error: Error) => {
                this.isImageLoading = false;
                console.log(error.message);
              }
            );
          }
        }
        this.isImageLoading = false;
      },(error: Error) => {
          this.isImageLoading = false;
          console.log(error.message);
        }
      )
  }

  public isUserL2andAdmin():boolean
  {
    if (appSettings.isUserAMemberOf("RM_L2_Technicians") || appSettings.isUserAMemberOf("RM_Admins")) {
      return true;
    }
    return false;
  }

  public isUserLoggedIn():boolean
  {
      if (this.userName != null){
        return true;
      }
      return false;
  }

  public isUserAdmin(): boolean {
    return appSettings.isUserAMemberOf("RM_Admins");
  }

  refreshRoles() {
    this.currentRoles['Administrator'] =  appSettings.isUserAMemberOf("RM_Admins");
    this.currentRoles['L2 Technician'] =  appSettings.isUserAMemberOf("RM_L2_Technicians"); // L2 Owners/CoOwners
    this.currentRoles['L1 Technician'] =  appSettings.isUserAMemberOf("RM_L1_Technicians"); // Readonly
    this.currentRoles['Lab Customer'] =  appSettings.isUserAMemberOf("RM_Lab_Customers"); // ReadOnly
    this.currentRoles['No Role'] =
      !this.currentRoles['Administrator'] && !this.currentRoles['L1 Technician'] &&
      !this.currentRoles['L2 Technician'] && !this.currentRoles['Lab Customer'];

    this.currentRole = Object.keys(this.currentRoles).filter(key => this.currentRoles[key] === true).join(', ');
  }
}
