import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'app/common/event-emitter-service';

@Component({
  selector: 'app-off-vpn',
  templateUrl: './off-vpn.component.html',
  styleUrls: ['./off-vpn.component.scss']
})
export class OffVpnComponent implements OnInit {
  public errorMessage: string | null = "undefined";
  constructor(private Routes: Router,  private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
      this.errorMessage = sessionStorage.getItem("loginError") != null ? sessionStorage.getItem("loginError") : this.errorMessage;
      this.eventEmitterService.onRefreshPage();
  }
}
