<style>
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
</style>

<div class="footer navbar-light" style="z-index:10000">
  <div class="row">
    <div class="footer-text col-9">Recipe Management ({{commitVersion}}) | Intel Confidential | <a
        href="https://circuit.intel.com/content/corp/Global_Employee_and_Global_Contingent_Worker_Privacy.html">Privacy
        Notice</a></div>
    <div class="col-3">
      <div class="mr-3" [style]="{'float': 'right'}">
        <button kendoButton size="small"  #anchor
          [icon]="show ? 'arrow-chevron-down' : 'arrow-chevron-up'" (click)="onToggle()"
          [toggleable]="true" [class]="rm_notificationService.lastSeverityClass">
            <span *ngIf="rm_notificationService.notificationMessages.length > 0">{{rm_notificationService.notificationMessages[rm_notificationService.notificationMessages.length - 1].summary}}</span>
            <span *ngIf="rm_notificationService.notificationMessages.length === 0">Notifications</span>
          <kendo-badge [position]="'edge'" [align]="{vertical: 'top', horizontal: 'start'}" rounded="full" [class]="rm_notificationService.lastSeverityClass">{{rm_notificationService.notificationMessages.length}}
          </kendo-badge>
        </button>
      </div>
      <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class="content">
          <table *ngIf="rm_notificationService.notificationMessages.length > 0" style="width: 200px" [cellSpacing]="'2px'" [cellPadding]="'2px'">
            <tr *ngFor="let item of rm_notificationService.notificationMessages" [class]="item.severity">
              <td [vAlign]="'top'"><span class="k-icon k-i-close-outline" style="cursor:pointer"
                  (click)="rm_notificationService.removeNotification(item)"></span></td>
              <td [vAlign]="'top'" [title]="item.detail">{{item.summary}}</td>
            </tr>
          </table>
        </div>
      </kendo-popup>
    </div>
  </div>
</div>
