import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SearchGateway } from '../gateways/search.gateway';

@Injectable()
export class StartupService {
    private _wasSiteDataLoaded: Boolean = false;

    constructor(private searchService: SearchGateway) { }

    public async loadSites(): Promise<any> {

        this._wasSiteDataLoaded = false;
        const areSitesLoading$ = this.searchService.getSites();
        var data = await lastValueFrom(areSitesLoading$).catch((err: Error) => {
            alert(err.message);
            console.log(err.message);
            Promise.reject();
        });

        if (data != undefined){
            localStorage.setItem("RmSiteList", JSON.stringify(data));
            this._wasSiteDataLoaded = true;
            return Promise.resolve();
        }
    }
    public get startupData(): Boolean {
        return this._wasSiteDataLoaded;
    }
}