export class GraphUsers {
    id: string = "";
    displayName: string = ""; // DisplayName
    givenName: string = ""; // GivenName
    surname: string = ""; //string Surname
    userPrincipalName: string = ""; // UserPrincipalName
    accountEnabled: string = ""; // AccountEnabled
    businessPhone: string = ""; // BusinessPhones
    mobilePhone: string = ""; // MobilePhone
    officeLocation: string = ""; // OfficeLocation
    jobTitle: string = ""; // JobTitle
    mail: string = ""; // Mail
    city: string = ""; // City
    country: string = ""; // Country
    skipToken: string = ""; // SkipToken
    onPremisesSamAccountName: string ="";
    onPremisesDomainName: string="";
  }
  
  