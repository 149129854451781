<div class="main-content administration-content">
  <h4>Administration</h4><br />
  <div>
    <kendo-dropdownlist [data]="listSearchOptions" [textField]="'text'" [valueField]="'value'" class="filter-type"
      style="width: 300px;" (valueChange)="listSearchValueChange($event)" [(ngModel)]="selectedListSearchItem" [listHeight]="250">
    </kendo-dropdownlist>
  </div>
  <div style="margin-top: 25px; height: 85%;">
    <kendo-grid id="adminGrid" [data]="adminGridData" [resizable]="true" [filterable]="true"
      (dataStateChange)="dataStateChange($event)" [pageSize]="gridSettings.state.take" [skip]="gridSettings.state.skip"
      [sort]="gridSettings.state.sort" [loading]="gridIsLoading" [filter]="gridSettings.state.filter"
      [sortable]="sortSettings" [pageable]="true" [reorderable]="true" [selectable]="selectableSettings"
      [navigable]="true" (filterChange)="filterChange($event)" (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)" (save)="saveHandler($event)" (remove)="removeHandler($event)"
      (add)="addHandler($event)">
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand icon="plus" [hidden]="!showMenuButtons">Add New Record</button>
        <button kendoButton iconClass="intelicon-import-outlined" (click)="importData()" [hidden]="!showMenuButtons || !isEditable">Import...</button>
        <button kendoGridExcelCommand icon="file-excel" [hidden]="!showMenuButtons">Export to Excel</button>
      </ng-template>
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column *ngFor="let col of searchGridColumns" [field]="col.field" [title]="col.title"
        [width]="col.width" [format]="col.format" [filter]="col.type" [headerStyle]="{'font-size': 'small'}"
        [style]="{'font-size': 'small', 'padding-top': '0', 'padding-bottom': '0', 'white-space': 'pre-wrap'}">
        <ng-template *ngIf="col.field == 'boardPBAId'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-textbox #input="popupAnchor" popupAnchor (valueChange)="boardPBAIdTextBoxOnChange($event)" [formControl]="formGroup.get('boardPBAId')"
            style="width: 160px; margin-right: 10px;" [size]="'small'">
          </kendo-textbox>
          <kendo-popup [anchor]="input.element" *ngIf="boardPBAIdHasError" popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg">
            <div style="width:150px; font-size:small"><span class="k-icon k-i-exclamation-circle"></span> {{ boardPBAIdErrorMessage }}</div>
          </kendo-popup>
          <button kendoButton (click)="searchBoardType()" icon="search" title="Search" [size]="'small'" [disabled]="isBoardTypeButtonDisabled"></button>
        </ng-template>
        <ng-template *ngIf="col.field == 'boardTypeName'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-textbox [readonly]="true" [value]="boardTypeNameSelected" [formControl]="formGroup.get('boardTypeName')" [size]="'small'"></kendo-textbox>
        </ng-template>
        <ng-template *ngIf="col.field == 'labs'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-multiselect [data]="labsList" [formControl]="formGroup.get('labs')"></kendo-multiselect>
        </ng-template>
        <ng-template *ngIf="col.field == 'siliconProgramName'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-autocomplete #programsAutoComplete [data]="programNamesList" placeholder="type program name and then click search"
            (valueChange)="programsValueChange($event)" [suggest]="true" style="width: 180px; margin-right: 10px;" [size]="'small'"
            [loading]="programsAutoCompleteIsLoading" [formControl]="formGroup.get('siliconProgramName')">
          </kendo-autocomplete>
          <button kendoButton (click)="searchPrograms()" icon="search" title="Search" [size]="'small'"></button>
        </ng-template>
        <ng-template *ngIf="col.field == 'productName'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-combobox #productComboBox [data]="productsList" textField="productName" valueField="productID" [size]="'small'" [(ngModel)]="selectedProduct"
            [formControl]="formGroup.get('productName')"></kendo-combobox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column [width]="220">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button kendoGridEditCommand [primary]="true" *ngIf="isEditable">Edit</button>
          <button kendoGridRemoveCommand>Remove</button>
          <button kendoGridSaveCommand [disabled]="formGroup.invalid">
            {{ isNew ? "Add" : "Update" }}
          </button>
          <button kendoGridCancelCommand>
            {{ isNew ? "Discard Changes" : "Cancel" }}
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-excel fileName="rm_admin_data.xlsx" [fetchData]="allData"></kendo-grid-excel>
    </kendo-grid>
    <kendo-dialog title="Please confirm" *ngIf="showDialog" (close)="closeDialog('cancel')" [minWidth]="250" [width]="450">
      <p style="margin: 30px; text-align: center;">
        Dependencies were found on this record. Are you sure you want to continue with deleting this item?
      </p>
      <kendo-dialog-actions>
        <button kendoButton (click)="closeDialog('yes')">Yes</button>
        <button kendoButton (click)="closeDialog('no')" themeColor="primary">No</button>
      </kendo-dialog-actions>
    </kendo-dialog>
    <kendo-dialog title="Import Data" *ngIf="showImportDialog" (close)="closeImportDialog('cancel')" [minWidth]="250" [width]="450">
      <p>Select a .xlsx file for import.  Make sure your column name(s) match what shows in grid.</p>
      <kendo-fileselect zoneId="myZone" (select)="onImportSelect($event)" [restrictions]="importRestrictions" [showFileList]="false" [multiple]="false"></kendo-fileselect>
    </kendo-dialog>
    <kendo-dialog [animation]="animation" *ngIf="errorDialogOpened" (close)="dialogWindowClose('error')" [minWidth]="250" [width]="450" (close)="errorDialogAction('exit')">
      <kendo-dialog-titlebar>
          <div style="font-size: 18px; line-height: 1.3em;">
            <span class="k-icon k-i-error"></span> {{messageTitle}}
          </div>
        </kendo-dialog-titlebar>
      <i aria-hidden="true"></i> {{messageDescription}}
      <kendo-dialog-actions>
          <button type="button" class="k-button" (click)="copyText()" >Copy Message & Close</button>
          <button type="button" class="k-button k-primary" (click)="errorDialogAction('exit')" primary="true">Close</button>
      </kendo-dialog-actions>
  </kendo-dialog>
  </div>
</div>
