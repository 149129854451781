export class Employee {
    accessorID: number = 0;
    loginName: string = "";
    firstName: string = "";
    lastName: string = "";
    accessorName: string = "";
    wwid: string = "";
    emailAddress: string = "";
    roleName: string = "";
    photo: [] = [];
  }
  