import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserauthguardGuard  {

  constructor(private msalService:MsalService)
  {

  }
  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     // AGS entitlements to be used here. Based on roles we can render components in main page.

      if ("LoginFailure" in sessionStorage)
      {
        return false;
      }
      else
      {
        return true;
      }
  }
}