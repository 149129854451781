import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { DataItem, DataStateChangeEvent, PageChangeEvent, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { process, CompositeFilterDescriptor, filterBy, distinct } from '@progress/kendo-data-query';
import { EventEmitterService } from 'app/common/event-emitter-service';
import { SearchGateway } from 'app/gateways/search.gateway';
import { appSettings } from 'app/modules/shared/app-settings';
import { lastValueFrom, Subscription, takeUntil, Subject } from 'rxjs';
import { BuildingBlock } from '../shared/model/building-block';
import { Recipe, SelectedBuildingBlock } from '../shared/model/recipe';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogAnimation } from '@progress/kendo-angular-dialog';
import { AnimationConfiguratorService } from 'app/common/animation-configurator-service';
import { CreateGateway } from 'app/gateways/create.gateway';
import { environment } from 'environments/environment';
import { Person } from '../shared/model/person';
import { FileGateway } from 'app/gateways/file.gateway';
import { ThirdPartyAppGateway } from 'app/gateways/third-party-app.gateway';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { GraphUsers } from '../shared/model/graph-users';

import {
  QueryApiService,
  RecordApiService,
  UserApiCacheService,
} from '@apps-es/api'
import { LabInfo } from '../shared/model/lab';
import { WorkQ } from '../shared/model/work-q';
import { GridSettings } from 'app/common/grid-settings-interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContextMenuComponent, ContextMenuSelectEvent } from '@progress/kendo-angular-menu';
import { Config } from '../shared/model/config';
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { forEach, update } from 'lodash';
import { UserService } from 'app/common/user-service';
import { ToolsIntegration } from '../shared/model/tools-integration';
enum RecipeEnumerations {
  CreateHsdRecipe = 0,
  AssignHsdRecipe = 1,
  ShowPdfRecipe = 2,
  EditRecipe = 3,
  DeleteRecipe = 4,
  CopyRecipe = 5
}

enum BBEnumerations {
  ShowPdfBB = 0,
  EditBB = 1,
  DeleteBB = 2,
  CopyBB = 3,
  CreateRecipeBB = 4
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {
  @ViewChild("gridmenu")

  public gridContextMenu!: ContextMenuComponent;
  public selectedRowKeys: number[] = [];
  public selectedRowKeysBBInActiveRecipe: number[] = [];
  public rmOwnersList: string[] = [];
  public rmCoOwnersList: string[] = [];
  public buildingBlock: string | undefined;
  public searchRowFound: boolean = false;
  public exportData: any = null;
  public bbExportData: any = null;
  public recipeRevData: any = null;
  public recipeData: any = null;
  public bbRevHisData: any = null;
  public isReloadData: boolean = false;

  public destroy$: Subject<boolean> = new Subject<boolean>();

  public contextMenuOptionsForRecipe: any[] = [
    { text: "Create HSD-ES/CC", icon: "file-txt", disabled: false },
    { text: "Assign HSD-ES to CC", icon: "forward-element", disabled: false },
    { text: "Show PDF", icon: "file-pdf", disabled: false },
    { text: "Edit Recipe", icon: "edit-tools", disabled: false },
    { text: "Delete Recipe", icon: "delete", disabled: false },
    { text: "Copy Recipe", icon: "copy", disabled: false }
  ];

  public contextMenuOptionsForBB: any[] = [
    { text: "Show PDF", icon: "file-pdf", disabled: false },
    { text: "Edit Building Block", icon: "edit-tools", disabled: false },
    { text: "Delete Building Block", icon: "delete", disabled: false },
    { text: "Copy Building Block", icon: "copy", disabled: false },
    //Hide the menu for now
    { text: "Create Recipe", icon: "file-add", disabled: false }
  ];

  public filter!: CompositeFilterDescriptor;

  public isFilterVisible: boolean | undefined;
  public rmProductsList: string[] = [];
  public hsdEsGridData: any[] = [];
  public recipeGridData: any[] = [];
  public gridData: any = null;
  public supportGridData: any = null;
  public bbCheckListGridData: any = null;
  public articleGridData: any = null;
  public recipeWithBuildingBlocksGridData: any = null;
  public bbHistoryGridData: any = null;
  public recipeHistoryGridData: any[] = [];
  public recipeWithBBGridData: any[] = [];
  public buildingBlockGridData: any[] = [];
  public buildingBlockHistoryGridData: any[] = [];
  public buildingBlockDetailsGrid: any[] = [];
  public wordSearchFilterSelected: string = 'All';
  public createHSD: string = "Create HSD-ES/CC";
  public assignHSD: string = "Assign HSD-ES to CC"
  public editOrViewButtonLabel: string = "";
  public isHsdButtonDisabled: boolean = false;
  public isCreateRecipeEnabled: boolean = false;
  public showHsdEsWaitIndicator: boolean = false;
  public btnToggle: boolean = true;
  public btnStatus: string = "Enable";
  public testingHSD: any[] = [];

  //Ramp-Down Configurations 
  public isRampDown: string = "";
  public workQCategory: string = "";

  //LM Configurations
  public isWindowLMPopUp:boolean =false;
  public isWindowAssignHSD:boolean = false;
  public systemcheckersites:any=[];
  public systemcheckerconfig:any =[];

  public selectedWordSearch: string = "";
  public selectedHSDESSearch: string = "hsdesid"; //Here Ken
  public wordSearchFilterTypes: Array<{ label: string; value: string }> = [];
  public hsdesAssignWordSearchFilterTypes: Array<{ label: string; value: string }> = [];
  public rmExecutionTypeList: string[] = [
    'Power-On Readiness',
    'Execution'
  ];
  public form = new FormGroup({
    switch: new FormControl(null, Validators.required)
  });
  public gridSettings: GridSettings = {
    state: {
      skip: 0,
      take: 10,

      filter: {
        logic: 'and',
        filters: [],
      },
    }
  };

  public rmValidationTeamsList: string[] = [];
  public rmBoardTypeList: string[] = [];
  public rmSiliconProgramsList: string[] = [];

  public fromDate: Date = new Date();
  public toDate: Date = new Date();


  public currentUser: GraphUsers | null;

  public selectedItemFromGrid: any;
  public placeholderValue: string = "All"; // to be replaced.
  public defaultOwner: string = "";
  public selectedLabs: string[] = [];
  public selectedBoardTypes: string[] = [];
  public selectedProducts: string[] = [];
  public selectedCoOwners: string[] = [];
  public selectedValidationTeams: string[] = [];
  public selectedBuildingBlockList: any[] = [];
  public selectedExecutionType: string = "";
  public selectedOwner: string = "";
  public selectedSiliconProgram: string = "";
  public HsdEsTicketCount: number = 0;
  public selectedHsdEsTickets: any[] = [];
  public isMainGridChange: boolean = false;

  public selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'single'
  };
  private filterGetLabsSubscription: Subscription = new Subscription();
  private filterGetBoardTypesSubscription: Subscription = new Subscription();
  private filterGetProductsSubscription: Subscription = new Subscription();
  private filterGetOwnerSubscription: Subscription = new Subscription();
  private filterGetCoOwnersSubscription: Subscription = new Subscription();
  private filterGetValidationTeamsSubscription: Subscription = new Subscription();
  private filterGetSiliconProgramsSubscription: Subscription = new Subscription();
  private filterGetRecipeRevHistorySubscription: Subscription = new Subscription();
  private filterGetBBDetailsSubscription: Subscription = new Subscription();
  private filterGetRecipesSubscription: Subscription = new Subscription();
  private filterGetBBsSubscription: Subscription = new Subscription();
  private filterGetRecipesWithBBSubscription: Subscription = new Subscription();
  private filterGetHsdEsArticlesSubscription: Subscription = new Subscription();
  private filterUpdateHsdEsArticlesSubscription: Subscription = new Subscription();
  private filterCreateWorkQSubscription: Subscription = new Subscription();
  private filterGetUserIdsidSubscription: Subscription = new Subscription();
  private filterGetUsersSubscription: Subscription = new Subscription;
  private filterCreateToolsIntegrationSubscription: Subscription = new Subscription;

  public enableDelete: boolean = false;
  public enableEdit: boolean = false;
  public formIsDirty: boolean = false;

  public requestGridLoading: boolean = false;
  public isRecipeRevHistoryGridLoading: boolean = false;
  public buildingBlockRevHistoryGridLoading: boolean = false;
  public recipeWithBBGridLoading: boolean = false;
  public setSelectedCoowner: any;
  public setSelectedOwner: any;
  public coownerDisplayName: string = '';
  public ownerDisplayName: string = '';
  public allRequestData: any = null;
  public mainGridData: any[] = [];
  public hsdEsArticleGridData: any[] = [];
  public searchGridLoading: boolean = false;
  public isBB: boolean | null = false;
  public isBBTemplate: boolean | null = false;
  public isEmpty: boolean = true;
  public isSearchDisabled: boolean = true;
  public rmLabList: string[] = [];
  public createMode = '';
  public rmSelectedBoardType = "";

  public IsContextMenuTrigger: boolean = false;
  public IsCheckBokClicked: boolean = false;

  public checkListData: Array<any> = [];

  /* ======= LM Integration Variables ======= */

  public lmSiteList: string[] = [];
  public lmLabList: any[] = [];
  public lmVTList: any[] = [];
  public lmActivitiesList: any[] = [];
  public lmProjectList: any[] = [];
  public lmOwnerList: any[] = [];
  public lmVTNamesList: any[] = [];
  //public isWindowRecipeHSDCreate:boolean =false;
  public selectedLMSite: string = "";
  //public userEmail: string | null = "";
  public lmActiveStates: Array<string> = ["Active", "Inactive", "Lab Readiness", "Ramping-Up", "Ramping-Down", "Under Repair"];
  public lmIntegrationRequest = new ToolsIntegration();
  public userName: string = ''; //store current user name
  public userEmail: string = ''; //store current user email
  public userWwid: string = ''; //store current user wwid
  public userIdsid: string = ''; //store Lab Manager Owner Idsid to pass to GTS TOOl Customer Contact Field Auto Populate
  public updateToolsIntegration = new ToolsIntegration();
  public isButtonAssignHSDESToCC: boolean = false; 
  public boolBulkTicket: boolean = false;
  public isLMHostAvailable: boolean = true;

  public assignWorkQCases: string = ""; // used to store assign hsdes workq numbers to update into RM containers

  public assignHSDNumbers: string = ""; // used to store assignHSDES hsd numbers to update into RM containers

  //Host Details variables

  public lmActiveState: string = "";
  public lmActivity: string = "";
  public lmBoardSN: string = "";
  public lmComponents: any[] = [];
  public lmDescription: string = "";  
  public lmFocus: string = "";
  public lmGroup: string = "iVE";
  public lmHostName: string = "";
  public lmLab: string = "";
  public lmLocation: string = "";  
  public lmOwner: string = "";
  public lmProject: string = "";
  public lmProjectID: string = "";
  public lmSite: string = "";
  public lmVTName: string = "";

  //public lmDefaultSite: string = ""; //Ken add this for reset button to lmDefaultSite

  /* ================= END ================== */

  /* Loading spinners */

  public areLabsLoading: boolean = false;
  public areBoardTypesLoading: boolean = false;
  public areProductsLoading: boolean = false;
  public isOwnerLoading: boolean = false;
  public isCoownerLoading: boolean = false;
  public areValidationTeamsLoading: boolean = false;
  public areSiliconProgramsLoading: boolean = false;

  public isRecipesWithBBOn = false;
  public isBBRevisionHistoryOn = false;

  public timeNowHSDStr: string = '';
  public timeNowHSD: Date = new Date();

  // public timeNowHSDToday: string = '';
  // public timeNowHSDWeek: string = ''; 

  /* Right-hand Panel */

  public recipeId: string = '';
  public idNumber: string = '';
  public idName: string = ''; //Ken added this for BB/Recipe Name in Display 20/9/2023
  public idNumberName: string = ''; //Ken added this for BB/Recipe name display in 22/9/2023
  public parentRecipeNumber: string = '';
  public parentRecipeRevision: string = '';
  public parentBBNumber: string = '';
  public parentBBRevision: string = '';
  public status: string = '';
  public createdDate: string = '';
  public createdBy: string = '';
  public description: string = '';
  public checkList: string = '';
  public siliconProgram: string = '';
  public module: string = '';
  public rampUp: string = '';
  public recipeCount: number = 0;
  public updatedBy: string = '';
  public updatedDate: string = '';
  public currentAction: string | null = '';
  public isItATemplate: boolean = false;
  public isItADraft: boolean = false;
  public isItArchived: boolean = false;
  public enableViewOrEdit: boolean = true;
  public owner: Person = new Person;

  /* Modal Pop-up */

  public errorDialogOpened: boolean = false;
  public promptDialogOpened: boolean = false;
  public actionOnItem: string = "";
  public alertDialogOpen: boolean = true;
  public messageDescription: string = "";
  public messageTitle: string = "";
  public animation: boolean | DialogAnimation = {};
  public animationConfig: AnimationConfiguratorService = new AnimationConfiguratorService();
  public incomingMode: string | null = "";
  public fileUrl: string = "";
  public checkListContentLoading: any = {};
  public isWindowHsdEsArticlesOpened: boolean = false;
  public isWindowBuildingBlockPdfsOpened: boolean = false;
  public isWindowRecipePdfsOpened: boolean = false;
  public windowOpenedTitle: string = "";
  public isPdfOptionDisabled: boolean = false;
  public enableCopy: boolean = false;
  private contextItem: any;

  /* PDF functionality*/

  public isRecipeEmpty: boolean = false;
  public hideIndividualBBOptions: boolean = false;
  public combinedPDFDocument: string = "";
  public isCombinedPdfLoading: boolean = false;
  public isHsdEsGridLoading: boolean = false;
  public isSearchHsdEsDisabled: boolean = false;
  public isAssignedHsdEsDisabled: boolean = true;
  public isAssignHsdEsToCCDisabled: boolean = false;
  public isSupportGridDataLoading: boolean = false;
  public recipeDocBinary: any = {};
  public recipeDoc: any = { url: '' };

  public newRecipeDoc: any = {};



  constructor(private searchGateway: SearchGateway,
    private createGateway: CreateGateway,
    private authService: MsalService,
    private route: ActivatedRoute,
    private Routes: Router,
    private clipboard: Clipboard,
    private eventEmitterService: EventEmitterService,
    public thirdPartyAppGateway: ThirdPartyAppGateway,
    public fileGateway: FileGateway,
    public queryApi: QueryApiService,
    public recordApi: RecordApiService,
    public userApiCache: UserApiCacheService,
    public userService: UserService,
    public graphUsersGateway: GraphUsersGateway) {
    this.currentUser = new GraphUsers();
    this.allData = this.allData.bind(this);
    this.bbData = this.bbData.bind(this);
    this.revHistoryData = this.revHistoryData.bind(this);
    this.recipesData = this.recipesData.bind(this);
    this.bbRevHistoryData = this.bbRevHistoryData.bind(this);
  }

  ngOnInit(): void {

    var account = this.authService.instance.getActiveAccount();
    if (account != undefined) {
      var accountName = account.name;
      if (accountName != undefined) {
        this.userName = accountName;
        this.userEmail = account.username;
        this.setUserWwid();
      }
    }



    // Call GetUserConfiguration function to get user's default/home site (populate site in LM popup)
    this.getUserConfig();
    

    this.fileUrl = this.fileGateway.getUrl('', '');
    this.route.paramMap.subscribe(params => {

      this.clearAll(true);
      sessionStorage.setItem("IsRedirectFromView", "0");
      sessionStorage.removeItem("viewSelectedTab");
      this.selectedBuildingBlockList = [];
      this.hideIndividualBBOptions = this.selectedBuildingBlockList.length > 1 ? true : false;
      this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;
      //this.disableBBContextMenu();

      this.enableCopyItem();
      this.selectedChipIndex = 0;
      //Ken added this to check if the advanced filter is off or not on 26/9/2023
      if (this.isFilterVisible == true) {
        setTimeout(function () {
          let element: HTMLElement = document.querySelector('#auto_trigger_filter') as HTMLElement;
          element.click();
        }, 1000);
      }

      if (params.get('mode')) {
        this.incomingMode = params.get('mode');
        if (this.incomingMode) {

          switch (this.incomingMode.toLowerCase()) {
            case "bb":
              this.isBB = true;
              this.isBBTemplate = false;
              this.createMode = "Building Block";
              this.editOrViewButtonLabel = "View Building Block";
              break;
            case "bb-template":
              this.isBB = true;
              this.isBBTemplate = true;
              this.createMode = "Building Block Template";
              this.editOrViewButtonLabel = "View Building Block";
              break;
            case "recipe":
              this.isBB = false;
              this.isBBTemplate = false;
              this.createMode = "Recipe";
              this.editOrViewButtonLabel = "View Recipe";
              break;
            case "recipe-template":
              this.isBB = false;
              this.isBBTemplate = false;
              this.createMode = "Recipe Template";
              this.editOrViewButtonLabel = "View Recipe";
              break;
            case "mode":
              sessionStorage.setItem("viewMode", "recipe");
              this.Routes.navigate(['recipe/view']);
              this.isBB = false;
              this.isBBTemplate = false;
              break;
            default:
              this.Routes.navigate(['']);
              break;
          }
          sessionStorage.setItem("viewMode", this.incomingMode.toLowerCase());
        }
      }
      else {
        sessionStorage.setItem("viewMode", "recipe");
        this.Routes.navigate(['recipe/view']);
        this.isBB = false;
        this.isBBTemplate = false;
      }

      this.setDefaultDates();

      var mode = sessionStorage.getItem("viewMode");

      if (mode != null) {
        this.getCurrentUserConfiguration();
        this.setSearchFilters(mode.toLowerCase());
      }

    });

    if (localStorage.getItem("IntegrationID") != null) {
      localStorage.removeItem("IntegrationID");
    }
    if (localStorage.getItem("createdByWwid") != null) {
      localStorage.removeItem("createdByWwid");
    }

    //Hide the menu for now
    //this.handleContextMenu("CreateRecipe", this.isCreateRecipeEnabled);
  }
  //Ken added this to auto click on the search button 29/8/2023
  // ngAfterViewInit()
  // {
  //   setTimeout(function(){ let element:HTMLElement = document.querySelector('#auto_trigger_filter') as HTMLElement;
  //   element.click(); }, 1000);
  // }

  public getUserIdsid(name: string): void {
  this.graphUsersGateway.getUsers(name.trim(), 10, '', true)
  .subscribe(userData => {
    if (userData.length === 1) {      
      this.currentUser = userData[0];
      this.userIdsid = "";
      this.userIdsid = this.currentUser.onPremisesSamAccountName; //returns idsid
    }
  })
}

  public onCellClick(e: any): void {
    if (e.type === "contextmenu") {
      const originalEvent = e.originalEvent;
      this.IsContextMenuTrigger = true;
      this.IsCheckBokClicked = false;
      originalEvent.preventDefault();
      var rowIndex = e.rowIndex;
      this.contextItem = e.dataItem;
      this.selectedItemFromGrid = this.contextItem;

      this.selectedRowKeys = [];
      var index = -1;
      switch (this.incomingMode?.toLowerCase()) {
        case "bb":
        case "bb-template":
          this.contextItem.isSelected = true;
          this.gridBBSelectionChange(this.contextItem);
          if (this.hideIndividualBBOptions) {
            this.contextMenuOptionsForBB[BBEnumerations.ShowPdfBB].disabled = true;
            this.contextMenuOptionsForBB[BBEnumerations.CopyBB].disabled = true;
            this.contextMenuOptionsForBB[BBEnumerations.DeleteBB].disabled = true;
            this.contextMenuOptionsForBB[BBEnumerations.EditBB].disabled = true;
            this.contextMenuOptionsForBB[BBEnumerations.CreateRecipeBB].disabled = false;
          }
          this.gridContextMenu.items = this.contextMenuOptionsForBB;
          break;
        case "recipe":
        case "recipe-template":
        default:
          this.gridRecipeSelectionChange(this.contextItem);
          this.gridContextMenu.items = this.contextMenuOptionsForRecipe;
          break;
      }
      this.selectedRowKeys.push(rowIndex);
      this.gridContextMenu.show({ left: originalEvent.pageX, top: originalEvent.pageY });
    }
    else {
      this.gridContextMenu.items = [];
    }
  }

  public onSelectContextMenuItem(e: ContextMenuSelectEvent) {

    if (e.item != undefined && e.item.text != undefined) {
      switch (e.item.text.toLowerCase()) {
        case "edit recipe":
        case "view recipe":
          this.btnActionsClick('edit-recipe');
          break;
        case "edit building block":
        case "view building block":
          this.btnActionsClick('edit-bb');
          break;
        case "copy recipe":
          this.btnActionsClick('copy-recipe');
          break;
        case "create recipe":
          this.btnCreateRecipe();
          break;
        case "copy building block":
          this.btnActionsClick('copy-bb');
          break;
        case "delete recipe":
          this.btnActionsClick('delete-recipe');
          break;
        case "delete building block":
          this.btnActionsClick('delete-bb');
          break;
        case "show pdf":
          switch (this.incomingMode?.toLowerCase()) {
            case "bb":
            case "bb-template":
              this.btnShowBBPdf();
              break;
            case "recipe":
            case "recipe-template":
            default:
              this.btnShowRecipePdf();
              break;
          }
          break;
        case "assign hsd-es to cc":
          this.btnAssignHsdEs();
          //this.btnOpenHsdEsArticlesPopupWindow();
          break;
        case "create hsd-es/cc":
        case "update from hsd-es":
          this.btnCreateHsdEs();
          //this.btnShowCreateCC();
          
          break;
        default:
          break;
      }
    }
  }

  private enableCopyItem() {
    /* Enable copy */

    if (appSettings.isUserAMemberOf("RM_L1_Technicians")) {
      this.enableCopy = false;
    }
    else {
      this.enableCopy = true;
    }
    this.handleContextMenu("Copy", this.enableCopy);
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridSettings.state.skip = event.skip;
    if (!this.isReloadData) {
      this.selectedRowKeys = [];
    }
    if (this.isMainGridChange) {
      this.reloadGridData();
    }
  }

  public reloadGridData() {
    this.requestGridLoading = true;
    var mode = sessionStorage.getItem("viewMode");

    if (mode != null) {
      if (this.gridSettings.state.filter) {
        this.gridData = process(filterBy(this.mainGridData, this.gridSettings.state.filter), this.gridSettings.state);
      }
      var selectRow: number = <number>this.gridSettings.state.skip;
      var sIndex: number = (selectRow % 10 == 0 ? 0 : selectRow);
      switch (mode.toLowerCase()) {
        case "bb":
        case "bb-template":
          this.buildingBlockGridData = [];
          this.buildingBlockGridData = process(this.mainGridData, this.gridSettings.state).data;//process(this.gridData, this.gridSettings.state).data;
          this.selectedItemFromGrid = this.gridData.data[sIndex];
          break;
        case "recipe":
        case "recipe-template":
          this.recipeGridData = [];
          this.recipeGridData = process(this.mainGridData, this.gridSettings.state).data;//this.gridData;
          this.selectedItemFromGrid = this.gridData.data[sIndex];
          break;
        default:
          break;
      }
      this.exportData = this.mainGridData;
      this.recipeData = this.mainGridData;
      this.selectedRowKeys.push(selectRow);
      this.onTabSelect();
    }
    this.requestGridLoading = false;
  }
  public rowCallback(context: RowClassArgs) {

    var item: any = null;
    var mode = sessionStorage.getItem("viewMode");

    if (mode != null) {
      switch (mode.toLowerCase()) {
        case "recipe":
        case "recipe-template":
          if ("viewRecipeGridSelectedItem" in sessionStorage) {
            var sessionStorageItem = sessionStorage.getItem("viewRecipeGridSelectedItem");
            if (sessionStorageItem != undefined) {
              item = this.selectedItemFromGrid == undefined ? JSON.parse(sessionStorageItem) as unknown as any : this.selectedItemFromGrid;
            }
          }
          break;
        case "bb":
        case "bb-template":
        default:
          if ("viewBBGridSelectedItem" in sessionStorage) {
            var sessionStorageItem = sessionStorage.getItem("viewBBGridSelectedItem");
            if (sessionStorageItem != undefined) {
              item = this.selectedItemFromGrid == undefined ? JSON.parse(sessionStorageItem) as unknown as any : this.selectedItemFromGrid;
            }
          }
          break;
      }
    }
    const isActiveItem = item != undefined ? context.dataItem.id == item.id : false;

    if (isActiveItem) {
      return { gold: true };
    } else {
      return { green: true };
    }
  }

  public dataStateChange(state: DataStateChangeEvent, gridType: string = ""): void {
    this.isReloadData = (<number>this.gridSettings.state.skip == state.skip && this.gridSettings.state.filter == state.filter);
    this.gridSettings.state = state;

    if (gridType == '') {
      this.isMainGridChange = true;
      this.reloadGridData();
    }
    else {
      this.isMainGridChange = false;
      switch (gridType.toLowerCase()) {
        case "buildingblockdetails":
          this.isSupportGridDataLoading = true;
          this.supportGridData = process(this.buildingBlockDetailsGrid, state);
          this.isSupportGridDataLoading = false;
          this.bbExportData = this.buildingBlockDetailsGrid;
          break;
        case "reciperevisionhistory":
          this.isRecipeRevHistoryGridLoading = true;
          this.supportGridData = process(this.recipeHistoryGridData, state);
          this.isRecipeRevHistoryGridLoading = false;
          this.recipeRevData = this.recipeHistoryGridData;
          break;
        case "hsdesarticles":
          this.isHsdEsGridLoading = true;
          this.articleGridData = process(this.hsdEsArticleGridData, state);
          this.isHsdEsGridLoading = false;
          break;
        case "recipewithbbs":
          this.recipeWithBBGridLoading = true;
          this.recipeWithBuildingBlocksGridData = process(this.recipeWithBBGridData, state);
          this.recipeWithBBGridLoading = false;
          break;
        case "bbhistory":
          this.buildingBlockRevHistoryGridLoading = true;
          this.bbHistoryGridData = process(this.buildingBlockHistoryGridData, state);
          this.buildingBlockRevHistoryGridLoading = false;
          this.bbRevHisData = this.buildingBlockHistoryGridData;
          break;
        default:
          break;
      }
    }
  }

  public openLMPopUp(): void{
    this.btnResetValueLM();
    this.isWindowLMPopUp = true;
  }

  public closeLMPopUp(): void{
    //this.btnResetValueLM();
    this.isWindowLMPopUp = false;
    this.showHsdEsWaitIndicator = false;
  }

  public openPopupWindow(windowName: string): void {

    switch (windowName.toLowerCase()) {
      case "hsdesarticles":
        this.isWindowLMPopUp = false;
        this.isWindowHsdEsArticlesOpened = true;
        //this.isWindowAssignHSD = true;
        
        break;
      case "buildingblockpdf":
        this.isWindowBuildingBlockPdfsOpened = true;
        break;
      case "recipepdf":
        this.isWindowRecipePdfsOpened = true;
        break;
      // case "createcc":
      //   this.isWindowRecipeHSDCreate = true;
      //   break;
      // case "lmassignhsd":
      //   this.isWindowAssignHSD = true;
      //   break;
      default:
        break;
    }
  }

  public closePopupWindow(windowName: string): void {

    switch (windowName.toLowerCase()) {
      case "hsdesarticles":
        this.isAssignHsdEsToCCDisabled = false;
        this.isWindowHsdEsArticlesOpened = false;
        this.showHsdEsWaitIndicator = false;
        break;
      case "buildingblockpdf":
        this.isWindowBuildingBlockPdfsOpened = false;
        break;
      case "recipepdf":
        this.isWindowRecipePdfsOpened = false;
        break;
      // case "createcc":
      //   this.isWindowRecipeHSDCreate = false;
      //   this.isHsdButtonDisabled = false;
      //   break;
      // case "lmassignhsd":
      //   this.isWindowAssignHSD = false;
      //   break;
      default:
        break;
    }
  }

  public btnShowBBPdf(): void {

    this.openPopupWindow("buildingblockpdf");
  }

  public btnShowRecipePdf(): void {

    this.openPopupWindow("recipepdf");
  }

  private setDefaultDropDowns(): void {

    sessionStorage.removeItem("viewSelectedLabs");
    this.isEmpty = true;
  }

  private setDefaultDates(): void {

    this.fromDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 365);

    this.toDate = new Date();
    this.toDate.setDate(this.toDate.getDate() + 1);

  }

  public getCurrentUserConfiguration(): void {
    var userWWID = sessionStorage.getItem("appSettings.appUserWwid");
    if (userWWID) {
      this.createGateway.getConfiguration(userWWID).subscribe({
        next: (result) => {
          if (result) {
            const config: any = result;
            const updatedLabs: any[] = [];
            const updatedProducts: any[] = [];
            const updatedValTeams: any[] = [];

            config.labs.forEach((item: any) => {
              updatedLabs.push(item.name.toUpperCase());
            });

            config.products.forEach((item: any) => {
              updatedProducts.push(item.name.toUpperCase());
            });

            config.validationTeams.forEach((item: any) => {
              updatedValTeams.push(item.name.toUpperCase());
            });

            this.selectedLabs = updatedLabs;
            this.selectedProducts = updatedProducts;
            this.selectedValidationTeams = updatedValTeams;
            //this.selectedLMSite = config.defaultSite;

            sessionStorage.setItem("viewSelectedLabs", JSON.stringify(this.selectedLabs));
            sessionStorage.setItem("viewSelectedProducts", JSON.stringify(this.selectedProducts));
            sessionStorage.setItem("viewSelectedValidationTeams", JSON.stringify(this.selectedValidationTeams));

          }
        }
      });

    }
  }


  public lstWordSearchValueChange(value: any): void {

    this.selectedWordSearch = value.value;

    if (value.label.toLowerCase() != "search by") {
      this.defaultOwner = "All";
      this.selectedOwner = this.defaultOwner;
      sessionStorage.setItem("viewSelectedOwner", JSON.stringify(this.selectedOwner));
      //ly btn disabled
      (<HTMLInputElement>document.getElementById('searchBox')).disabled = false;
    }
    else {
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";
      if (this.selectedWordSearch.toLowerCase() !== "search by") {
        this.setDefaultDropDowns();
        this.setDefaultDates();
        this.setOwnerPlaceHolderFromMSAL();
        //ly btn disabled
        (<HTMLInputElement>document.getElementById('searchBox')).disabled = false;
      }
      //ly btn disabled
      (<HTMLInputElement>document.getElementById('searchBox')).disabled = true;
    }
    sessionStorage.setItem("viewSelectedWordSearch", JSON.stringify(this.selectedWordSearch));
  }

  private revertWordSearchToDefault(): void {

    this.selectedWordSearch = "Search By";

    try {
      if (document.getElementById('searchBox') != null) {
        (<HTMLInputElement>document.getElementById('searchBox')).value = "";
        //ly btn disabled
        (<HTMLInputElement>document.getElementById('searchBox')).disabled = true;
      }
    }
    catch (Error) {
      var ErrorObj = Error as Error;
      console.log(ErrorObj.message);
    }
  }

  public lstOnListValueChange(value: any, category: string) {

    this.revertWordSearchToDefault();

    switch (category.toLowerCase()) {
      case "labs":
        this.selectedLabs = value;
        this.isSearchDisabled = false;
        sessionStorage.setItem("viewSelectedLabs", JSON.stringify(this.selectedLabs));
        break;
      case "boardtypes":
        this.selectedBoardTypes = value;
        sessionStorage.setItem("viewSelectedBoardTypes", JSON.stringify(this.selectedBoardTypes));
        break;
      case "products":
        this.selectedProducts = value;
        sessionStorage.setItem("viewSelectedProducts", JSON.stringify(this.selectedProducts));
        break;
      case "owners":
        this.selectedOwner = value;
        sessionStorage.setItem("viewSelectedOwner", JSON.stringify(this.selectedOwner));
        break;
      case "coowners":
        this.selectedCoOwners = value;
        sessionStorage.setItem("viewSelectedCoOwners", JSON.stringify(this.selectedCoOwners));
        break;
      case "validationteams":
        this.selectedValidationTeams = value;
        sessionStorage.setItem("viewSelectedValidationTeams", JSON.stringify(this.selectedValidationTeams));
        break;
      case "siliconprograms":
        this.selectedSiliconProgram = value;
        sessionStorage.setItem("viewSelectedSiliconProgram", JSON.stringify(this.selectedSiliconProgram));
        break;
      case "executiontype":
        this.selectedExecutionType = value;
        sessionStorage.setItem("viewSelectedExecutionType", JSON.stringify(this.selectedExecutionType));
        break;
      default:
        break;
    }
  }

  public preloadValues(category: string) {

    switch (category.toLowerCase()) {
      case "labs":
        const labList = localStorage.getItem("RmSiteList");

        if (labList != null && labList.length > 0) {
          const allLabs = labList != null ? JSON.parse(labList) : [];
          this.rmLabList = allLabs.map((x: string) => x.toUpperCase());
        }
        if ("viewSelectedLabs" in sessionStorage != undefined) {
          var labValue = sessionStorage.getItem("viewSelectedLabs");
          if (labValue != undefined) {
            this.selectedLabs = JSON.parse(labValue) as string[];
          }
          else {
            this.selectedLabs = [];
          }
          this.isSearchDisabled = false;
        }


        break;
      case "boardtypes":
        if ("viewSelectedBoardTypes" in sessionStorage != undefined) {
          var boardTypeValues = sessionStorage.getItem("viewSelectedBoardTypes");
          if (boardTypeValues != undefined) {
            this.selectedBoardTypes = JSON.parse(boardTypeValues) as string[];
          }
          else {
            this.selectedBoardTypes = [];
          }
          this.isSearchDisabled = false;
        }
        break;
      case "coowners":
        if ("viewSelectedCoOwners" in sessionStorage != undefined) {
          var coOwnerValues = sessionStorage.getItem("viewSelectedCoOwners");
          if (coOwnerValues != undefined) {
            this.selectedCoOwners = JSON.parse(coOwnerValues) as string[];
          }
          else {
            this.selectedCoOwners = [];
          }
          this.isSearchDisabled = false;
        }
        break;
      case "validationteams":
        if ("viewSelectedValidationTeams" in sessionStorage != undefined) {
          var validationTeamValues = sessionStorage.getItem("viewSelectedValidationTeams");
          if (validationTeamValues != undefined) {
            this.selectedValidationTeams = JSON.parse(validationTeamValues) as string[];
          }
          else {
            this.selectedValidationTeams = [];
          }
          this.isSearchDisabled = false;
        }
        break;
      case "products":
        if ("viewSelectedProducts" in sessionStorage != undefined) {
          var productValues = sessionStorage.getItem("viewSelectedProducts");
          if (productValues != undefined) {
            this.selectedProducts = JSON.parse(productValues) as string[];
          }
          else {
            this.selectedProducts = [];
          }
          this.isSearchDisabled = false;
        }
        break;
      default:
        break;
    }
  }

  public checkIfVariableExistsInSessionStorage(variable: string): boolean {

    return sessionStorage.getItem(variable) != null;
  }

  public getStringValueFromSessionStorage(variable: string): string {

    var value = "";
    if (sessionStorage.getItem(variable) != null) {
      var sessionStorageValue = sessionStorage.getItem(variable);
      if (sessionStorageValue != null) {
        value = JSON.parse(sessionStorageValue);
      }
    }
    return value;
  }

  private clearSubscriptions(): void {

    if (this.filterGetLabsSubscription) {
      this.filterGetLabsSubscription.unsubscribe();
    }
    if (this.filterGetBoardTypesSubscription) {
      this.filterGetBoardTypesSubscription.unsubscribe();
    }
    if (this.filterGetProductsSubscription) {
      this.filterGetProductsSubscription.unsubscribe();
    }
    if (this.filterGetOwnerSubscription) {
      this.filterGetOwnerSubscription.unsubscribe();
    }
    if (this.filterGetCoOwnersSubscription) {
      this.filterGetCoOwnersSubscription.unsubscribe();
    }
    if (this.filterGetValidationTeamsSubscription) {
      this.filterGetValidationTeamsSubscription.unsubscribe();
    }
    if (this.filterGetSiliconProgramsSubscription) {
      this.filterGetSiliconProgramsSubscription.unsubscribe();
    }
    if (this.filterGetRecipeRevHistorySubscription) {
      this.filterGetRecipeRevHistorySubscription.unsubscribe();
    }
    if (this.filterGetBBDetailsSubscription) {
      this.filterGetBBDetailsSubscription.unsubscribe();
    }
    if (this.filterGetRecipesSubscription) {
      this.filterGetRecipesSubscription.unsubscribe();
    }
    if (this.filterGetBBsSubscription) {
      this.filterGetBBsSubscription.unsubscribe();
    }
    if (this.filterGetRecipesWithBBSubscription) {
      this.filterGetRecipesWithBBSubscription.unsubscribe();
    }
    if (this.filterGetHsdEsArticlesSubscription) {
      this.filterGetHsdEsArticlesSubscription.unsubscribe();
    }
    if (this.filterUpdateHsdEsArticlesSubscription) {
      this.filterUpdateHsdEsArticlesSubscription.unsubscribe();
    }
    if (this.filterCreateWorkQSubscription) {
      this.filterCreateWorkQSubscription.unsubscribe();
    }
    if (this.filterGetUserIdsidSubscription) {
      this.filterGetUserIdsidSubscription.unsubscribe();
    }
  }

  public setSearchFilters(mode: string): void {

    this.isOwnerLoading = true;
    this.isCoownerLoading = true;
    this.areLabsLoading = true;
    this.areBoardTypesLoading = true;
    this.areProductsLoading = true;
    this.areValidationTeamsLoading = true;
    this.areSiliconProgramsLoading = true;

    this.clearSubscriptions();

    let params: any = {};

    switch (mode.toLowerCase()) {
      case "bb-template":
      case "recipe-template":
        params = {
          isDraft: undefined,
          isTemplate: true,
          isArchived: false,
        }
        break;
      default:
        params = {
          isDraft: undefined,
          isTemplate: undefined,
          isArchived: undefined,
        }
        break;
    }
    this.getFilters(mode, params);
    this.revertWordSearchToDefault();
  }

  private getFilters(mode: string, parameters?: any): void {

    var isDraft: boolean | undefined = undefined;
    var isTemplate: boolean | undefined = undefined;
    var isArchived: boolean | undefined = false;

    if (parameters != undefined) {
      isDraft = parameters["isDraft"];
      isTemplate = parameters["isTemplate"];
      isArchived = parameters["isArchived"];
    }

    var defaultInput = "All";

    this.selectedWordSearch = this.checkIfVariableExistsInSessionStorage("viewSelectedWordSearch")
      && (this.wordSearchFilterTypes.find((item) => item.value === this.getStringValueFromSessionStorage("viewSelectedWordSearch"))) ? this.getStringValueFromSessionStorage("viewSelectedWordSearch") : "Search By";


    switch (mode.toLowerCase()) {
      case "bb":
      case "bb-template":
        this.wordSearchFilterTypes = [
          { label: 'Search By', value: '' },
          { label: 'Building Block Name', value: 'buildingBlockName' },
          { label: 'Building Block Number', value: 'buildingBlockNumber' },
          { label: 'Board Type', value: 'boardType' },
          { label: 'Owner', value: 'ownerName' },
          { label: 'Co-owner', value: 'coOwnerName' },
          { label: 'Module', value: 'module' },
          { label: 'Product', value: 'product' },
          { label: 'Ramp Up', value: 'executionType' },
          { label: 'Revision', value: 'revision' },
          { label: 'Silicon Program', value: 'siliconProgram' },
          { label: 'Type', value: 'type' },
          { label: 'Validation Team', value: 'validationTeam' }];

        /* Get Labs */

        this.filterGetLabsSubscription = this.searchGateway.getLabsFromBuildingBlocks(parameters).subscribe(labsList => {
          this.rmLabList = labsList;
          this.rmLabList.sort();
          localStorage.setItem("RmSiteList", JSON.stringify(this.rmLabList));
          this.preloadValues("labs");
          this.areLabsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areLabsLoading = false;
        })));

        /* Get Board Types */

        this.filterGetBoardTypesSubscription = this.searchGateway.getBoardTypesFromBuildingBlocks(parameters).subscribe(boardTypesList => {
          this.rmBoardTypeList = boardTypesList.map((x: string) => x.toUpperCase());
          this.rmBoardTypeList.sort();
          this.preloadValues("boardtypes");
          this.areBoardTypesLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areBoardTypesLoading = false;
        })));

        /* Get Execution Types */

        this.rmExecutionTypeList.sort();
        this.selectedExecutionType = this.checkIfVariableExistsInSessionStorage("viewSelectedExecutionType") && this.rmExecutionTypeList.includes(this.getStringValueFromSessionStorage("viewSelectedExecutionType")) ? this.getStringValueFromSessionStorage("viewSelectedExecutionType") : "All";

        /* Get Products */

        this.filterGetProductsSubscription = this.searchGateway.getProductsFromBuildingBlocks(parameters).subscribe(productsList => {
          this.rmProductsList = productsList;
          this.rmProductsList.sort();
          this.preloadValues("products");
          this.areProductsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areProductsLoading = false;
        })));

        /* Get Owners */

        this.filterGetOwnerSubscription = this.searchGateway.getOwnersFromBuildingBlocks(parameters).subscribe(ownersList => {
          this.rmOwnersList = ownersList;
          this.rmOwnersList.push(defaultInput);
          this.rmOwnersList.sort();
          this.setOwnerPlaceHolder();
          this.isOwnerLoading = false;
        }, ((error => {
          console.log(error.error);
          this.isOwnerLoading = false;
        })));

        /* Get Co-owners */

        this.filterGetCoOwnersSubscription = this.searchGateway.getCoOwnersFromBuildingBlocks(parameters).subscribe(coOwnersList => {
          this.rmCoOwnersList = coOwnersList;
          this.rmCoOwnersList.sort();
          this.preloadValues("coowners");
          this.isCoownerLoading = false;
        }, ((error => {
          console.log(error.error);
          this.isCoownerLoading = false;
        })));

        /* Get Validation Teams */

        this.filterGetValidationTeamsSubscription = this.searchGateway.getValidationTeamsFromBuildingBlocks(parameters).subscribe(validationTeamsList => {
          this.rmValidationTeamsList = validationTeamsList.map((x: string) => x.toUpperCase());
          this.rmValidationTeamsList.sort();
          this.preloadValues("validationteams");
          this.areValidationTeamsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areValidationTeamsLoading = false;
        })));

        /* Get Silicon Program */

        this.filterGetSiliconProgramsSubscription = this.searchGateway.getSiliconProgramsFromBuildingBlocks(parameters).subscribe(siliconProgramList => {
          this.rmSiliconProgramsList = siliconProgramList;
          this.rmSiliconProgramsList.sort();
          this.selectedSiliconProgram = this.checkIfVariableExistsInSessionStorage("viewSelectedSiliconProgram") && this.rmSiliconProgramsList.includes(this.getStringValueFromSessionStorage("viewSelectedSiliconProgram")) ? this.getStringValueFromSessionStorage("viewSelectedSiliconProgram") : "All";
          this.areSiliconProgramsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areSiliconProgramsLoading = false;
        })));
        break;
      case "recipe":
      case "recipe-template":
      default:
        this.wordSearchFilterTypes = [
          { label: 'Search By', value: '' },
          { label: 'Recipe Name', value: 'recipeName' },
          { label: 'Recipe Number', value: 'recipeNumber' },
          { label: 'Board Type', value: 'boardType' },
          { label: 'Owner', value: 'ownerName' },
          { label: 'Co-owner', value: 'coOwnerName' },
          { label: 'Product', value: 'product' },
          { label: 'Ramp Up', value: 'executionType' },
          { label: 'Revision', value: 'revision' },
          { label: 'Silicon Program', value: 'siliconProgram' },
          { label: 'Validation Team', value: 'validationTeam' }];

        // Assigning DropDownValues for HSDES ID on 26/7/2023 by Lay Ken
        this.hsdesAssignWordSearchFilterTypes = [
          //{ label: 'Search By', value: '' },
          { label: 'HSDES ID', value: 'hsdesid' },
          { label: 'HSDES Query ID', value: 'hsdesqueryid' }];
        
        

        /* Get Labs */

        this.filterGetLabsSubscription = this.searchGateway.getLabsFromRecipes(parameters).subscribe(labsList => {
          this.rmLabList = labsList;
          this.rmLabList.sort();
          localStorage.setItem("RmSiteList", JSON.stringify(this.rmLabList));
          this.preloadValues("labs");
          this.areLabsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areLabsLoading = false;
        })));

        /* Get Board Types */

        this.filterGetBoardTypesSubscription = this.searchGateway.getBoardTypesFromRecipes(parameters).subscribe(boardTypesList => {
          this.rmBoardTypeList = boardTypesList.map((x: string) => x.toUpperCase());
          this.rmBoardTypeList.sort();
          this.preloadValues("boardtypes");
          this.areBoardTypesLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areBoardTypesLoading = false;
        })));

        /* Get Execution Types */

        this.rmExecutionTypeList.sort();
        this.selectedExecutionType = this.checkIfVariableExistsInSessionStorage("viewSelectedExecutionType") && this.rmExecutionTypeList.includes(this.getStringValueFromSessionStorage("viewSelectedExecutionType")) ? this.getStringValueFromSessionStorage("viewSelectedExecutionType") : "All";

        /* Get Products */

        this.filterGetProductsSubscription = this.searchGateway.getProductsFromRecipes(parameters).subscribe(productsList => {
          this.rmProductsList = productsList;
          this.rmProductsList.sort();
          this.preloadValues("products");
          this.areProductsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areProductsLoading = false;
        })));

        /* Get Owners */

        this.filterGetOwnerSubscription = this.searchGateway.getOwnersFromRecipes(parameters).subscribe(ownersList => {
          this.rmOwnersList = ownersList;
          this.rmOwnersList.push(defaultInput);
          this.rmOwnersList.sort();
          this.setOwnerPlaceHolder();
          this.isOwnerLoading = false;
        }, ((error => {
          console.log(error.error);
          this.isOwnerLoading = false;
        })));

        /* Get Co-Owners */

        this.filterGetCoOwnersSubscription = this.searchGateway.getCoOwnersFromRecipes(parameters).subscribe(coOwnersList => {
          this.rmCoOwnersList = coOwnersList;
          this.rmCoOwnersList.sort();
          this.preloadValues("coowners");
          this.isCoownerLoading = false;
        }, ((error => {
          console.log(error.error);
          this.isCoownerLoading = false;
        })));

        /* Get Validation Teams */

        this.filterGetValidationTeamsSubscription = this.searchGateway.getValidationTeamsFromRecipes(parameters).subscribe(validationTeamsList => {
          this.rmValidationTeamsList = validationTeamsList.map((x: string) => x.toUpperCase());
          this.rmValidationTeamsList.sort();
          this.preloadValues("validationteams");
          this.areValidationTeamsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areValidationTeamsLoading = false;
        })));

        /* Get Silicon Program */

        this.filterGetSiliconProgramsSubscription = this.searchGateway.getSiliconProgramsFromRecipes(parameters).subscribe(siliconProgramList => {
          this.rmSiliconProgramsList = siliconProgramList;
          this.rmSiliconProgramsList.sort();
          this.selectedSiliconProgram = this.checkIfVariableExistsInSessionStorage("viewSelectedSiliconProgram") && this.rmSiliconProgramsList.includes(this.getStringValueFromSessionStorage("viewSelectedSiliconProgram")) ? this.getStringValueFromSessionStorage("viewSelectedSiliconProgram") : "All";
          this.areSiliconProgramsLoading = false;
        }, ((error => {
          console.log(error.error);
          this.areSiliconProgramsLoading = false;
        })));
        break;
    }
  }

  public setOwnerPlaceHolderFromMSAL(): void {

    var account = this.authService.instance.getActiveAccount();
    if (account != undefined) {
      var userName = account.name;
      if (userName != undefined) {
        this.defaultOwner = this.rmOwnersList.includes(userName) ? userName : "All";
        this.selectedOwner = this.defaultOwner;
      }
    }
  }

  public copyText(): void {

    this.clipboard.copy(this.messageDescription);
    this.errorDialogOpened = false;
    this.alertDialogOpen = false;
  }

  public dialogWindowClose(category: string): void {

    switch (category.toLowerCase()) {
      case "error":
        this.errorDialogOpened = false;
        break;
      case "prompt":
        this.promptDialogOpened = false;
        break;
      default:
        break;
    }
  }

  public errorDialogAction(status: string): void {

    if (status.toLowerCase() == 'exit') {
      this.errorDialogOpened = false;
      this.alertDialogOpen = false;
    }
    else {
      this.alertDialogOpen = false;
      this.errorDialogOpened = false;
    }
  }

  public setOwnerPlaceHolder(): void {

    if ("viewSelectedOwner" in sessionStorage) {
      this.selectedOwner = this.checkIfVariableExistsInSessionStorage("viewSelectedOwner") && this.rmOwnersList.includes(this.getStringValueFromSessionStorage("viewSelectedOwner")) ? this.getStringValueFromSessionStorage("viewSelectedOwner") : "All";
    }

    if (this.rmOwnersList.length > 0 && (this.selectedOwner.toLowerCase() === "all" || this.selectedOwner === "")) {
      if ("appSettings.appUserFullname" in sessionStorage) {

        var appUserFullName = sessionStorage.getItem("appSettings.appUserFullname");

        if (appUserFullName != undefined) {
          appSettings.appUserFullname = appUserFullName;
          this.defaultOwner = this.rmOwnersList.includes(appUserFullName) ? appUserFullName : "All";
          this.selectedOwner = this.defaultOwner;
        }
        else {
          this.setOwnerPlaceHolderFromMSAL();
        }
      }
      else {
        this.setOwnerPlaceHolderFromMSAL();
      }
    }
    else {
      this.setOwnerPlaceHolderFromMSAL();
    }
  }


  public searchGridFilterChange(filter: CompositeFilterDescriptor, externalSource: string = ""): void {

    this.gridSettings.state.filter = <CompositeFilterDescriptor>filter;

    if (externalSource == '') {
      this.reloadGridData();
    }
    else {
      switch (externalSource.toLowerCase()) {
        case "hsdes":
          this.isHsdEsGridLoading = true;
          var data = filterBy(this.hsdEsGridData, filter);
          this.hsdEsArticleGridData = [];
          this.hsdEsArticleGridData = data;
          this.articleGridData = this.hsdEsArticleGridData;
          this.isHsdEsGridLoading = false;
          break;
        case "buildingblockdetails":
          this.isSupportGridDataLoading = true;
          var gridDetails = filterBy(this.buildingBlockDetailsGrid, filter);
          this.supportGridData = [];
          this.supportGridData = gridDetails;
          this.isSupportGridDataLoading = false;
          break;
        case "reciperevisionhistory":
          this.isRecipeRevHistoryGridLoading = true;
          var gridDetails = filterBy(this.recipeHistoryGridData, filter);
          this.supportGridData = [];
          this.supportGridData = gridDetails;
          this.isRecipeRevHistoryGridLoading = false;
          break;
        case "recipewithbbs":
          this.recipeWithBBGridLoading = true;
          this.recipeWithBuildingBlocksGridData = filterBy(this.recipeWithBBGridData, filter);
          this.recipeWithBBGridLoading = false;
          break;
        case "bbhistory":
          this.buildingBlockRevHistoryGridLoading = true;
          this.bbHistoryGridData = filterBy(this.buildingBlockHistoryGridData, filter);
          this.buildingBlockRevHistoryGridLoading = false;
          break;
        default:
          break;
      }
    }
  }

  // Below method checks if string contains valid email address. Currently not being used, but left here as potential placeholder for future functionality.
  private isValidEmailAddress(email: string): boolean {

    var matching = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return matching == null ? false : matching?.length > 0;
  };

  //Below method converts grid row value to upper case to most columns (few exceptions are listed below).
  public gridRowToUpper(obj: any) {

    for (var prop in obj) {
      if (typeof obj[prop] === 'string') {
        if (prop.toString().toLowerCase() != "id" &&
          prop.toString().toLowerCase() != "comments" &&
          prop.toString().toLowerCase() != "content" &&
          prop.toString().toLowerCase() != "buildingblockid" &&
          prop.toString().toLowerCase() != "recipeid" &&
          prop.toString().toLowerCase() != "emaildistribution" &&
          prop.toString().toLowerCase() != "email") {

          obj[prop] = obj[prop].toUpperCase();
        }
      }

      //Commented the below line to escape CheckList items from being converted to UpperCase as customer wants the original format
      //Ticket Ref: https://hsdes.intel.com/appstore/article/#/22020022831
      //Date: 06 May'24
      //Author: Rajesh

      //if (typeof obj[prop] === 'object') { 

      if (typeof obj[prop] === 'object' && prop.toString().toLowerCase() != "checklist") {        
          this.gridRowToUpper(obj[prop]);
      }
    }
    return obj;
  }

  public clearAllFilters(): void {
    this.gridSettings = {
      state: {
        skip: 0,
        take: 10,

        filter: {
          logic: 'and',
          filters: [],
        },
      }
    };
  }

  public distinctPrimitive(fieldName: string, gridName: string = ""): any {

    var returnedData: any = undefined;
    switch (gridName.toLowerCase()) {
      case "hsdes":
        returnedData = distinct(this.hsdEsArticleGridData, fieldName).map(item => item[fieldName]).sort();
        break;
      case 'bbdetails':
        returnedData = distinct(this.buildingBlockDetailsGrid, fieldName).map(item => item[fieldName]).sort();
        break;
      default:
        returnedData = distinct(this.mainGridData, fieldName).map(item => item[fieldName]).sort();
        break;
    }
    return returnedData;
  }

  private dealWithPeoplesNames(name: string) {

    var nameArray = [];
    var reformattedName = "";

    if (name.indexOf(",") < 0) {
      nameArray = name.split(/\b(\s)/).filter(n => n.length > 1);

      switch (nameArray.length) {
        case 0:
        case 1:
          reformattedName = nameArray[0].trim().toLowerCase();
          break;
        case 2:
          reformattedName = nameArray[1].trim().toLowerCase() + ", " + nameArray[0].trim().toLowerCase();
          break;
        case 3:
          reformattedName = nameArray[2].trim().toLowerCase() + ", " + nameArray[0].trim().toLowerCase() + " " + nameArray[1].trim().toLowerCase();
          break;
        case 4:
          reformattedName = nameArray[2].trim().toLowerCase() + " " + nameArray[3].trim().toLowerCase() + ", " + nameArray[0].trim().toLowerCase() + " " + nameArray[1].trim().toLowerCase();
          break;
        default:
          reformattedName = nameArray.join(" ");
          break;
      }
    }
    else {
      reformattedName = name.toLowerCase();
    }

    return reformattedName;
  }

  private searchItem(receivingParams: any): void {

    let params: any = {};
    this.clearAllFilters();
    this.clearAll_BBGrid_Filters();
    if (this.selectedWordSearch.toLowerCase() === "search by") {
      params = {
        lab: this.selectedLabs.length <= 0 ? "All" : this.selectedLabs.join("|").toLowerCase(),
        boardType: this.selectedBoardTypes.length <= 0 ? "All" : this.selectedBoardTypes.join("|").toLowerCase(),
        executionType: this.selectedExecutionType,
        product: this.selectedProducts.length <= 0 ? "All" : this.selectedProducts.join("|").toLowerCase(),
        ownerName: this.selectedOwner,
        coOwnerName: this.selectedCoOwners.length <= 0 ? "All" : this.selectedCoOwners.join("|").toLowerCase(),
        validationTeam: this.selectedValidationTeams.length <= 0 ? "All" : this.selectedValidationTeams.join("|").toLowerCase(),
        siliconProgram: this.selectedSiliconProgram,
        fromCreatedDate: this.formatDate(this.fromDate),
        toCreatedDate: this.formatDate(this.toDate),
      };
    }
    else {
      switch (this.selectedWordSearch.toLowerCase()) {
        case "ownername":
        case "coownername":
          params[this.selectedWordSearch] = this.dealWithPeoplesNames(receivingParams['searchValue']);
          break;
        default:
          params[this.selectedWordSearch] = receivingParams['searchValue'];
          break;
      }
    }
    params['isArchived'] = receivingParams['isArchived'];
    params['isTemplate'] = receivingParams['isTemplate'];
    params['isDraft'] = receivingParams['isDraft'];

    let issuingParams: any = {
      mode: receivingParams['mode'],
      clicked: receivingParams['clicked']
    }

    if (this.filterGetRecipesSubscription) {
      this.filterGetRecipesSubscription.unsubscribe();
    }
    if (this.filterGetBBsSubscription) {
      this.filterGetBBsSubscription.unsubscribe();
    }

    let counter: number = 0;

    switch (receivingParams['mode'].toLowerCase()) {
      case 'bb':
      case 'bb-template':
        this.filterGetBBsSubscription = this.searchGateway.getBuildingBlocks(params, true).subscribe((data) => {
          var newData: any = data;
          counter = 0;
          if (newData.length > 0) {
            this.selectedRowKeys = [];
            this.selectedRowKeys.push(counter);
          }
          newData.forEach((d: any) => {
            d.isSelected = false;
          });
          issuingParams["data"] = newData;
          this.loadDataOntoMainGrid(issuingParams);
        }, ((error => {
          this.displayModalWindowWithError("An error was encountered while attempting to pull " + receivingParams['mode'].toUpperCase() + " information: " + error.error + ".");
          this.clearAll(true);
          this.requestGridLoading = false;
        })));
        this.selectedChipIndex = 0;
        break;
      case 'recipe':
      case 'recipe-template':
      default:
        this.filterGetRecipesSubscription = this.searchGateway.getRecipes(params, true).subscribe((data) => {
          issuingParams["data"] = data;
          counter = 0;
          if (data.length > 0) {
            this.selectedRowKeys = [];
            this.selectedRowKeys.push(counter);
          }

          this.loadDataOntoMainGrid(issuingParams);
        }, ((error => {
          this.displayModalWindowWithError("An error was encountered while attempting to pull " + receivingParams['mode'].toUpperCase() + " information: " + error.error + ".");
          this.clearAll(true);
          this.requestGridLoading = false;
        })));
        break;
    }
    this.requestGridLoading = false;
  }

  public onScroll(event: any) {
    this.onCellClick(event);
  }

  public search(btnClicked: boolean = false): void {

    let searchValue: string = "";
    let searchType: string = "";

    /* Resets the context menu to disable creation of recipe option */
    //Hide the menu for now
    //this.handleContextMenu("CreateRecipe", false);

    searchValue = document.getElementById('searchBox') != null ? (<HTMLInputElement>document.getElementById('searchBox')).value : "";

    if (searchValue === "") {
      this.revertWordSearchToDefault();
    }

    if ("viewSelectedLabs" in sessionStorage == true) {
      var labValue = sessionStorage.getItem("viewSelectedLabs");
      if (labValue != undefined) {
        this.selectedLabs = JSON.parse(labValue) as string[];
      }
      else {
        if (this.selectedLabs == undefined) {
          this.displayModalWindowWithError("You must select a lab before searching!");
          return;
        }
      }
    }

    this.requestGridLoading = true;
    searchType = this.wordSearchFilterSelected.toLowerCase().replace(' ', '');
    var mode = sessionStorage.getItem("viewMode");

    if (mode != null) {
      let params: any = {};

      params = {
        mode: mode,
        clicked: btnClicked,
        searchValue: searchValue.trim(),
        isDraft: undefined,
        isTemplate: !mode.includes('template') ? false : true,
        isArchived: false
      }
      this.searchItem(params);
    }
    else {
      this.requestGridLoading = false;
    }
  }

  private handleComplexColumns(data: any[], mode: string): any[] {

    var formattedData: any[] = [];

    switch (mode.toLowerCase()) {
      case 'recipe':
      case 'recipe-template':
        data.forEach((recipe: any) => {
          recipe.boardTypes = this.getJoinedValues(recipe.boardTypes);
          recipe.owner = recipe.ownerName;
          recipe.coOwnerEmail = this.getJoinedValues(recipe.coOwner, "coownerEmail");
          recipe.coOwner = this.getJoinedValues(recipe.coOwner, "coowner");
          recipe.labs = this.getJoinedValues(recipe.labs);
          recipe.validationTeams = this.getJoinedValues(recipe.validationTeams);
          recipe.products = this.getJoinedValues(recipe.products);
          recipe.emailDistribution = this.getJoinedValues(recipe.emailDistribution, "email");
          recipe.parentRecipeRevision = recipe.parentRecipe != null ? recipe.parentRecipe.revision : "N/A";
          recipe.updatedBy = new Person();
          recipe.updatedBy.name = appSettings.appUserFullname;
          recipe.updatedBy.email = appSettings.appUserEmailId;
          recipe.updatedBy.wwid = appSettings.appUserWwid;
          recipe.createdBy = new Person();
          recipe.createdBy.name = recipe.createdByName;
          recipe.createdBy.wwid = recipe.createdByWwid;
          recipe.createdBy.email = recipe.createdByEmail;
          formattedData.push(recipe);
        });
        break;
      case 'bb':
      case 'bb-template':
        data.forEach((bb: any) => {
          this.buildingBlockDetailsGrid = [];
          bb.boardTypes = this.getJoinedValues(bb.boardTypes);
          bb.owner = bb.ownerName;
          bb.coOwnerEmail = this.getJoinedValues(bb.coOwner, "coownerEmail");
          bb.coOwner = this.getJoinedValues(bb.coOwner, "coowner");
          bb.labs = this.getJoinedValues(bb.labs);
          bb.validationTeams = this.getJoinedValues(bb.validationTeams);
          //bb.checkList = this.getJoinedValues(bb.checkList);
          this.checkListData = bb.checkList; //checkList item displayed in table format updated on 14 July 2023 by Khoo Lay Ken
          bb.products = this.getJoinedValues(bb.products);
          bb.emailDistribution = this.getJoinedValues(bb.emailDistribution, "email");
          bb.parentBBRevision = bb.parentBuildingBlock != null ? bb.parentBuildingBlock.revision : "N/A";
          bb.updatedBy = new Person();
          bb.updatedBy.name = appSettings.appUserFullname;
          bb.updatedBy.email = appSettings.appUserEmailId;
          bb.updatedBy.wwid = appSettings.appUserWwid;
          bb.createdBy = new Person();
          bb.createdBy.name = bb.createdByName;
          bb.createdBy.wwid = bb.createdByWwid;
          bb.createdBy.email = bb.createdByEmail;
          formattedData.push(bb);
        });
        break;
      default:
        break;
    }
    return formattedData;
  }

  public toggleFilter() {

    this.isFilterVisible = !this.isFilterVisible;
  }

  public toggle(dataItem: any, field: string, category: string) {

    switch (category.toLowerCase()) {
      case "selectedbuildingblocks":
        dataItem[field] = !dataItem[field];
        this.IsCheckBokClicked = true;
        this.gridBBSelectionChange(dataItem);
        break;
      case "selectedbuildingblocksforhsd":
        dataItem[field] = !dataItem[field];
        var selection = dataItem;
        selection.isSelected = !selection.isSelected;

        const index1 = this.selectedBuildingBlockList.indexOf(selection, 0);
        if (index1 > -1) {
          this.selectedBuildingBlockList.splice(index1, 1);
        } else {
          this.selectedBuildingBlockList.push(selection);
        }
        break;
      case "selectedhsdesarticles":
        dataItem[field] = !dataItem[field];
        var singleHsdEsTicket = dataItem;

        if (dataItem[field] == false) {
          singleHsdEsTicket.isSelected = false;
        }
        else {
          singleHsdEsTicket.isSelected = true;
        }

        const index2 = this.selectedHsdEsTickets.indexOf(singleHsdEsTicket, 0);

        if (index2 > -1) {
          this.selectedHsdEsTickets.splice(index2, 1);
        } else {
          this.selectedHsdEsTickets.push(singleHsdEsTicket);
        }
        break;
      default:
        break;

    }
  }

  private loadDataOntoMainGrid(parameters: any): void {

    const mode: string = parameters["mode"];
    const data: any[] = parameters["data"];
    const btnClicked: boolean = parameters["clicked"];

    var modeInReadForm = "";

    switch (mode.toLowerCase()) {
      case "bb":
        modeInReadForm = "Building Block";
        break;
      case "bb-template":
        modeInReadForm = "Building Block Template";
        break;
      case "recipe":
        modeInReadForm = "Recipe";
        break;
      case "recipe-template":
        modeInReadForm = "Recipe Template";
        break;
      default:
        break;
    }

    if (data != undefined && data.length > 0) {
      this.isEmpty = false;
      this.searchGridLoading = false;
    }
    else {
      this.isEmpty = true;
      if (btnClicked) {
        this.displayModalWindowWithError("No matching '" + modeInReadForm.toUpperCase() + "' was found! Please check your filtering criteria and try again.");
        this.searchGridLoading = false;
        this.requestGridLoading = false;
        return;
      }
    }

    switch (mode.toLowerCase()) {
      case 'recipe':
      case 'recipe-template':
        this.mainGridData = [];
        this.recipeGridData = [];
        this.gridData = [];
        this.recipeGridData = this.handleComplexColumns(data, mode);
        this.mainGridData = this.gridRowToUpper(this.recipeGridData);
        //Rajesh updated this code to fix paging issue in view component on 12 July'23
        this.gridData = process(this.mainGridData, this.gridSettings.state);
        this.selectedItemFromGrid = this.recipeGridData[0];
        break;
      case 'bb':
      case 'bb-template':
        this.buildingBlockGridData = [];
        this.mainGridData = [];
        this.gridData = [];
        this.buildingBlockGridData = this.handleComplexColumns(data, mode);
        this.mainGridData = this.gridRowToUpper(this.buildingBlockGridData);
        //Rajesh updated this code to fix paging issue in view component on 12 July'23
        this.gridData = process(this.mainGridData, this.gridSettings_BBGrid.state);
        this.selectedBuildingBlockList = [];
        this.selectedItemFromGrid = this.buildingBlockGridData[0];
        break;
      default: //Adding Default to break on 14 July 2023 by Khoo Lay Ken
        break;
    }
    this.onTabSelect();
    this.searchGridLoading = false;
    this.requestGridLoading = false;
    this.exportData = this.mainGridData;
    this.recipeData = this.mainGridData;
  }

  private padTo2Digits(num: Number): string {

    return num.toString().padStart(2, '0');
  }

  private formatDate(date: Date): string {

    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  // private formatDateWithMilliseconds(date: Date): string {

  //   return (
  //     date.getFullYear().toString() +
  //     '-' +
  //     this.padTo2Digits(date.getMonth() + 1) +
  //     '-' +
  //     this.padTo2Digits(date.getDate()) +
  //     ' ' +
  //     this.padTo2Digits(date.getHours()) +
  //     ':' +
  //     this.padTo2Digits(date.getMinutes()) +
  //     ':' +
  //     this.padTo2Digits(date.getSeconds()) +
  //     '.' +
  //     date.getMilliseconds().toString().padStart(3, '0')
  //   );
  // }

    // Function to get current date and time formatted
    // getCurrentDateTimeFormatted(): string {
    //   return this.formatDateWithMilliseconds(new Date());
    // }
  
    // // Function to get the date and time exactly one week ago formatted
    // getPastDateTimeFormatted(): string {
    //   const pastDate = new Date();
    //   pastDate.setDate(pastDate.getDate() - 7);
    //   return this.formatDateWithMilliseconds(pastDate);
    // }

  public getSelectedRecord(): DataItem {

    var item = undefined;

    switch (this.createMode.toLowerCase()) {
      case "building block":
      case "building block template":
        item = this.getSelectedItemFromSessionStorage("bb") as DataItem;

        if (item == undefined) {
          item = this.buildingBlockGridData[0]; // If no selection from user, the first element in the grid is the default selection.
        }
        break;
      case "recipe":
      case "recipe template":
      default:
        item = this.getSelectedItemFromSessionStorage("recipe") as DataItem;

        if (item == undefined) {
          item = this.recipeGridData[0]; // If no selection from user, the first element in the grid is the default selection.
        }
        break;
    }
    const selectedDataItem: DataItem = item;
    return selectedDataItem;
  }

  private actionsOnRecipe() {

    var selectedRecipeData = this.getSelectedRecord();
    var selectedRecipeNumber = selectedRecipeData['recipeNumber' as keyof DataItem] != undefined ? selectedRecipeData['recipeNumber' as keyof DataItem].toString() : "";
      
    switch (this.actionOnItem) {
      case "copy-recipe":
        this.displayModalWindowWithWarning("Are you sure you want to make a copy of '" + selectedRecipeNumber + "' recipe?");
        break;
      case "edit-recipe":
        if (this.editOrViewButtonLabel.toLowerCase() == "edit recipe") {
          this.displayModalWindowWithWarning("Are you sure you want to " + this.editOrViewButtonLabel + " '" + selectedRecipeNumber + "'?");
        }
        else {
          var recipeId = selectedRecipeData['id' as keyof DataItem] != undefined ? selectedRecipeData['id' as keyof DataItem].toString() : "";
          var createdByWwid = selectedRecipeData['createdByWwid' as keyof DataItem] != undefined ? selectedRecipeData['createdByWwid' as keyof DataItem].toString() : "";

          var url = `#/recipe/edit/${recipeId}?createdByWwid=${createdByWwid}`;
          this.actionOnItem = "";
          window.open(url);
        }
        break;
      case "delete-recipe":
        this.displayModalWindowWithWarning("Are you sure you want to delete '" + selectedRecipeNumber + "' recipe?");
        break;
      default:
        break;
    }
  }

  private actionsOnBuildingBlock() {

    var selectedBBData = this.getSelectedRecord();
    var selectedBuildingBlockNumber = selectedBBData['buildingBlockNumber' as keyof DataItem] != undefined ? selectedBBData['buildingBlockNumber' as keyof DataItem].toString() : "";

    switch (this.actionOnItem) {
      case "copy-bb":
        this.displayModalWindowWithWarning("Are you sure you want to make a copy of '" + selectedBuildingBlockNumber + "' building block?");
        break;
      case "edit-bb":
        if (this.editOrViewButtonLabel.toLowerCase() == "edit building block") {
          this.displayModalWindowWithWarning("Are you sure you want to " + this.editOrViewButtonLabel + " '" + selectedBuildingBlockNumber + "'?");
        }
        else {
          var bbId = selectedBBData['id' as keyof DataItem] != undefined ? selectedBBData['id' as keyof DataItem].toString() : "";
          var createdByWwid = selectedBBData['createdByWwid' as keyof DataItem] != undefined ? selectedBBData['createdByWwid' as keyof DataItem].toString() : "";

          var url = `#/bb/edit/${bbId}?createdByWwid=${createdByWwid}`;
          this.actionOnItem = "";
          window.open(url);
        }
        break;
      case "delete-bb":
        this.displayModalWindowWithWarning("Are you sure you want to delete '" + selectedBuildingBlockNumber + "' building block?");
        break;
      default:
        break;
    }
  }

  public btnActionsClick(actionOnItem: string) {

    this.actionOnItem = actionOnItem.toLowerCase();

    switch (this.createMode.toLowerCase()) {
      case "recipe":
      case "recipe template":
        this.actionsOnRecipe();
        break;
      case "building block":
      case "building block template":
      default:
        this.actionsOnBuildingBlock();
        break;
    }
  }

  private getSelectedItemFromSessionStorage(itemType: string): any {

    var item: any;

    switch (itemType.toLowerCase()) {
      case "bb":
        if (this.selectedItemFromGrid == undefined) {
          if ("viewBBGridSelectedItem" in sessionStorage) {
            var sessionStorageItem = sessionStorage.getItem("viewBBGridSelectedItem");
            if (sessionStorageItem != undefined) {
              item = this.selectedItemFromGrid == undefined ? JSON.parse(sessionStorageItem) as unknown as DataItem : this.selectedItemFromGrid;
            }
          }
        }
        else {
          item = this.selectedItemFromGrid;
        }
        break;
      case "recipe":
        if (this.selectedItemFromGrid == undefined) {
          if ("viewRecipeGridSelectedItem" in sessionStorage) {
            var sessionStorageItem = sessionStorage.getItem("viewRecipeGridSelectedItem");
            if (sessionStorageItem != undefined) {
              item = this.selectedItemFromGrid == undefined ? JSON.parse(sessionStorageItem) as unknown as DataItem : this.selectedItemFromGrid;
            }
          }
        }
        else {
          item = this.selectedItemFromGrid;
        }
        break;
      default:
        this.displayModalWindowWithError("Unable to handle this type of item at this time. Please try building block or recipe only.");
        break;
    }
    return item;
  }

  public promptDialogAction(status: string): void {

    if (status.toLowerCase() == 'cancel') {
      this.promptDialogOpened = false;
      this.alertDialogOpen = false;
    }
    else {
      var item = this.selectedItemFromGrid;
      var url = "";

      switch (this.actionOnItem.toLowerCase()) {

        case "copy-recipe":

          const selectedCopyRecipeData = this.getSelectedRecord();
          var recipeId = selectedCopyRecipeData['id' as keyof DataItem] != undefined ? selectedCopyRecipeData['id' as keyof DataItem].toString() : "";
          var createdByWwid = selectedCopyRecipeData['createdByWwid' as keyof DataItem] != undefined ? selectedCopyRecipeData['createdByWwid' as keyof DataItem].toString() : "";

          url = `#/recipe/copy/${recipeId}?createdByWwid=${createdByWwid}`;
          this.actionOnItem = "";
          window.open(url);
          break;

        case "copy-bb":

          const selectedCopyBuildingBlockData = this.getSelectedRecord();
          var bbId = selectedCopyBuildingBlockData['id' as keyof DataItem] != undefined ? selectedCopyBuildingBlockData['id' as keyof DataItem].toString() : "";
          var createdByWwid = selectedCopyBuildingBlockData['createdByWwid' as keyof DataItem] != undefined ? selectedCopyBuildingBlockData['createdByWwid' as keyof DataItem].toString() : "";

          url = `#/bb/copy/${bbId}?createdByWwid=${createdByWwid}`;
          this.actionOnItem = "";
          window.open(url);
          break;

        case "edit-recipe":

          const selectedEditRecipeData = this.getSelectedRecord();
          var recipeId = selectedEditRecipeData['id' as keyof DataItem] != undefined ? selectedEditRecipeData['id' as keyof DataItem].toString() : "";
          var createdByWwid = selectedEditRecipeData['createdByWwid' as keyof DataItem] != undefined ? selectedEditRecipeData['createdByWwid' as keyof DataItem].toString() : "";

          url = `#/recipe/edit/${recipeId}?createdByWwid=${createdByWwid}`;
          this.actionOnItem = "";
          window.open(url);
          break;

        case "edit-bb":

          const selectedEditBuildingBlockData = this.getSelectedRecord();
          var bbId = selectedEditBuildingBlockData['id' as keyof DataItem] != undefined ? selectedEditBuildingBlockData['id' as keyof DataItem].toString() : "";
          var createdByWwid = selectedEditBuildingBlockData['createdByWwid' as keyof DataItem] != undefined ? selectedEditBuildingBlockData['createdByWwid' as keyof DataItem].toString() : "";

          url = `#/bb/edit/${bbId}?createdByWwid=${createdByWwid}`;
          this.actionOnItem = "";
          window.open(url);

          break;

        case "delete-recipe":

          item = this.getSelectedItemFromSessionStorage("recipe") as Recipe;

          if (item == undefined) {
            item = this.recipeGridData[0] as Recipe; // If no selection from user, the first element in the grid is the default selection.
          }

          if (this.checkItemOwnership(item)) {
            var filterDeleteRecipeSubscription = new Subscription();
            filterDeleteRecipeSubscription = this.createGateway.deleteRecipe(item).subscribe((result) => {
              this.displayModalWindowWithWarning("'" + item.recipeNumber.toUpperCase() + "' was deleted.", "Recipe Management Information");
              this.actionOnItem = "";
              this.search();
            }, ((error => {
              this.displayModalWindowWithError("An error was encountered while attempting to delete '" + item.recipeNumber.toUpperCase() + "'.");
              console.log(error.error);
            })));
          }
          else {
            this.displayModalWindowWithError("You are not an owner or co-owner of recipe '" + item.recipeNumber.toUpperCase() + "'; thus, you cannot delete it yourself. Please check with the recipe owner(s) first.");
          }
          break;

        case "delete-bb":

          item = this.getSelectedItemFromSessionStorage("bb") as BuildingBlock;

          if (item == undefined) {
            item = this.buildingBlockGridData[0] as BuildingBlock; // If no selection from user, the first element in the grid is the default selection.
          }

          if (this.checkItemOwnership(item)) {
            var filterDeleteRecipeSubscription = new Subscription();
            filterDeleteRecipeSubscription = this.createGateway.deleteBuildingBlock(item).subscribe((result) => {
              this.displayModalWindowWithWarning("'" + item.buildingBlockNumber.toUpperCase() + "' was deleted.", "Recipe Management Information");
              this.actionOnItem = "";
              this.search();
            }, ((error => {
              this.displayModalWindowWithError("An error was encountered while attempting to delete '" + item.buildingBlockNumber.toUpperCase() + "'.");
              console.log(error.error);
            })));
          }
          else {
            this.displayModalWindowWithError("You are not an owner or co-owner of building block '" + item.buildingBlockNumber.toUpperCase() + "'; thus, you cannot delete it yourself. Please check with the building block owner(s) first.");
          }
          break;

        default:
          break;
      }

      this.alertDialogOpen = false;
      this.promptDialogOpened = false;
    }
  }

  //Ken add this to retrieve selected drop down value from user (31/07/2023)
  public lstWordSearchValueChangeHsdes(value: any): void {
    this.selectedHSDESSearch = value.value;
    console.log(value.label.toLowerCase());
    (<HTMLInputElement>document.getElementById('searchHsdEsBox')).value = ""; // clear search textbox
    if (value.label.toLowerCase() != "search by") {
      //ly btn disabled
      (<HTMLInputElement>document.getElementById('searchHsdEsBox')).disabled = false;
    }
    else {
      (<HTMLInputElement>document.getElementById('searchHsdEsBox')).value = "";
      if (this.selectedWordSearch.toLowerCase() !== "search by") {
        //ly btn disabled
        (<HTMLInputElement>document.getElementById('searchHsdEsBox')).disabled = false;
      }
      //ly btn disabled
      (<HTMLInputElement>document.getElementById('searchHsdEsBox')).disabled = true;
    }
  }

  public searchHsdEsArticles(): void {

    this.hsdEsGridData = [];
    this.hsdEsArticleGridData = [];
    this.articleGridData = [];

    this.isHsdEsGridLoading = true;
    var hsdId = document.getElementById('searchHsdEsBox') != null ? (<HTMLInputElement>document.getElementById('searchHsdEsBox')).value : "";

    //Ken and Rajesh do this to retrieve HSDES Query ID based on User Selection (31/7/2023)
    //getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE id in`;
    getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE submitted_date AND status NOT IN ('Complete', 'Rejected') AND id in`;
    
    //Check which selection the user selected
    if (this.selectedHSDESSearch == "hsdesid" || this.selectedHSDESSearch == "") {

      console.log('HSDES ID is being searched');
      if (hsdId != "") {

        hsdId = hsdId.trim();
        var getTCsQuery = "";
        var hsdIds = hsdId.split(",");

        if (hsdIds.length > 1) {

          var newHsdIds: any = [];

          hsdIds.forEach((sId: any) => {
            sId = sId.trim();
            sId = "\"" + sId + "\"";
            newHsdIds.push(sId);
          });

          getTCsQuery = getTCsQuery + `(${newHsdIds.join(",")})`;
        }
        else {
          getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE id = '${hsdId}' AND status NOT IN ('Complete', 'Rejected')`;
        }


        this.hsdEsGridData = [];
        this.hsdEsArticleGridData = [];
        this.articleGridData = [];

        this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe((result: any) => {

          var recordCount = result.length as number;

          if (recordCount == 0) {
            this.isHsdEsGridLoading = false;
            return;
          }

          var newData: any = result;
          newData.forEach((d: any) => {
            d.isSelected = false;
          });
          this.hsdEsGridData = newData;
          this.hsdEsArticleGridData = newData;
          this.hsdEsArticleGridData.map(item => {
            item['support_site'] = item['support.site']
          })
          this.articleGridData = this.hsdEsArticleGridData;

          this.isHsdEsGridLoading = false;

          this.openPopupWindow("hsdesarticles");

        }, ((error => {
          console.log(error.toString());
          this.isHsdEsGridLoading = false;
        })));
      }
      else {
        if (this.filterGetUserIdsidSubscription) {
          this.filterGetUserIdsidSubscription.unsubscribe();
        }

        this.filterGetUserIdsidSubscription = this.userApiCache.whoAmI().subscribe(userIdentities => {
          if (userIdentities.length > 0) {

            const currentUserIdsid = userIdentities[0].idsid;

            if (this.filterGetHsdEsArticlesSubscription) {
              this.filterGetHsdEsArticlesSubscription.unsubscribe();
            }
            var getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE owner = '${currentUserIdsid}' AND status NOT IN ('Complete', 'Rejected') AND submitted_date > daysAgo(7)`;
            this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe((result: any) => {

              var recordCount = result.length as number;

              if (recordCount == 0) {
                this.isHsdEsGridLoading = false;
                return;
              }

              var newData: any = result;
              newData.forEach((d: any) => {
                d.isSelected = false;
              });

              this.hsdEsGridData = newData;
              this.hsdEsArticleGridData = newData;
              this.hsdEsArticleGridData.map(item => {
                item['support_site'] = item['support.site']
              })
              this.articleGridData = this.hsdEsArticleGridData;
              this.isHsdEsGridLoading = false;
            }
              , ((error => {
                console.log(error.toString());
                this.isHsdEsGridLoading = false;
              })));
          }
        });
      }
    }
    else {

      //This else is to find the Query ID instead done by Rajesh (31/7/2023)
      let id: any = [];

      this.filterGetHsdEsArticlesSubscription = this.queryApi.getQueryById(parseInt(hsdId)).subscribe((result: any) => {
        var newData: any = result.data;

        newData.forEach((obj: any) => {

          id.push(obj.id);

        })

        var newHsdIds: any = [];

        id.forEach((sId: any) => {
          sId = sId.trim();
          sId = "\"" + sId + "\"";
          newHsdIds.push(sId);
        });
        getTCsQuery = "";
        getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE id in`;
        getTCsQuery = getTCsQuery + `(${newHsdIds.join(",")})`;

        this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe((result: any) => {
          var recordCount = result.length as number;

          if (recordCount == 0) {
            this.isHsdEsGridLoading = false;
            return;
          }

          result = result.filter((a: any)=> (a.reason != 'user_verified' && a.reason != 'released'));

          var newData: any = result;
          newData.forEach((d: any) => {
            d.isSelected = false;
          });

          this.hsdEsGridData = newData;
          this.hsdEsArticleGridData = newData;
          this.hsdEsArticleGridData.map(item => {
            item['support_site'] = item['support.site']
          })
          this.articleGridData = this.hsdEsArticleGridData;
          this.isHsdEsGridLoading = false;
          this.openPopupWindow("hsdesarticles");

        }, ((error => {

          console.log(error.toString());

          this.isHsdEsGridLoading = false;

        })));

        this.isHsdEsGridLoading = false;

      });
    }
  }

  public async btnAssignHsdEsArticles(): Promise<void> {
    if (this.selectedHsdEsTickets == null || this.selectedHsdEsTickets.length <= 0) {
      return;
    }
    // console.log("HELLOO")
    //this.closePopupWindow("hsdesarticles");
    console.log("Assign HSD Category: " + this.workQCategory);
    this.isAssignHsdEsToCCDisabled = true;
    this.showHsdEsWaitIndicator = true;
    const selectedEditRecipeData = this.getSelectedRecord();
    const selectedRecipe = selectedEditRecipeData as unknown as Recipe;
    const simplifiedBBArray: SelectedBuildingBlock[] = [];
    let orderNum = 0;
    const newWorkQCases: string[] = [];

    this.selectedBuildingBlockList.forEach((bb: BuildingBlock) => {
      const simplifiedBB: SelectedBuildingBlock = new SelectedBuildingBlock();
      simplifiedBB.buildingBlockId = bb.id;
      simplifiedBB.moduleName = bb.module;
      simplifiedBB.order = orderNum++;
      if (bb.checkList.length == 0) {
        simplifiedBB.checkList = [{
          id: '',
          name: 'BB Completed',
          type: 'CheckList Item',
          isRequired: true,
          isChecked: false,

        }]
      }
      else {
        simplifiedBB.checkList = bb.checkList;
      }

      simplifiedBBArray.push(simplifiedBB);
    });

    const currentOwner = new Person();
    currentOwner.email = appSettings.appUserEmailId;
    currentOwner.name = appSettings.appUserFullname;
    currentOwner.wwid = appSettings.appUserWwid;

    if (this.selectedHsdEsTickets[0].owner) {
      const user = await lastValueFrom(
        this.userService.getUserDetails({ idsid: this.selectedHsdEsTickets[0].owner, expand: 'personal' })
      );

      if (user.data) {

        console.log(user.data[0]);
        this.owner.wwid = user.data[0]['sys_user.wwid'];
        this.owner.email = user.data[0]['sys_user.email'];
        this.owner.name = user.data[0]['sys_user.name'];
      }
    }

    const workQCase: WorkQ = new WorkQ();
    
    this.HsdEsTicketCount = 0;

    //Check if HSD-ES ID is assigned to CC
    let combinedStringId = '';
    for (const selectedHsdEsId of this.selectedHsdEsTickets)
    {
      combinedStringId += selectedHsdEsId.id + ','; 

      if (combinedStringId.endsWith(', ')) 
        {
          combinedStringId = combinedStringId.slice(0, -1);
          console.log("Combined ID 1: " + combinedStringId);
        }
        
    }

    //Search Gateway to check if HSD-ES ID is already assigned to CC
    this.searchGateway.getWorkQHsdEsId(combinedStringId).subscribe(async result => {
      console.log("Combined ID: " + combinedStringId)
      console.log(result);
      //If there is result from the query, then display error message
      if(result.length > 0)
      {
        const results = result;
        //Error Message here
        this.displayModalWindowWithWarning(
          // "HSD-ES ID is already assigned to CC! Please check the following HSD-ES ID: " +
          // results.map(result => result.hsdId) + ".",
          "The following HSD-ES ID:" +  results.map(result => result.hsdId) + " has already been assigned to CC/WQ: " 
          + results.map(result => result.workqNumber) + ".",
        )
      }
      else
      {
        //Continue with the process
        console.log("Continue with the process");
        this.closePopupWindow("hsdesarticles");
        for (const selectedHsdEsArticle of this.selectedHsdEsTickets) {
          workQCase.labs = [];
          workQCase.hsdId = selectedHsdEsArticle.id;
          const labInfoObj = new LabInfo();
          labInfoObj.name = selectedHsdEsArticle['support.site'];
          // labInfoObj.name = selectedRecipe.labs.toString();
          workQCase.labs.push(labInfoObj);
          workQCase.hsdTitle = selectedHsdEsArticle.title;
          workQCase.hsdDescription = selectedHsdEsArticle.description;
          workQCase.hsdReason = selectedHsdEsArticle.reason;
          workQCase.hsdPriority = selectedHsdEsArticle.priority;
          workQCase.recipeId = selectedRecipe.id;
          workQCase.recipeNumber = selectedRecipe.recipeNumber;
          workQCase.recipeName = selectedRecipe.recipeName;
          workQCase.buildingBlocks = simplifiedBBArray;
          workQCase.mode = selectedHsdEsArticle.mode == undefined ? "not started" : selectedHsdEsArticle.mode;
          workQCase.daysInWip = 0;
          workQCase.createdBy = currentOwner;
          workQCase.updatedBy = currentOwner;
          workQCase.revision = 0;
          workQCase.application = "Recipe Management";
          workQCase.hsdOwner = this.owner;
          workQCase.category = this.workQCategory; //Inserting Category to WorkQ - Ramp Up/ Ramp Down
          console.log(workQCase);
          
          try {
            const result = await (async () => {
              return await this.createGateway.createWorkQ(workQCase).toPromise();
            })();

            newWorkQCases.push(result.workqNumber);
            this.assignWorkQCases += result.workqNumber + ",";
            this.assignHSDNumbers += result.hsdId +",";
            this.HsdEsTicketCount++;
    
            if (this.HsdEsTicketCount === this.selectedHsdEsTickets.length) {
              this.displayModalWindowWithWarning(
                "WorkQ cases were successfully created! A total of '" +
                this.HsdEsTicketCount +
                "' WorkQs. The WorkQs are: " +
                newWorkQCases.join(", ").toString() +
                ".",
                "HSD-ES / CC Creation"
              );
              this.isAssignHsdEsToCCDisabled = false;
              this.showHsdEsWaitIndicator = false;
    
              //Update Lab Manager Details in RM Container (ToolsIntegration) to auto populate in workq page.
              //If data not found, no action required
              //If data found, update WorkQ number + HSD number in RM Container
              var id = null;
              var createdByWwid = null;
              if (sessionStorage.getItem("LMNewItemID") != null) {
                id = sessionStorage.getItem("LMNewItemID");
              }
              if (sessionStorage.getItem("CreatedByWwid") != null) {
                createdByWwid = sessionStorage.getItem("CreatedByWwid");
              }
    
              //if(id != null && createdByWwid != null){
              if (id != null && id != undefined) {
                this.assignWorkQCases = this.assignWorkQCases.endsWith(",") ? this.assignWorkQCases.substring(0, this.assignWorkQCases.length - 1) : this.assignWorkQCases;
                this.assignHSDNumbers = this.assignHSDNumbers.endsWith(",") ? this.assignHSDNumbers.substring(0, this.assignHSDNumbers.length - 1) : this.assignHSDNumbers;
                console.log(this.assignHSDNumbers);
                console.log(this.assignWorkQCases);
                this.updateToolsIntegration.workq = this.assignWorkQCases;
                this.updateToolsIntegration.hsdNumber = this.assignHSDNumbers;
                this.updateToolsIntegration.id = id;
                this.updateToolsIntegrationItem(this.updateToolsIntegration);
    
              }
              
            }
          }
          catch (error: any) {
            this.displayModalWindowWithError(
              "An error was encountered while attempting to create WorkQCase: " + error.toString()
            );
    
            this.isAssignHsdEsToCCDisabled = false;
            this.showHsdEsWaitIndicator = false;
          }
        }
      }
    },((error => {
      alert("An error was encountered while attempting to pull workq information: " + error.error);
    })));



   
    // for (const selectedHsdEsArticle of this.selectedHsdEsTickets) {
    //   workQCase.labs = [];
    //   workQCase.hsdId = selectedHsdEsArticle.id;
    //   const labInfoObj = new LabInfo();
    //   labInfoObj.name = selectedHsdEsArticle['support.site'];
    //   // labInfoObj.name = selectedRecipe.labs.toString();
    //   workQCase.labs.push(labInfoObj);
    //   workQCase.hsdTitle = selectedHsdEsArticle.title;
    //   workQCase.hsdDescription = selectedHsdEsArticle.description;
    //   workQCase.hsdReason = selectedHsdEsArticle.reason;
    //   workQCase.hsdPriority = selectedHsdEsArticle.priority;
    //   workQCase.recipeId = selectedRecipe.id;
    //   workQCase.recipeNumber = selectedRecipe.recipeNumber;
    //   workQCase.recipeName = selectedRecipe.recipeName;
    //   workQCase.buildingBlocks = simplifiedBBArray;
    //   workQCase.mode = selectedHsdEsArticle.mode == undefined ? "not started" : selectedHsdEsArticle.mode;
    //   workQCase.daysInWip = 0;
    //   workQCase.createdBy = currentOwner;
    //   workQCase.updatedBy = currentOwner;
    //   workQCase.revision = 0;
    //   workQCase.application = "Recipe Management";
    //   workQCase.hsdOwner = this.owner;
    //   console.log(workQCase);
      
    //   try {
    //     const result = await this.createGateway.createWorkQ(workQCase).toPromise();
    //     newWorkQCases.push(result.workqNumber);
    //     this.assignWorkQCases += result.workqNumber + ",";
    //     this.assignHSDNumbers += result.hsdId +",";
    //     this.HsdEsTicketCount++;

    //     if (this.HsdEsTicketCount === this.selectedHsdEsTickets.length) {
    //       this.displayModalWindowWithWarning(
    //         "WorkQ cases were successfully created! A total of '" +
    //         this.HsdEsTicketCount +
    //         "' WorkQs. The WorkQs are: " +
    //         newWorkQCases.join(", ").toString() +
    //         ".",
    //         "HSD-ES / CC Creation"
    //       );
    //       this.isAssignHsdEsToCCDisabled = false;
    //       this.showHsdEsWaitIndicator = false;

    //       //Update Lab Manager Details in RM Container (ToolsIntegration) to auto populate in workq page.
    //       //If data not found, no action required
    //       //If data found, update WorkQ number + HSD number in RM Container
    //       var id = null;
    //       var createdByWwid = null;
    //       if (sessionStorage.getItem("LMNewItemID") != null) {
    //         id = sessionStorage.getItem("LMNewItemID");
    //       }
    //       if (sessionStorage.getItem("CreatedByWwid") != null) {
    //         createdByWwid = sessionStorage.getItem("CreatedByWwid");
    //       }

    //       //if(id != null && createdByWwid != null){
    //       if (id != null && id != undefined) {
    //         this.assignWorkQCases = this.assignWorkQCases.endsWith(",") ? this.assignWorkQCases.substring(0, this.assignWorkQCases.length - 1) : this.assignWorkQCases;
    //         this.assignHSDNumbers = this.assignHSDNumbers.endsWith(",") ? this.assignHSDNumbers.substring(0, this.assignHSDNumbers.length - 1) : this.assignHSDNumbers;
    //         console.log(this.assignHSDNumbers);
    //         console.log(this.assignWorkQCases);
    //         this.updateToolsIntegration.workq = this.assignWorkQCases;
    //         this.updateToolsIntegration.hsdNumber = this.assignHSDNumbers;
    //         this.updateToolsIntegration.id = id;
    //         this.updateToolsIntegrationItem(this.updateToolsIntegration);

    //       }
          
    //     }
    //   }
    //   catch (error: any) {
    //     this.displayModalWindowWithError(
    //       "An error was encountered while attempting to create WorkQCase: " + error.toString()
    //     );

    //     this.isAssignHsdEsToCCDisabled = false;
    //     this.showHsdEsWaitIndicator = false;
    //   }
    // }
  }

  public btnOpenHsdEsArticlesPopupWindow(): void{
    this.selectedHsdEsTickets = [];
    this.windowOpenedTitle = "HSD-ES Tickets";
    this.isAssignHsdEsToCCDisabled = true;
    this.showHsdEsWaitIndicator = true;

    if (this.filterGetUserIdsidSubscription) {
      this.filterGetUserIdsidSubscription.unsubscribe();
    }

    this.filterGetUserIdsidSubscription = this.userApiCache.whoAmI().subscribe(userIdentities => {
      if (userIdentities.length > 0) {

        const currentUserIdsid = userIdentities[0].idsid;

        if (this.filterGetHsdEsArticlesSubscription) {
          this.filterGetHsdEsArticlesSubscription.unsubscribe();
        }
               
        //var getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE owner = '${currentUserIdsid}'`;
        var getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE owner = '${currentUserIdsid}' AND status NOT IN ('Complete', 'Rejected') AND submitted_date > daysAgo(7)`;
        this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe((result: any) => {

          var recordCount = result.length as number;

          if (recordCount == 0) {
            //this.displayModalWindowWithWarning("No matching records from HSD-ES were found.", "HSD-ES / CC Creation");
            this.isAssignHsdEsToCCDisabled = false;
            this.showHsdEsWaitIndicator = false;                      
            //return;
          }

          var newData: any = result;
          newData.forEach((d: any) => {
            d.isSelected = false;
          });

          this.hsdEsGridData = newData;
          this.hsdEsArticleGridData = newData;
          this.hsdEsArticleGridData.map(item => {
            item['support_site'] = item['support.site']
          })
          this.articleGridData = this.hsdEsArticleGridData;
          
          
          this.openPopupWindow("hsdesarticles");

          // if (this.selectedHSDESSearch != "")
          // {
          //   console.log(this.selectedHSDESSearch);
          //   (<HTMLInputElement>document.getElementById('searchHsdEsBox')).disabled = false;
          // }


        }
          , ((error => {
            this.displayModalWindowWithError("An error was encountered while attempting to assign HSD-ES articles to CC: " + error.toString());
            console.log(error);
            this.isAssignHsdEsToCCDisabled = false;
            this.showHsdEsWaitIndicator = false;
          })));
      }
    });
  }

  public async btnCreateHsdEs(): Promise<void> {

    //Get Recipe Category Ramp Up/ Ramp Down
    var selectedRecipeData = this.getSelectedRecord();
    this.workQCategory = selectedRecipeData['rampUp' as keyof DataItem] != undefined ? selectedRecipeData['rampUp' as keyof DataItem].toString() : ""; //Get Ramp Down Details

    this.btnToggle = !this.btnToggle;
    this.btnStatus = this.btnToggle ? "Enable" : "Disable";
    this.showHsdEsWaitIndicator = true;
    this.isButtonAssignHSDESToCC = false; //boolean - Assign HSDES To CC - check the click action (used this validation in SaveAndContinue function)
    //this.openPopupWindow("createcc");
    this.openLMPopUp();
    this.showHsdEsWaitIndicator = false;

  }

  //Ken add this for LM Assign Popup 15/3/2024
  public async btnAssignHsdEs(): Promise<void> {

    this.btnToggle = !this.btnToggle;
    this.btnStatus = this.btnToggle ? "Enable" : "Disable";
    this.showHsdEsWaitIndicator = true;

    //this.openPopupWindow("lmassignhsd");
    this.isButtonAssignHSDESToCC = true; ////boolean - Assign HSDES To CC - check the click action (used this validation in SaveAndContinue function)
    this.openLMPopUp();
    var selectedRecipeData = this.getSelectedRecord();
    //Get Recipe Category Ramp Up/ Ramp Down
    this.workQCategory = selectedRecipeData['rampUp' as keyof DataItem] != undefined ? selectedRecipeData['rampUp' as keyof DataItem].toString() : ""; //Get Ramp Down Details
    //this.showHsdEsWaitIndicator = false;

  }


  public redirectToGTS2(){
    this.closeLMPopUp(); //close the LM pop up window
    if (this.createHSD == "Create HSD-ES/CC" ){
      this.timeNowHSD = new Date();
      this.timeNowHSDStr = this.timeNowHSD.getFullYear().toString() + this.padTo2Digits(this.timeNowHSD.getMonth() + 1).toString() + this.padTo2Digits(this.timeNowHSD.getDate()).toString() + this.padTo2Digits(this.timeNowHSD.getHours()).toString() + this.padTo2Digits(this.timeNowHSD.getMinutes()).toString() + this.padTo2Digits(this.timeNowHSD.getSeconds()).toString();

      //Commented code is to remove the 'Update HSDES/CC' as Cian Shu has implemented it on GTS by Lay Ken on 8/8/2023

      // this.createHSD = "Update from HSD-ES";
      // if (this.gridContextMenu.items.length == 0)
      // {
      //   console.log('Undefined');
      // }
      // else
      // {
      //   this.gridContextMenu.items[0].text = this.createHSD;
      // }

      //this.gridContextMenu.items[0].text = this.createHSD;

      //this.getUserIdsid(this.lmOwner); //Call this function to return LM Owner Idsid value before pass to GTS tool
      //console.log(this.userIdsid);
      console.log("GTS Category: " + this.workQCategory);
      var url = environment.services.HsdEsUrl;
      url += "ticket_category=Lab%20Support&";
      url += "service_type=lab&";
      url += "service_sub_type=request&";
      url += "debug_level=Level%201&";
      url += "lab_org=iVE&";
      url += "category=System%20and%20Board%20Support&";
      if(this.workQCategory.toLowerCase() === 'ramp down')
      {
        url += "component=lab.System%20%26%20Board%20Support.Board%20Ramp%20Down&";
      }
      else
      {
        url += "component=lab.System%20%26%20Board%20Support.Board%20Ramp%20Up&";
      }
      url += "priority=3-medium&";
      url += "tag=" + this.idNumber + "," + this.timeNowHSDStr + "&";
      url += "customer_contact=" + this.userIdsid + "&";
      if (!this.boolBulkTicket) {        
        url += "physical_location=" + this.lmLocation + "&";
        url += "hostname=" + this.lmHostName + "&";
        url += "board_sn=" +this.lmBoardSN+ "&";
      }
      
      
      if(sessionStorage.getItem("LMNewItemID")!= null && sessionStorage.getItem("LMNewItemID")!= "" && sessionStorage.getItem("CreatedByWwid") != null && sessionStorage.getItem("CreatedByWwid") != "")
      {
        url += "is_from_recipe_management=true&";
        url += "integration_id="+sessionStorage.getItem("LMNewItemID")+"&";
        url += "created_by_wwid="+sessionStorage.getItem("CreatedByWwid");
      }
      else{
        url += "is_from_recipe_management=true";
      }
      window.open(url, 'popup', 'minWidth=1200,minHeight=600,');
    }

    this.isHsdButtonDisabled = false;
    this.showHsdEsWaitIndicator = false;
    this.userIdsid = "";

    // else{
    //   console.log('else here');
    //   this.isHsdButtonDisabled = true;
    //   this.HsdEsTicketCount = 0;
    //   const selectedEditRecipeData = this.getSelectedRecord();
    //   var selectedRecipe = selectedEditRecipeData as unknown as Recipe;
    //   var simplifiedBBArray: SelectedBuildingBlock[] = [];
    //   var orderNum = 0;
    //   this.selectedBuildingBlockList.forEach((bb:BuildingBlock)=> {
    //     var simplifiedBB: SelectedBuildingBlock = new SelectedBuildingBlock();
    //     simplifiedBB.buildingBlockId = bb.id;
    //     simplifiedBB.moduleName = bb.module;
    //     simplifiedBB.order = orderNum++;
    //     simplifiedBBArray.push(simplifiedBB);
    //   })
    //   this.createHSD = "Create HSD-ES/CC";
    //   if (this.gridContextMenu.items.length == 0)
    //   {
    //     console.log('Undefined');
    //   }
    //   else
    //   {
    //     this.gridContextMenu.items[0].text = this.createHSD;
    //   }

    //   //this.gridContextMenu.items[0].text = this.createHSD;
    //   var getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE tag = '${this.idNumber},${this.timeNowHSDStr}'`;
    //   if (this.filterGetHsdEsArticlesSubscription){
    //     this.filterGetHsdEsArticlesSubscription.unsubscribe();
    //   }
    //   var newWorkQCases: string[] = [];
    //   this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe(async (result: any) => {
    //       var recordCount = result.length as number;
    //       if (recordCount == 0){
    //         this.displayModalWindowWithWarning("No matching records from HSD-ES were found.", "HSD-ES / CC Creation");
    //         this.isHsdButtonDisabled = false;
    //         this.showHsdEsWaitIndicator = false;
    //         return;
    //       }
    //       if (this.filterCreateWorkQSubscription){
    //         this.filterCreateWorkQSubscription.unsubscribe();
    //       }
    //       for (var hsdEsTicket of result){
    //           var currentOwner = new Person();
    //           currentOwner.email = appSettings.appUserEmailId;
    //           currentOwner.name = appSettings.appUserFullname;
    //           currentOwner.wwid = appSettings.appUserWwid;
    //           var workQCase: WorkQ = new WorkQ();
    //           var labInfoObj = new LabInfo();
    //           workQCase.labs = [];
    //           labInfoObj.name = hsdEsTicket['services_sys_val.support.site_code'];
    //           workQCase.labs.push(labInfoObj);
    //           workQCase.hsdId= hsdEsTicket.id;
    //           workQCase.hsdTitle= hsdEsTicket.title;
    //           workQCase.hsdDescription= hsdEsTicket.description;
    //           workQCase.hsdReason= hsdEsTicket.reason;
    //           workQCase.hsdPriority = hsdEsTicket.priority;
    //           //workQCase.hsdOwner = currentOwner;
    //           workQCase.hsdOwner = (hsdEsTicket.owner != undefined && hsdEsTicket.owner == '')? hsdEsTicket.owner:'';
    //           workQCase.recipeId = selectedRecipe.id;
    //           workQCase.recipeNumber = selectedRecipe.recipeNumber;
    //           workQCase.recipeName = selectedRecipe.recipeName;
    //           workQCase.buildingBlocks = simplifiedBBArray;
    //           workQCase.mode = hsdEsTicket.mode == undefined ? "not started" : hsdEsTicket.mode;
    //           workQCase.daysInWip = 0;
    //           workQCase.createdBy = currentOwner;
    //           workQCase.updatedBy = currentOwner;
    //           workQCase.revision = 0;
    //           workQCase.application = "Recipe Management";
    //           const isWorkQCreated$ = this.createGateway.createWorkQ(workQCase);
    //           var result = await lastValueFrom(isWorkQCreated$).catch(error => {
    //             this.displayModalWindowWithError("An error was encountered while attempting to create WorkQCase: " + error.toString());
    //             console.log(error.toString());
    //             this.isHsdButtonDisabled = false;
    //             this.showHsdEsWaitIndicator = false;
    //           });
    //           newWorkQCases.push(result.workqNumber);
    //           this.HsdEsTicketCount++;
    //           if (this.HsdEsTicketCount == recordCount){
    //               this.isHsdButtonDisabled = false;
    //               this.showHsdEsWaitIndicator = false;
    //               this.displayModalWindowWithWarning("WorkQ cases were successfully created! A total of '" + this.HsdEsTicketCount + "' WorkQs. The WorkQs are: " + newWorkQCases.join(", ").toString() + ".", "HSD-ES / CC Creation");
    //           }
    //       }
    //   },((error => {
    //     this.displayModalWindowWithError("An error was encountered while attempting to retrieve HSD-ES information: " + error.toString());
    //     console.log(error.toString());
    //     this.isHsdButtonDisabled = false;
    //     this.showHsdEsWaitIndicator = false;
    //   })));
    // }
  }

  public btnCreateRecipe(): void {
    this.newRecipeDoc = "";
    var newRecipeObject: any = {};
    let owners: { owner: GraphUsers; coOwner: any } = {
      owner: appSettings.appUserObject,
      coOwner: null
    };
    let basicFeatures: {
      rampUp: any,
      labs: string[],
      validationTeams: any[],
      boardTypes: any,
      module: any, siliconProgram: any,
      products: any[], buildingBlockName: any,
      recipeName: any, type: any,
      description: any, emailDistribution: any
    } = {
      rampUp: null,
      labs: this.selectedLabs,
      validationTeams: this.selectedValidationTeams,
      boardTypes: null,
      module: null,
      siliconProgram: null,
      products: this.selectedProducts,
      buildingBlockName: null,
      recipeName: null,
      type: null,
      description: null,
      emailDistribution: null
    }
    newRecipeObject.source = "viewPage";
    newRecipeObject.owners = owners;
    newRecipeObject.basicFeatures = basicFeatures;

    let blockManager: {
      content: any
    } = { content: null }

    let CheckList: {
      checkList: any[]
    } = { checkList: [] }

    let orderBuildingBlocks: {
      modules: any[],
      buildingBlocks: any[]
    } = { modules: [], buildingBlocks: [] }

    newRecipeObject.blockManager = blockManager;
    newRecipeObject.CheckList = CheckList;
    newRecipeObject.orderBuildingBlocks = orderBuildingBlocks;

    var cnt = 0;
    // mamorrow 12-6: made changes to support distint module list
    this.selectedBuildingBlockList.sort((a, b) => a.module > b.module ? 1 : -1).forEach((bb: any) => {
      var newBB = { buildingBlockId: bb.id, order: cnt, moduleName: bb.module };
      newRecipeObject.orderBuildingBlocks.buildingBlocks.push(newBB);
      cnt++;
    });

    cnt = 0;
    var distinctModules = [... new Set(newRecipeObject.orderBuildingBlocks.buildingBlocks
      .map((item: any) => (item.moduleName)))].map((m, index) => ({ name: m, order: index }));

    newRecipeObject.orderBuildingBlocks.modules = distinctModules;
    // end mamorow 12-6 updates

    this.newRecipeDoc = JSON.stringify(newRecipeObject);
    sessionStorage.setItem("recipe_request_form", this.newRecipeDoc);
    sessionStorage.setItem("IsRedirectFromView", "1");
    this.Routes.navigate(['recipe/create/']);
  }

  private checkItemOwnership(item: any): boolean {

    var emailAddress = "";
    let found = false;

    if ("appSettings.appUserEmailId" in sessionStorage) {
      var userEmail = sessionStorage.getItem("appSettings.appUserEmailId");
      if (userEmail != undefined) {
        emailAddress = userEmail;
      }
    }

    var coOwnerEmailAddresses = item.coOwnerEmail.toString().split(';');

    switch (coOwnerEmailAddresses.length) {
      case 0:
        found = false;
        break;
      case 1:
        coOwnerEmailAddresses = item.coOwnerEmail.toString();
        if (coOwnerEmailAddresses.trim().toLowerCase() == emailAddress.toLowerCase()) {
          found = true;
        }
        break;
      default:
        for (var i = 0; i < coOwnerEmailAddresses.length; i++) {
          if (coOwnerEmailAddresses[i].trim().toLowerCase() == emailAddress.toLowerCase()) {
            found = true;
          }
        }
        break;
    }

    if (found) { return found; }

    if (item.ownerEmail != undefined && item.ownerEmail.toString().toLowerCase() == emailAddress.toLowerCase()) {
      found = true;
    }
    return found;
  }
  //On Selected row of BB Grid, populate Request detail panel and tabs
  public gridBBSelectionChange(selection: any): void {

    this.clearAll(false);
    let selectedData: any = undefined;

    if (selection.selectedRows == undefined) {
      if (!this.IsContextMenuTrigger) {
        selection.isSelected = !selection.isSelected;
      }
      selectedData = selection;
      this.IsContextMenuTrigger = false;
    }
    else {
      selectedData = selection.selectedRows[0].dataItem;
      selectedData.isSelected = !selectedData.isSelected;
      this.selectedBuildingBlockList = [];
      this.gridData.data.forEach((s: any) => (
        s.isSelected = (selectedData.id == s.id) ? s.isSelected : false
      ));
    }
    this.selectedItemFromGrid = selectedData;


    if (this.selectedItemFromGrid.isSelected == true &&

      (this.selectedItemFromGrid.isArchived == true ||
        this.selectedItemFromGrid.isDraft == true ||
        this.selectedItemFromGrid.isTemplate == true ||
        this.selectedItemFromGrid.module == '')) {

      this.selectedItemFromGrid.isSelected = false;

      this.displayModalWindowWithError("This Building Block '" + this.selectedItemFromGrid.buildingBlockNumber + "' cannot be used to create a recipe because it is a draft and/or is archived and/or is a template and/or its module is empty. Please check and try again.");

      if (this.selectedBuildingBlockList.length == 1) {
        this.selectedItemFromGrid = this.selectedBuildingBlockList[0];
      }
      this.onTabSelect();
      selection.target.isSelected = false;

      this.hideIndividualBBOptions = this.selectedBuildingBlockList.length > 1 ? true : false;
      this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;

    }
    else {

      if (this.selectedItemFromGrid.isSelected == false) {
        const index = this.selectedBuildingBlockList.indexOf(this.selectedItemFromGrid, 0);
        if (index > -1) {
          this.selectedBuildingBlockList.splice(index, 1);
          if (this.selectedBuildingBlockList.length == 1) {
            this.selectedItemFromGrid = this.selectedBuildingBlockList[0];
          }
        }
      }
      else {
        if (!this.selectedBuildingBlockList.includes(this.selectedItemFromGrid)) {
          this.selectedBuildingBlockList.push(this.selectedItemFromGrid);
        }
      }
      this.hideIndividualBBOptions = this.selectedBuildingBlockList.length > 1 ? true : false;
      this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;

      sessionStorage.setItem("viewBBGridSelectedItem", JSON.stringify(this.selectedItemFromGrid));
      this.onTabSelect();
      // this.disableBBContextMenu();
      //Hide the menu for now
      this.handleContextMenu("CreateRecipe", this.isCreateRecipeEnabled);
      this.IsCheckBokClicked = false;
    }
  }

  //On Selected row of Recipe Grid, populate Request detail panel and tabs
  public gridRecipeSelectionChange(selection: any): void {

    this.clearAll(false);
    const selectedData = selection.selectedRows != undefined ? selection.selectedRows[0].dataItem : selection;
    this.selectedItemFromGrid = selectedData;
    sessionStorage.setItem("viewRecipeGridSelectedItem", JSON.stringify(this.selectedItemFromGrid));
    this.onTabSelect();
  }

  public disableBBContextMenu() {
    if (this.hideIndividualBBOptions) {
      for (var i = 0; i < this.contextMenuOptionsForBB.length; i++) {
        if (this.contextMenuOptionsForBB[i].text != "Create Recipe") {
          this.contextMenuOptionsForBB[i].disabled = true;
        }
      }
    }
  }

  public handleContextMenu(enumValueStr: string, boolValue: boolean, titleValue: string = "") {

    var enumValue = null;

    switch (this.incomingMode?.toLowerCase()) {
      case "bb":
      case "bb-template":
        enumValue = enumValueStr + "BB" as keyof typeof BBEnumerations;

        if (BBEnumerations[enumValue] != undefined) {

          if (titleValue != "") {
            this.contextMenuOptionsForBB[BBEnumerations[enumValue]].text = titleValue + " Building Block";
          }
          this.contextMenuOptionsForBB[BBEnumerations[enumValue]].disabled = !boolValue;
        }
        break;
      case "recipe":
      case "recipe-template":
      default:
        enumValue = enumValueStr + "Recipe" as keyof typeof RecipeEnumerations;

        if (RecipeEnumerations[enumValue] != undefined) {

          if (titleValue != "") {
            this.contextMenuOptionsForRecipe[RecipeEnumerations[enumValue]].text = titleValue + " Recipe";
          }
          this.contextMenuOptionsForRecipe[RecipeEnumerations[enumValue]].disabled = !boolValue;
        }
        break;
    }
  }

  public loadRecipeRevisionHistory(rmDataItem: DataItem): void {

    const selectedData: DataItem = rmDataItem;
    this.idNumber = selectedData['recipeNumber' as keyof DataItem] != undefined ? selectedData['recipeNumber' as keyof DataItem].toString() : "";
    var recipeId = selectedData['id' as keyof DataItem] != undefined ? selectedData['id' as keyof DataItem].toString() : "";
    var simplifiedBBList = selectedData['buildingBlocks' as keyof DataItem] != undefined ? selectedData['buildingBlocks' as keyof DataItem] as BuildingBlock[] : [];
    var uniqueBBs = simplifiedBBList.filter(x => x.buildingBlockId != null);
    this.recipeCount = uniqueBBs == undefined ? 0 : uniqueBBs.length;
    this.isRecipeRevHistoryGridLoading = true;

    this.buildingBlockDetailsGrid = [];
    this.supportGridData = [];

    if (this.recipeCount <= 0) {
      this.isPdfOptionDisabled = true;

    }
    else {
      this.isPdfOptionDisabled = false;

    }
    this.handleContextMenu("ShowPdf", !this.isPdfOptionDisabled);

    if (this.filterGetRecipeRevHistorySubscription) {
      this.filterGetRecipeRevHistorySubscription.unsubscribe();
    }

    this.filterGetRecipeRevHistorySubscription = this.searchGateway.getRecipeRevHistory(recipeId).subscribe(results => {

      if (results.length > 0) {
        this.searchRowFound = true;
      }
      else {
        this.searchRowFound = false;
      }

      this.recipeHistoryGridData = this.handleComplexColumns(this.gridRowToUpper(results), "recipe");
      this.supportGridData = this.recipeHistoryGridData;
      this.recipeRevData = this.recipeHistoryGridData;
      this.isRecipeRevHistoryGridLoading = false;

    }, (error: any) => { this.isRecipeRevHistoryGridLoading = false })
  }

  public loadRecipesWithBuildingBlock(rmDataItem: DataItem): void {

    const selectedData: DataItem = rmDataItem;
    var bbId = selectedData['id' as keyof DataItem] != undefined ? selectedData['id' as keyof DataItem].toString() : "";
    this.idNumber = selectedData['buildingBlockNumber' as keyof DataItem] != undefined ? selectedData['buildingBlockNumber' as keyof DataItem].toString() : "";
    let content = selectedData['content' as keyof DataItem] != undefined ? selectedData['content' as keyof DataItem].toString() : "";

    this.checkIfPdfButtonIsEnabled(content);
    this.recipeWithBBGridLoading = true;

    if (this.filterGetRecipesWithBBSubscription) {
      this.filterGetRecipesWithBBSubscription.unsubscribe();
    }

    this.selectedRowKeysBBInActiveRecipe = [];
    var counter: number = 0;
    this.filterGetRecipesWithBBSubscription = this.searchGateway.getRecipesWithBB(bbId).subscribe(results => {

      for (var singleBB in results) {
        this.selectedRowKeysBBInActiveRecipe.push(counter);
        counter++;
      }

      if (results.length > 0) {
        this.searchRowFound = true;
        this.recipeCount = results.length;
      }
      else {
        this.searchRowFound = false;
        this.recipeCount = 0;
      }

      this.recipeWithBBGridData = this.handleComplexColumns(this.gridRowToUpper(results), "recipe");
      this.recipeWithBuildingBlocksGridData = this.recipeWithBBGridData;
      this.recipeWithBBGridLoading = false;
      this.buildingBlockDetailsGrid = [];
      this.recipeData = this.recipeWithBuildingBlocksGridData;


    }, (error: any) => { this.recipeWithBBGridLoading = false })
  }

  public loadBuildingBlockRevisionHistory(rmDataItem: DataItem): void {

    const selectedData: DataItem = rmDataItem;
    var bbId = selectedData['id' as keyof DataItem] != undefined ? selectedData['id' as keyof DataItem].toString() : "";
    this.idNumber = selectedData['buildingBlockNumber' as keyof DataItem] != undefined ? selectedData['buildingBlockNumber' as keyof DataItem].toString() : "";
    let content = selectedData['content' as keyof DataItem] != undefined ? selectedData['content' as keyof DataItem].toString() : "";

    this.checkIfPdfButtonIsEnabled(content);
    this.buildingBlockRevHistoryGridLoading = true;

    if (this.filterGetRecipeRevHistorySubscription) {
      this.filterGetRecipeRevHistorySubscription.unsubscribe();
    }

    this.filterGetRecipeRevHistorySubscription = this.searchGateway.getBuildingBlockRevHistory(bbId).subscribe(results => {

      if (results.length > 0) {
        this.searchRowFound = true;
      }
      else {
        this.searchRowFound = false;
      }

      this.buildingBlockHistoryGridData = this.handleComplexColumns(this.gridRowToUpper(results), "bb");
      this.bbHistoryGridData = this.buildingBlockHistoryGridData;
      this.buildingBlockRevHistoryGridLoading = false;
      this.buildingBlockDetailsGrid = [];
      this.bbRevHisData = this.buildingBlockHistoryGridData;

    }, (error: any) => { this.buildingBlockRevHistoryGridLoading = false })
  }

  private setStandardBooleanFlags(mode: string) {

    let isTemplate = this.selectedItemFromGrid['isTemplate' as keyof DataItem] != undefined ? this.selectedItemFromGrid['isTemplate' as keyof DataItem].toString().toUpperCase() : "";
    let isDraft = this.selectedItemFromGrid['isDraft' as keyof DataItem] != undefined ? this.selectedItemFromGrid['isDraft' as keyof DataItem].toString().toUpperCase() : "";
    let isArchived = this.selectedItemFromGrid['isArchived' as keyof DataItem] != undefined ? this.selectedItemFromGrid['isArchived' as keyof DataItem].toString().toUpperCase() : "";

    if (isTemplate.toLowerCase() === "true") {
      this.isItATemplate = true;
    }
    else {
      this.isItATemplate = false;
    }

    if (isDraft.toLowerCase() === "true") {
      this.isItADraft = true;
    }
    else {
      this.isItADraft = false;
    }

    if (isArchived.toLowerCase() === "true") {
      this.isItArchived = true;
    }
    else {
      this.isItArchived = false;
    }

    if (this.checkItemOwnership(this.selectedItemFromGrid)) {
      this.editOrViewButtonLabel = "Edit " + mode;
      this.enableDelete = true;
      this.handleContextMenu("Edit", true, "Edit");

    }
    else {
      this.editOrViewButtonLabel = "View " + mode;
      this.enableDelete = false;
      this.handleContextMenu("Edit", true, "View");

    }
    this.handleContextMenu("Delete", this.enableDelete);

    if ((appSettings.isUserAMemberOf("RM_Admins") || appSettings.isUserAMemberOf("RM_L2_Technicians")) &&
      this.isItADraft == false && this.isItATemplate == false && this.isItArchived == false) {
      this.enableEdit = true;

    }
    else {
      this.enableEdit = false;

    }

    if (this.isItADraft || this.isItArchived || this.isItATemplate) {
      this.isHsdButtonDisabled = true;
      this.isAssignHsdEsToCCDisabled = true;
    }
    else {
      this.isHsdButtonDisabled = false;
      this.isAssignHsdEsToCCDisabled = false;
    }

    this.handleContextMenu("CreateHsd", !this.isHsdButtonDisabled);
    this.handleContextMenu("AssignHsd", !this.isAssignHsdEsToCCDisabled);
  }

  public onTabSelect(e?: SelectEvent): void {

    var selectedEvent: any;

    if (e != null) {
      selectedEvent = e;
      sessionStorage.setItem("viewSelectedTab", JSON.stringify(e));
    }
    else {
      var eventMetadata = sessionStorage.getItem("viewSelectedTab");
      if (eventMetadata != null) {
        selectedEvent = JSON.parse(eventMetadata) as unknown as any;
      }
      else {
        switch (this.createMode.toLowerCase()) {
          case "building block":
          case "building block template":
            selectedEvent = { title: "bb details" };
            if (this.selectedItemFromGrid == undefined) {
              this.selectedItemFromGrid = this.buildingBlockGridData[0];
            }
            break;
          case "recipe":
          case "recipe template":
          default:
            selectedEvent = { title: "recipe details" };
            if (this.selectedItemFromGrid == undefined) {
              this.selectedItemFromGrid = this.recipeGridData[0];
            }
            break;
        }
      }
    }

    if (this.selectedItemFromGrid != undefined) {
      this.setStandardBooleanFlags(this.createMode);
      this.enableViewOrEdit = true;
      this.enableCopy = true;
      this.handleContextMenu("Copy", this.enableCopy);
    }
    else {
      this.clearItemDetails();
    }
    if (this.selectedItemFromGrid != undefined) {
      switch (selectedEvent.title.toLowerCase()) {
        case "recipe details":
          this.loadRecipeDetails(this.selectedItemFromGrid);
          this.isRecipesWithBBOn = false;
          this.isBBRevisionHistoryOn = false;
          break;
        case "recipe revision history":
          this.isRecipesWithBBOn = false;
          this.isBBRevisionHistoryOn = false;
          this.loadRightPaneCommonDetails(this.selectedItemFromGrid, false);
          this.loadRecipeRevisionHistory(this.selectedItemFromGrid);
          break;
        case "bb details":
          this.loadBBDetails(this.selectedItemFromGrid);
          this.isRecipesWithBBOn = false;
          this.isBBRevisionHistoryOn = false;
          break;
        case "recipes with this bb":
          this.isRecipesWithBBOn = true;
          this.isBBRevisionHistoryOn = false;
          this.loadRightPaneCommonDetails(this.selectedItemFromGrid, true);
          this.loadRecipesWithBuildingBlock(this.selectedItemFromGrid);
          break;
        case "bb revision history":
          this.isRecipesWithBBOn = false;
          this.isBBRevisionHistoryOn = true;
          this.loadRightPaneCommonDetails(this.selectedItemFromGrid, true);
          this.loadBuildingBlockRevisionHistory(this.selectedItemFromGrid);
          break;
        default:
          this.isRecipesWithBBOn = false;
          this.isBBRevisionHistoryOn = false;
          this.buildingBlockDetailsGrid = [];
          break;
      }
    }
  }

  public clearItemDetails() {

    this.recipeId = "";
    this.idNumber = "";
    this.idName = ""; //Ken added this for BB/Recipe name display in 20/9/2023
    this.idNumberName = ""; //Ken added this for BB/Recipe name display in 22/9/2023
    this.createdBy = "";
    this.createdDate = "";
    this.rampUp = "";
    this.description = "";
    this.checkList = "";
    this.siliconProgram = "";
    this.isItArchived = false;
    this.isItADraft = false;
    this.isItATemplate = false;
    this.parentRecipeRevision = "";
    this.parentRecipeNumber = "";
    this.updatedBy = ""
    this.updatedDate = ""
    this.recipeCount = 0
    this.isPdfOptionDisabled = true;
    this.handleContextMenu("ShowPdf", !this.isPdfOptionDisabled);
    this.buildingBlockDetailsGrid = [];
    this.parentBBNumber = "";
    this.parentBBRevision = "";

    this.isHsdButtonDisabled = false;
    this.isAssignHsdEsToCCDisabled = false;

    this.isHsdButtonDisabled = true;
    this.isAssignHsdEsToCCDisabled = true;
    this.enableViewOrEdit = false;
    this.enableCopy = false;
    this.enableDelete = false;
  }

  public getJoinedValues(values: any, fieldName: string = ""): string {

    var value: string = "";

    switch (fieldName.toLowerCase()) {
      case "email":
        value = values.filter((item: any) => item != null).map((filtered: any) => filtered).join(', ');
        break;
      case "coowner":
        value = values.filter((item: any) => item.name != null).map((filtered: any) => filtered.name).join('; ');
        break;
      case "coowneremail":
        value = values.filter((item: any) => item.email != null).map((filtered: any) => filtered.email).join('; ');
        break;
      default:
        value = values.filter((item: any) => item.name != null).map((filtered: any) => filtered.name).join(', ');
        break;
    }
    return value;
  }

  private checkIfPdfButtonIsEnabled(content: string): void {

    if (content != null && content != '') {
      this.isPdfOptionDisabled = false;
    }
    else {
      this.isPdfOptionDisabled = true;
    }

    this.handleContextMenu("ShowPdf", !this.isPdfOptionDisabled);
  }

  private checkIfRecipeHasBBs(): void {

    if (this.buildingBlockDetailsGrid.length <= 0) {
      this.isRecipeEmpty = true;
    }
    else {
      this.isRecipeEmpty = false;
    }
    this.handleContextMenu("ShowPdf", !this.isRecipeEmpty);
  }

  public onloadingPdfProgress(progressData: PDFProgressData, id: any) {

    this.checkListContentLoading[id].loaded = progressData.loaded;
    this.checkListContentLoading[id].progress = progressData.total;
  }

  public loadRightPaneCommonDetails(rmDataItem: DataItem, isBB: boolean) {
    if (rmDataItem == undefined) {
      return;
    }

    try {
      const selectedData: DataItem = rmDataItem;
      if (isBB) {
        this.idNumber = selectedData['buildingBlockNumber' as keyof DataItem] != undefined ? selectedData['buildingBlockNumber' as keyof DataItem].toString().toUpperCase() : "";
        this.idName = selectedData['buildingBlockName' as keyof DataItem] != undefined ? selectedData['buildingBlockName' as keyof DataItem].toString().toUpperCase() : "";
      }
      else {
        this.idNumber = selectedData['recipeNumber' as keyof DataItem] != undefined ? selectedData['recipeNumber' as keyof DataItem].toString().toUpperCase() : "";
        this.idName = selectedData['recipeName' as keyof DataItem] != undefined ? selectedData['recipeName' as keyof DataItem].toString().toUpperCase() : "";
      }

      if (this.idName != "") {
        this.idNumberName = this.idNumber + ' - ' + this.idName;
      }
      else {
        this.idNumberName = this.idNumber;
      }
    }
    catch (Error) {
      console.log(Error);
      var actualError = Error as unknown as Error;
      this.displayModalWindowWithError(actualError.message);
    }

  }
  public loadBBDetails(rmDataItem: DataItem) {

    if (rmDataItem == undefined) {
      return;
    }

    try {
      var bbItem = rmDataItem as unknown as any;

      sessionStorage.setItem("viewBBGridSelectedItem", JSON.stringify(bbItem));
      this.selectedItemFromGrid = bbItem;
      this.buildingBlockDetailsGrid = [];
      this.supportGridData = [];
      this.buildingBlockDetailsGrid.push(bbItem);
      this.supportGridData.push(bbItem);
      const selectedData: DataItem = rmDataItem;

      this.idNumber = selectedData['buildingBlockNumber' as keyof DataItem] != undefined ? selectedData['buildingBlockNumber' as keyof DataItem].toString().toUpperCase() : "";
      //Ken added this for BB/Recipe Name display on 20/9/2023
      this.idName = selectedData['buildingBlockName' as keyof DataItem] != undefined ? selectedData['buildingBlockName' as keyof DataItem].toString().toUpperCase() : "";
      this.parentBBRevision = bbItem.parentBuildingBlock != null ? bbItem.parentBuildingBlock.revision : "N/A";
      this.parentBBNumber = "Retrieving...";

      //Ken added this for BB/Recipe name display in 22/9/2023
      if (this.idName != "") {
        this.idNumberName = this.idNumber + ' - ' + this.idName;
      }
      else {
        this.idNumberName = this.idNumber;
      }

      if (bbItem.parentBuildingBlock != null) {
        let params: any = { buildingBlockId: bbItem.parentBuildingBlock.id };

        if (this.filterGetBBsSubscription) {
          this.filterGetBBsSubscription.unsubscribe();
        }
        this.filterGetBBsSubscription = this.searchGateway.getBuildingBlocks(params, true).subscribe(result => {
          this.parentBBNumber = result.length == 1 ? result[0].buildingBlockNumber : "N/A";
        }, (error: any) => { this.parentBBNumber = "Unable to retrieve"; });
      } else {
        this.parentBBNumber = "N/A";
      }

      this.status = bbItem.status;
      this.createdBy = selectedData['createdByName' as keyof DataItem] != undefined ? selectedData['createdByName' as keyof DataItem].toString().toUpperCase() : "";
      this.createdDate = selectedData['createdDate' as keyof DataItem] != undefined ? selectedData['createdDate' as keyof DataItem].toString() : "";
      this.description = selectedData['description' as keyof DataItem] != undefined ? selectedData['description' as keyof DataItem].toString().toUpperCase() : "";
      this.module = selectedData['module' as keyof DataItem] != undefined ? selectedData['module' as keyof DataItem].toString().toUpperCase() : "";
      this.siliconProgram = selectedData['siliconProgram' as keyof DataItem] != undefined ? selectedData['siliconProgram' as keyof DataItem].toString().toUpperCase() : "";
      this.updatedBy = selectedData['updatedByName' as keyof DataItem] != undefined ? selectedData['updatedByName' as keyof DataItem].toString().toUpperCase() : "";
      this.updatedDate = selectedData['updatedDate' as keyof DataItem] != undefined ? selectedData['updatedDate' as keyof DataItem].toString() : "";
      //this.checkList = selectedData['checkList' as keyof DataItem] != undefined ? selectedData['checkList' as keyof DataItem].toString() : "";
      this.checkListData = bbItem.checkList; //To load the data into a table format updated on 14 July 2023 by Khoo Lay Ken

      let bbId = selectedData['id' as keyof DataItem] != undefined ? selectedData['id' as keyof DataItem].toString() : "";
      let content = selectedData['content' as keyof DataItem] != undefined ? selectedData['content' as keyof DataItem].toString() : "";

      this.checkIfPdfButtonIsEnabled(content);

      this.windowOpenedTitle = "Building Block Details for Building Block '" + this.idNumber + "' - Building Block PDF File";
      this.checkListContentLoading[bbId] = { loaded: false, progress: 0 };
    }
    catch (Error) {
      console.log(Error);
      var actualError = Error as unknown as Error;
      this.displayModalWindowWithError(actualError.message);
    }
  }

  public loadRecipeDetails(rmDataItem: DataItem) {

    if (rmDataItem == undefined) {
      return;
    }

    var simplifiedBBList: BuildingBlock[] = [];
    const selectedData: DataItem = rmDataItem;

    try {
      var recipeItem = rmDataItem as unknown as any;
      sessionStorage.setItem("viewRecipeGridSelectedItem", JSON.stringify(recipeItem));
      this.selectedItemFromGrid = recipeItem;

      this.recipeId = this.idNumber = selectedData['id' as keyof DataItem] != undefined ? selectedData['id' as keyof DataItem].toString() : "";
      this.idNumber = selectedData['recipeNumber' as keyof DataItem] != undefined ? selectedData['recipeNumber' as keyof DataItem].toString().toUpperCase() : "";
      //Ken added this for BB/Recipe name display in 20/9/2023
      this.idName = selectedData['recipeName' as keyof DataItem] != undefined ? selectedData['recipeName' as keyof DataItem].toString().toUpperCase() : "";
      this.createdBy = selectedData['createdByName' as keyof DataItem] != undefined ? selectedData['createdByName' as keyof DataItem].toString().toUpperCase() : "";
      this.createdDate = selectedData['createdDate' as keyof DataItem] != undefined ? selectedData['createdDate' as keyof DataItem].toString() : "";
      this.rampUp = selectedData['rampUp' as keyof DataItem] != undefined ? selectedData['rampUp' as keyof DataItem].toString().toUpperCase() : "";
      this.description = selectedData['description' as keyof DataItem] != undefined ? selectedData['description' as keyof DataItem].toString().toUpperCase() : "";
      this.siliconProgram = selectedData['siliconProgram' as keyof DataItem] != undefined ? selectedData['siliconProgram' as keyof DataItem].toString().toUpperCase() : "";

      //Ken added this for BB/Recipe name display in 22/9/2023
      if (this.idName != "") {
        this.idNumberName = this.idNumber + ' - ' + this.idName;
      }
      else {
        this.idNumberName = this.idNumber;
      }
      // The below codes commented by Rajesh on 17 July'23

      /*this.parentRecipeRevision = recipeItem.parentRecipe.revision != null ? recipeItem.parentRecipe.revision : "N/A";
      this.parentRecipeNumber = "Retrieving...";

      if (recipeItem.parentRecipe != null){
        let params: any = { recipeId: recipeItem.parentRecipe.id };

        if (this.filterGetRecipesSubscription){
          this.filterGetRecipesSubscription.unsubscribe();
        }
        this.filterGetRecipesSubscription = this.searchGateway.getRecipes(params, true).subscribe(result =>{
          this.parentRecipeNumber = result.length == 1 ? result[0].recipeNumber : "N/A";
        },(error:any)=>{ this.parentRecipeNumber = "Unable to retrieve"; } );
      }else{
        this.parentRecipeNumber = "N/A";
      }*/

      //=============================================================================================
      //Author: Rajesh
      //Date  : 17 July 2023
      //Purpose: Revision read property issue fix and parentRecipenumber "Retrieving" status update
      //==============================================================================================
      this.parentRecipeRevision = recipeItem.parentRecipe != null ? (recipeItem.parentRecipe.revision != null ? recipeItem.parentRecipe.revision : "N/A") : "N/A";
      this.parentRecipeNumber = "Retrieving...";

      if (recipeItem.parentRecipe != null) {
        if (recipeItem.parentRecipe.id != null && recipeItem.parentRecipe.id != '') {
          let params: any = { recipeId: recipeItem.parentRecipe.id };
          if (this.filterGetRecipesSubscription) {
            this.filterGetRecipesSubscription.unsubscribe();
          }
          this.filterGetRecipesSubscription = this.searchGateway.getRecipes(params, true).subscribe(result => {
            this.parentRecipeNumber = result.length == 1 ? result[0].recipeNumber : "N/A";
          }, (error: any) => { this.parentRecipeNumber = "Unable to retrieve"; });

        }
        else {
          this.parentRecipeNumber = "N/A";
        }
      } else {
        this.parentRecipeNumber = "N/A";
      }

      this.status = recipeItem.status;
      this.updatedBy = selectedData['updatedByName' as keyof DataItem] != undefined ? selectedData['updatedByName' as keyof DataItem].toString().toUpperCase() : "";
      this.updatedDate = selectedData['updatedDate' as keyof DataItem] != undefined ? selectedData['updatedDate' as keyof DataItem].toString() : "";
      simplifiedBBList = selectedData['buildingBlocks' as keyof DataItem] != undefined ? selectedData['buildingBlocks' as keyof DataItem] as BuildingBlock[] : [];

      var uniqueBBs = simplifiedBBList.filter(x => x.buildingBlockId != null);
      this.recipeCount = uniqueBBs == undefined ? 0 : uniqueBBs.length;
    }
    catch (Error) {
      console.log(Error);
      var actualError = Error as unknown as Error;
      this.displayModalWindowWithError(actualError.message);
    }
    if (this.recipeCount > 0) {
      var bbListIds = simplifiedBBList.map(x => x.buildingBlockId);

      this.loadBuildingBlockDetailForSelectedRecipe(bbListIds, simplifiedBBList);

      this.checkListContentLoading[this.recipeId] = { loaded: false, progress: 0 };

      this.windowOpenedTitle = "Recipe Details for Recipe '" + this.idNumber + "' - Combined PDF Document for Active Recipe (all PDF documents for BBs combined)";
    }
    else {
      this.isPdfOptionDisabled = true;
      this.buildingBlockDetailsGrid = [];
      this.checkIfRecipeHasBBs();
      this.handleContextMenu("ShowPdf", !this.isPdfOptionDisabled);
    }
  }

  public loadCombinedPdfContent(contents: string[], bbNumbers: string[]) {

    this.isCombinedPdfLoading = true;
    var subscribe = this.fileGateway.getCombinedPdfs(contents, bbNumbers, this.recipeId).subscribe({
      next: (data) => {
        if (this.isCombinedPdfLoading) {
          this.createCombinedPdfFromBlob(data);
        }
      },
      error: (err: any) => {
        console.log(err);
        this.isCombinedPdfLoading = false;
      }
    });
  }

  public createCombinedPdfFromBlob(pdf: Blob) {

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.recipeDocBinary = reader.result;
      this.isCombinedPdfLoading = false;
    }, false);

    if (pdf) {
      reader.readAsDataURL(pdf);
    }
  }

  public displayModalWindowWithError(message: string): void {

    this.messageTitle = "Recipe Management Error";
    this.messageDescription = message;

    const { type, direction, duration, myAnimation } = this.animationConfig;
    this.animation = myAnimation ? { duration, type, direction } : false;
    this.errorDialogOpened = true;
  }

  // public displayModalWindowWithWarning(message: string, title?: string): void {

  //   this.messageTitle = title == undefined ? "Recipe Management Warning" : title;
  //   this.messageDescription = message;

  //   const { type, direction, duration, myAnimation } = this.animationConfig;
  //   this.animation = myAnimation ? { duration, type, direction } : false;
  //   this.promptDialogOpened = true;
  // }
  
  public displayModalWindowWithWarning(message: string, title?: string): void {
    this.messageTitle = title == undefined ? "Recipe Management Warning" : title;
    this.messageDescription = message;

    const { type, direction, duration, myAnimation } = this.animationConfig;
    this.animation = myAnimation ? { duration, type, direction } : false;
    this.promptDialogOpened = true;

    // Set the z-index of the modal window to a higher value to make it appear in front
    const modalWindow = document.querySelector(".modal-window") as HTMLElement;
    if (modalWindow) {
      modalWindow.style.zIndex = "9999";
    }
  }

  public mapOrder(array: any[], order: any[], key: string) {
    //this function is for reorder BB for recipe 4/10/2023 - Ken
    array.sort(function (a, b) {

      var A = a[key], B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {

        return 1;

      } else {

        return -1;

      }

    });

    return array;

  };

  public loadBuildingBlockDetailForSelectedRecipe(bbListIds: string[], sortBBList: BuildingBlock[]) {

    if (bbListIds.length > 0) {

      if (this.filterGetBBDetailsSubscription) {
        this.filterGetBBDetailsSubscription.unsubscribe();
      }
      this.isSupportGridDataLoading = true;

      this.filterGetBBDetailsSubscription = this.searchGateway.getBuildingBlockDetailsForBBList(bbListIds).subscribe(result => {
        //result = result.sort((a,b) => a.buildingBlockNumber < b.buildingBlockNumber ? -1 : a.buildingBlockNumber > b.buildingBlockNumber ? 1 : 0);

        //This function is for reorder BB for recipe 4/10/2023 - Ken
        var order = sortBBList.map(x => x.buildingBlockId);
        result = this.mapOrder(result, order, 'id');
        var contentless = result.find(x => x.content != null && x.content != '');

        if (contentless != null) {
          this.isPdfOptionDisabled = false;
          var bbsWithContent = result.filter(x => x.content != null && x.content != '');
          var contents: string[] = [];
          let bbNumbers: string[] = []; // WH 26July2023 - pass bbNumbers as doc separator
          bbsWithContent.forEach(x => {
            contents.push(x.content);
            bbNumbers.push(x.buildingBlockNumber); // WH 26July2023 - pass bbNumbers as doc separator
          })
          this.loadCombinedPdfContent(contents, bbNumbers);
          this.getCombinedPdf(contents, bbNumbers);
        }
        else {
          this.isPdfOptionDisabled = true;
        }

        this.handleContextMenu("ShowPdf", !this.isPdfOptionDisabled);

        this.buildingBlockDetailsGrid = [];
        this.selectedBuildingBlockList = [];
        this.supportGridData = [];

        var bbData: any = result;
        bbData.forEach((d: any) => {
          d.isSelected = true;
          this.selectedBuildingBlockList.push(d);
        });

        this.buildingBlockDetailsGrid = this.gridRowToUpper(this.handleComplexColumns(bbData, "bb"));
        this.supportGridData = this.buildingBlockDetailsGrid;
        this.bbExportData = this.buildingBlockDetailsGrid;
        this.checkIfRecipeHasBBs();
        this.isSupportGridDataLoading = false;

      }, ((error => {
        console.log(error.error);
        this.isSupportGridDataLoading = false;
      })));
    }
  }

  private getCombinedPdf(contents: string[], bbNumbers: string[]) {

    if (contents.length > 0) {
      this.recipeDoc = {
        url: this.fileGateway.getUrlforFiles('combinedPdfs', contents, bbNumbers, this.recipeId) // WH 26July2023 - pass bbNumbers as doc separator
      }
    }
  }

//   public downloadPDF(url: any) {
//     url = url.replace('combinedPdfs', 'downloadpdf');  
//     var a = document.createElement('a');
//     a.target = '_new';
//     a.href = url;
//     a.click();
// }

  public clearAll(resetPage?: boolean) {

    this.idNumber = '';
    this.idName = ''; //Ken added this for BB/Recipe name display in 20/9/2023
    this.idNumberName = ''; //Ken added this for BB/Recipe name display in 22/9/2023
    this.createdBy = '';
    this.createdDate = '';
    this.description = '';
    this.siliconProgram = ''
    this.module = '';
    this.recipeCount = 0;
    this.updatedBy = '';
    this.updatedDate = '';
    this.formIsDirty = false;
    this.searchRowFound = false;

    if (resetPage != null && resetPage == true) {
      this.isEmpty = true;
    }
  }

  //Export to Excel
  public exportToExcel(grid: any): void {

    grid.saveAsExcel();
  }

  //Ken Added this to retrieve Export Excel for different Grids - 15/9/2023
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.exportData, {
      }).data,
    };

    return result;
  }

  public bbData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.bbExportData, {
      }).data,
    };

    return result;
  }

  public revHistoryData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.recipeRevData, {
      }).data,
    };

    return result;
  }

  public recipesData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.recipeData, {
      }).data,
    };

    return result;
  }

  public bbRevHistoryData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.bbRevHisData, {
      }).data,
    };

    return result;
  }
  public encodeURLContent(contentURL: string) {
    return encodeURIComponent(contentURL);
  }

  ////BB GRID Functionality
  public gridSettings_BBGrid: GridSettings = {
    state: {
      skip: 0,
      take: 10,

      filter: {
        logic: 'and',
        filters: [],
      },
    }
  };

  public selectedChipIndex: number = 0;

  public clearAll_BBGrid_Filters(): void {
    this.gridSettings_BBGrid = {
      state: {
        skip: 0,
        take: 10,

        filter: {
          logic: 'and',
          filters: [],
        },
      }
    };
  }

  public dataStateChange_BBGrid(state: DataStateChangeEvent, gridType: string = ""): void {
    this.isReloadData = (<number>this.gridSettings_BBGrid.state.skip == state.skip && this.gridSettings_BBGrid.state.filter == state.filter);
    this.gridSettings_BBGrid.state = state;
    if (gridType == '') {
      this.isMainGridChange = true;
      this.reload_BBGridData();
    }
  }

  public reload_BBGridData() {
    this.requestGridLoading = true;
    var mode = sessionStorage.getItem("viewMode");
    if (mode != null) {
      if (this.gridSettings_BBGrid.state.filter) {
        this.gridData = process(filterBy(this.mainGridData, this.gridSettings_BBGrid.state.filter), this.gridSettings_BBGrid.state);
      }
      var selectRow: number = <number>this.gridSettings_BBGrid.state.skip;
      var sIndex: number = (selectRow % 10 == 0 ? 0 : selectRow);
      this.buildingBlockGridData = [];
      this.buildingBlockGridData = process(this.mainGridData, this.gridSettings_BBGrid.state).data;
      this.selectedItemFromGrid = this.gridData.data[sIndex];
      this.exportData = this.mainGridData;
      this.selectedRowKeys = [];
      this.selectedRowKeys.push(selectRow);
      this.onTabSelect();
    }
    this.requestGridLoading = false;
  }

  public onCheckBoxClick_BB(selectedData: any, event: any) {
    console.log(event);
    var IsValidDataItem: boolean = this.isValidBBSelection(selectedData, true);
    selectedData.isSelected = IsValidDataItem ? !selectedData.isSelected : false;
    this.tabsSelectionFunc(selectedData);
    var index: number = 0;
    var check: boolean = false;
    this.gridData.data.forEach((element: any) => {
      if (element.buildingBlockNumber == selectedData.buildingBlockNumber) {
        check = true;
      }
      index = check ? index : index + 1;
    });
    this.selectedRowKeys = [];
    this.selectedRowKeys.push(index);
  }

  public onCellClick_BBGrid(event: any): void {
    this.clearAll(false);
    let selectedData: any = event.dataItem;
    var IsValidDataItem: boolean = false;
    if (event.type == 'click') {
      if (event.column.title == 'Selected') {
        IsValidDataItem = this.isValidBBSelection(selectedData, true);
        selectedData.isSelected = IsValidDataItem ? !selectedData.isSelected : false;
      }
      else {
        if (event.originalEvent.ctrlKey) {
          IsValidDataItem = this.isValidBBSelection(selectedData, true);
          selectedData.isSelected = IsValidDataItem ? !selectedData.isSelected : false;
        }
        else {
          this.clearBBFromSelectionList();
          IsValidDataItem = this.isValidBBSelection(selectedData, true);
          selectedData.isSelected = IsValidDataItem ? true : false;
        }
      }
    }
    else if (event.type == 'contextmenu') {
      IsValidDataItem = this.isValidBBSelection(selectedData, false);
      selectedData.isSelected = IsValidDataItem ? true : false;

      this.gridContextMenu.items = this.contextMenuOptionsForBB;
    }
    else {
      this.gridContextMenu.items = [];
    }

    this.tabsSelectionFunc(selectedData);

    if (event.type == 'contextmenu') {
      event.originalEvent.preventDefault();
      this.gridContextMenu.show({ left: event.originalEvent.pageX, top: event.originalEvent.pageY });
    }
  }

  public tabsSelectionFunc(selectedData: any) {
    this.addRemoveBBfromSelectionList(selectedData);
    if (this.selectedBuildingBlockList.length == 1) {
      this.selectedItemFromGrid = this.selectedBuildingBlockList[0];
    }
    else {
      this.selectedItemFromGrid = selectedData;
    }
    sessionStorage.setItem("viewBBGridSelectedItem", JSON.stringify(this.selectedItemFromGrid));
    this.handleContextMenu("CreateRecipe", this.isCreateRecipeEnabled);
    this.onTabSelect();

    this.hideIndividualBBOptions = this.selectedBuildingBlockList.length > 1 ? true : false;
    this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;

    if (this.hideIndividualBBOptions) {
      this.contextMenuOptionsForBB[BBEnumerations.ShowPdfBB].disabled = true;
      this.contextMenuOptionsForBB[BBEnumerations.CopyBB].disabled = true;
      this.contextMenuOptionsForBB[BBEnumerations.DeleteBB].disabled = true;
      this.contextMenuOptionsForBB[BBEnumerations.EditBB].disabled = true;
      this.contextMenuOptionsForBB[BBEnumerations.CreateRecipeBB].disabled = false;
    }
  }

  public isValidBBSelection(dataItem: any, showErrMsg: boolean): boolean {
    if (dataItem.isArchived == true ||
      dataItem.isDraft == true ||
      dataItem.isTemplate == true ||
      dataItem.module == '') {
      if (this.selectedBuildingBlockList.length > 0 && showErrMsg) {
        this.displayModalWindowWithError("This Building Block '" + this.selectedItemFromGrid.buildingBlockNumber + "' cannot be used to create a recipe because it is a draft and/or is archived and/or is a template and/or its module is empty. Please check and try again.");
      }
      return false;
    }
    return true;
  }

  public addRemoveBBfromSelectionList(dataItem: any): void {
    if (dataItem.isSelected) {
      this.appendBBFromSelectionList(dataItem);
    }
    else {
      this.removeBBFromSelectionList(dataItem);
    }
    this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;
  }
  public appendBBFromSelectionList(data: any): void {
    if (!this.selectedBuildingBlockList.includes(data)) {
      this.selectedBuildingBlockList.push(data);
    }
  }

  public removeBBFromSelectionList(data: any): void {
    const index = this.selectedBuildingBlockList.indexOf(data, 0);
    if (index > -1) {
      this.selectedBuildingBlockList.splice(index, 1);
      if (this.selectedBuildingBlockList.length == 1) {
        this.selectedItemFromGrid = this.selectedBuildingBlockList[0];
      }
    }
  }

  public clearBBFromSelectionList(): void {
    this.selectedBuildingBlockList = [];
    this.mainGridData.forEach((s: any) => (
      s.isSelected = false
    ));
    this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;
  }

  public onChipClick(event: any): void {
    if (event.length > 0) {
      this.selectedChipIndex = event[0]
    }
    this.selectedItemFromGrid = this.selectedBuildingBlockList[this.selectedChipIndex];
    var index: number = 0;
    var check: boolean = false;
    this.gridData.data.forEach((element: any) => {
      if (element.buildingBlockNumber == this.selectedItemFromGrid.buildingBlockNumber) {
        check = true;
      }
      index = check ? index : index + 1;
    });
    index = (<number>this.gridSettings_BBGrid.state.skip) + index;
    this.selectedRowKeys = [];
    this.selectedRowKeys.push(index);
    this.onTabSelect();
  }

  public onRemoveSelectedBBFromList(e: ChipRemoveEvent): void {
    const index = this.selectedBuildingBlockList.findIndex(bb => bb.buildingBlockNumber == e.sender.label);
    var selectedData: any = this.selectedBuildingBlockList[index];
    this.selectedBuildingBlockList.splice(index, 1);
    this.mainGridData.forEach((s: any) => {
      s.isSelected = (selectedData.id == s.id) ? false : s.isSelected
    });
    this.isCreateRecipeEnabled = this.selectedBuildingBlockList.length > 0 ? true : false;
  }
  
  public btnShowCreateCC(): void{
    //this.openPopupWindow("CreateCC");
    this.openLMPopUp();
  }



  //============ Lab Manager Code =============


  public getLMSites(): void {    
    this.thirdPartyAppGateway.getLMSites(true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let sites: any[] = [];
        let sitesList: any[] = [];
        sitesList = JSON.parse(JSON.stringify(result));
        sitesList.forEach(item => {
          sites.push(item.name);
        });
        this.lmSiteList = sites.filter((n, i) => sites.indexOf(n) === i).sort();
        // console.log("==== Site =====");
        // console.log(this.lmSiteList);
      },
      error: (e) => {       
        alert('Sites Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }

  public getLMActivities(): void {    
    this.thirdPartyAppGateway.getLMActivities(true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let activities: any[] = [];
        let activitiesList: any[] = [];
        activitiesList = JSON.parse(JSON.stringify(result));
        activitiesList.forEach(item => {
          activities.push(item.name);
        });
        
        this.lmActivitiesList = activities.filter((n, i) => activities.indexOf(n) === i).sort();
        // console.log("==== Activities =====");
        // console.log(this.lmActivitiesList);
      },
      error: (e) => {       
        alert('Activities Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }

  public getLMProjects(site: string): void {    
    this.thirdPartyAppGateway.getLMProjects(site,true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let projects: any[] = [];
        //Enable the below codes (Line#: 3869 - 3873) to bind only the project names
        //Disable the line 3875 if decided to bind the project names
        /*let projectList: any[] = [];
        projectList = JSON.parse(JSON.stringify(result));
        projectList.forEach(item => {
          projects.push(item.name);
        });*/
        projects = JSON.parse(JSON.stringify(result));
        this.lmProjectList = projects.filter((n, i) => projects.indexOf(n) === i).sort();
        // console.log("==== Projects =====");
        // console.log(this.lmProjectList);
      },
      error: (e) => {       
        alert('Projects Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }


  public getLMLabs(site: string): void {    
    this.thirdPartyAppGateway.getLMLabs(site,true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let labs: any[] = [];
        let labList: any[] = [];
        labList = JSON.parse(JSON.stringify(result));
        labList.forEach(item => {
          labs.push(item.lab);
        });
        this.lmLabList = labs.filter((n, i) => labs.indexOf(n) === i).sort();
        // console.log("==== Labs =====");
        // console.log(this,this.lmLabList);
      },
      error: (e) => {       
        alert('Lab Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }

  public getLMValidationTeams(site: string): void {    
    this.thirdPartyAppGateway.getLMValidationTeams(site,true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let vtNames: any[] = [];
        let vtNamesList: any[] = [];
        vtNamesList = JSON.parse(JSON.stringify(result));
        vtNamesList.forEach(item => {
          vtNames.push(item.name);
        });
        this.lmVTNamesList = vtNames.filter((n, i) => vtNames.indexOf(n) === i).sort();
        // console.log("==== VTNames =====");
        // console.log(this.lmVTNamesList);
      },
      error: (e) => {       
        alert('Validation Team Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }


  public getLMOwners(site: string): void {    
    this.thirdPartyAppGateway.getLMOwners(site,true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let owners: any[] = [];
        let ownerList: any[] = [];
        ownerList = JSON.parse(JSON.stringify(result));
        ownerList.forEach(item => {
          owners.push(item.fullName);
        });
        this.lmOwnerList = owners.filter((n, i) => owners.indexOf(n) === i).sort();
        // console.log("==== Owners =====");
        // console.log(this.lmOwnerList);
      },
      error: (e) => {       
        //alert('Owner Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }

  public async checkLMHostAvailability(hostName: string): Promise<void>{
    if(hostName!=null && hostName !=''){
      await this.thirdPartyAppGateway.getLMHostDetails(hostName.trim(), true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          console.log("Host Found\n\n");
          this.isLMHostAvailable = true;        

        },
        error: (e) => {
          var name = this.lmHostName;
          this.lmHostName = "";    
          this.isLMHostAvailable = false;     
          alert("Hostname: "+name+" not found. Please check the name and try again.\n\n");
          console.log("Host not found error:" + e.message?.toString());      

        },
        complete: () => {

        }
      });
      }

  }

  public async getLMHostDetails(hostName: string) {  
    if (hostName != null && hostName != '') {
      //await this.delay(2000);

      await this.checkLMHostAvailability(this.lmHostName.trim());
      //this.delay(1000);

      if (this.isLMHostAvailable) {
        this.thirdPartyAppGateway.getLMHostDetails(hostName.trim(), true).pipe(takeUntil(this.destroy$)).subscribe({
          next: (result) => {
            this.lmActiveState = result.activeState;
            this.lmActivity = result.activity;            
            this.lmDescription = result.description;
            this.lmFocus = result.focus;
            this.lmGroup = result.group.trim().length > 0 ? result.group : "iVE";
            this.lmHostName = result.name;
            this.lmLab = result.lab;
            this.lmLocation = result.location;
            this.lmOwner = result.owner;
            this.lmProject = result.project;
            this.lmProjectID = result.projectID;
            //this.lmSite = result.site;
            this.selectedLMSite = result.site;
            this.lmVTName = result.vtName;
            if (result.components.length > 0) {
              this.lmComponents = result.components;
              this.lmBoardSN = this.lmComponents[0]["serialNumber"];
            }
            else {
              this.lmBoardSN = "";
            }
            this.getUserIdsid(this.lmOwner);

          },
          error: (e) => {
            
            //alert('Hostname not found. Please try again with different host.\n\n');
            console.log("Hostname not found:" + e.message?.toString());
           
          },
          complete: () => {
            this.getLMProjects(this.selectedLMSite);
            this.getLMLabs(this.selectedLMSite);
            this.getLMValidationTeams(this.selectedLMSite);
            this.getLMOwners(this.selectedLMSite);

          }
        });
      }
      else {
        //alert('Hostname not found. Please try again with different host.\n\n');
        //console.log("Host not found\n\n");
        //this.lmHostName = "";
      }
    }

    else {
      alert("Please Enter Hostname\n\n");
    }
}

  //DropDownlist Events

  public ddlSiteValueChange(value: any) {
    this.selectedLMSite = value;
    this.lmLab = "";
    this.lmProject = "";
    this.lmVTName = "";
    this.lmOwner = "";
    this.getLMProjects(this.selectedLMSite);
    this.getLMLabs(this.selectedLMSite);
    this.getLMValidationTeams(this.selectedLMSite);
    this.getLMOwners(this.selectedLMSite);
  }

  public ddlProjectValueChange(value: any) {
    this.lmProject = "";
    this.lmProject = value.name;
    this.lmProjectID = value.projectID;
    //console.log(this.lmProject);
    //vconsole.log(this.lmProjectID);
  }

  public ddlLabValueChange(value: any) {
    this.lmLab = "";
    this.lmLab = value;
  }

  public ddlVTeamValueChange(value: any) {
    this.lmVTName = "";
    this.lmVTName = value;
  }

  public ddlOwnerValueChange(value: any) {
    this.lmOwner = "";
    this.lmOwner = value;
    this.getUserIdsid(this.lmOwner);
  }

  public ddlActivityValueChange(value: any) {
    this.lmActivity = "";
    this.lmActivity = value;
  }

  public ddlActiveStateValueChange(value: any) {
    this.lmActiveState = "";
    this.lmActiveState = value;
  }

  public onBulkTicketChecked(): void {
    this.boolBulkTicket = true;
  }

  //TextBox Event

  public onDescChange(value: string): void {
    this.lmDescription = "";
    this.lmDescription = value;
  }

  public onHostNameChange(value: string): void {
    this.lmHostName = "";
    this.lmHostName = value;
    //this.checkLMHostAvailability(this.lmHostName.trim());
  }

  public onGroupChange(value: string): void {
    this.lmGroup = "";
    this.lmGroup = value;
  }

  public onFocusChange(value: string): void {
    this.lmFocus = "";
    this.lmFocus = value;
  }

  public onLocationChange(value: string): void {
    this.lmLocation = "";
    this.lmLocation = value;
  }

  public onBoardSNChange(value: string): void {
    this.lmBoardSN = "";
    this.lmBoardSN = value;
  }

  public async delay(ms: number): Promise<void> {
    await new Promise( resolve => setTimeout(resolve, ms) );
  }


  public setUserWwid(): void {

    if (this.filterGetUsersSubscription){
      this.filterGetUsersSubscription.unsubscribe();
    }

    if (this.userEmail != '') {
      this.filterGetUsersSubscription = this.graphUsersGateway.getUsers(this.userEmail, 1, '', true).subscribe({
        next: (result) => {
          if (result[0].jobTitle != null) {
            this.userWwid = result[0].jobTitle;
          }
        },
        error: (e) => {
          alert('Get User Info Error\n\n' + e.error.toString());
        }
      });
    }
  } 

  public composeRequest(): void{
    this.lmIntegrationRequest.site = this.selectedLMSite;
    this.lmIntegrationRequest.lab = this.lmLab;
    this.lmIntegrationRequest.project = this.lmProject;
    this.lmIntegrationRequest.group = "IVE";
    this.lmIntegrationRequest.hostName = this.lmHostName;
    this.lmIntegrationRequest.description = this.lmDescription;
    this.lmIntegrationRequest.owner = this.lmOwner;
    this.lmIntegrationRequest.activity = this.lmActivity;
    this.lmIntegrationRequest.activeState = this.lmActiveState;
    this.lmIntegrationRequest.validationTeam = this.lmVTName;
    this.lmIntegrationRequest.focus = this.lmFocus;
    this.lmIntegrationRequest.boardSN = this.lmBoardSN;
    this.lmIntegrationRequest.location = this.lmLocation;
    this.lmIntegrationRequest.workq = "";
    this.lmIntegrationRequest.hsdNumber = "";
    this.lmIntegrationRequest.recipeNumber = this.idNumber;
    this.lmIntegrationRequest.recipeName = this.idName;
    this.lmIntegrationRequest.createdBy = new Person();
    this.lmIntegrationRequest.createdBy.name = this.userName;
    this.lmIntegrationRequest.createdBy.email = this.userEmail;
    this.lmIntegrationRequest.createdBy.wwid = this.userWwid;
    this.lmIntegrationRequest.revision = 0;
    this.lmIntegrationRequest.isArchived = false;
    this.lmIntegrationRequest.application = "Recipe Management";
    this.getUserIdsid(this.lmOwner);
    this.createNewIntegrationItem(this.lmIntegrationRequest);
  }

  public clearSessionAndRedirect(): void{
    if (sessionStorage.getItem("LMNewItemID") != null) {
      sessionStorage.removeItem("LMNewItemID");
    }
    if (sessionStorage.getItem("CreatedByWwid") != null) {
      sessionStorage.removeItem("CreatedByWwid");
    }
    this.redirectAction();
  }

  public async SaveAndContinue() {
    try {

      if(this.lmHostName.trim() != ""){
        await this.checkLMHostAvailability(this.lmHostName.trim());        
        await this.delay(3000);
      }
      else{
        this.isLMHostAvailable = true;
      }
      //this.delay(1000);
      if (this.isLMHostAvailable) {
          if (this.checkLMFieldsEmptyOrFilled()) {
            this.composeRequest();
          }
          else {
            this.clearSessionAndRedirect();
          }
        
      }
      else {
        if (confirm("Do you still want to continue to create workq?") == true) {
          if (this.checkLMFieldsEmptyOrFilled()) {
            this.composeRequest();
          }
          else {
            this.clearSessionAndRedirect();
          }
          
        }
        else {          

        }

      }

    } catch (e) {
      var error = e as Error;
      alert("An error occurred while submitting your request: " + error.message.toString());
    }
  
  }

  public checkLMFieldsEmptyOrFilled(): boolean {
    if (this.lmLab.trim().length > 0 || this.lmProject.trim().length > 0 || this.lmHostName.trim().length > 0 || this.lmDescription.trim().length > 0 || this.lmOwner.trim().length > 0 || this.lmActivity.trim().length > 0 || this.lmActiveState.trim().length > 0 || this.lmVTName.trim().length > 0 || this.lmFocus.trim().length > 0 || this.lmBoardSN.trim().length > 0 || this.lmLocation.trim().length > 0){
      return true;
    }
    return false;
  }
 

  public redirectAction(): void{
    // Go/Open GTS tool only if Create a new HSD.
    if(!this.isButtonAssignHSDESToCC){
      this.redirectToGTS2();
    }
    else{
      //this.openPopupWindow("hsdesarticles"); 
      this.closeLMPopUp();
      this.btnOpenHsdEsArticlesPopupWindow();
    }
  }

  public createNewIntegrationItem(item: ToolsIntegration): void {

    if (this.filterCreateToolsIntegrationSubscription){
      this.filterCreateToolsIntegrationSubscription.unsubscribe();
    }
    try
    {
      this.filterCreateToolsIntegrationSubscription = this.createGateway.createToolsIntegration(item).subscribe({
            next: (result) => {
              if(result != null){
                var newItemID = result.id;
                var createdByWwid = result.createdBy.wwid;
                if (sessionStorage.getItem("LMNewItemID") != null) {
                  sessionStorage.removeItem("LMNewItemID");
                }
                if (sessionStorage.getItem("CreatedByWwid") != null) {
                  sessionStorage.removeItem("CreatedByWwid");
                }
                sessionStorage.setItem("LMNewItemID", newItemID);
                sessionStorage.setItem("CreatedByWwid", createdByWwid);
                console.log("Recipe Management: New Integration Data Created Successfully..\n\n");               
                //Redirect to GTS
                this.redirectAction();
              }  
            },
            error: (e) => {
              console.log("Recipe Management: Integration Data Creation Failed\n\n" + e.message.toString());
            }
          })
    }
  catch
   (error)
          {
              var e = error as Error;
              console.log(e.message);
          };
  }

  public getUserConfig(): void {
    // Fetch user's default site from config page to populate Lab/Owner/Project/Station VT values
    var userWWID = sessionStorage.getItem("appSettings.appUserWwid");
    if (userWWID) {
      this.createGateway.getConfiguration(userWWID).subscribe({
          next: (result) => {          
          if (result) {
            this.selectedLMSite = "";
            this.selectedLMSite = result.defaultSite;
            //this.lmDefaultSite = result.defaultSite; //Ken Add this for Reset Button 
            if(sessionStorage.getItem("defaultSite") != null || sessionStorage.getItem("defaultSite") != ""){
              sessionStorage.removeItem("defaultSite");
            }
            sessionStorage.setItem("defaultSite", result.defaultSite);
            //==========LM Integration Method Calls========
            if (this.selectedLMSite != "") {
              this.getLMSites();
              this.getLMActivities();
              this.getLMProjects(this.selectedLMSite);
              this.getLMLabs(this.selectedLMSite);
              this.getLMValidationTeams(this.selectedLMSite);
              this.getLMOwners(this.selectedLMSite);
              //this.getLMHostDetails('GM03WVAW1949');
            }
            else {
              this.getLMSites();
              this.getLMActivities();
            }
            
            //================== END =======================
          }
        },
        error: (e) => {
          //alert('Get Configuration Error\n\n' + e.error?.toString());
          this.getLMSites();
          this.getLMActivities();
        },
        complete: () => {
        }
      
      });
    }
  }

  public async updateToolsIntegrationItem(loadeditem: ToolsIntegration) {
    try {

      let item: ToolsIntegration = new ToolsIntegration();      
      
      item.workq = loadeditem.workq.trim().length > 0 ? loadeditem.workq.trim() : "";
      item.hsdNumber = loadeditem.hsdNumber.trim().length > 0 ? loadeditem.hsdNumber.trim() : "";      
      item.id = loadeditem.id.trim().length > 0 ? loadeditem.id.trim() : "";

      // item.createdBy = new Person();
      // item.createdBy.name = this.userName;
      // item.createdBy.email = this.userEmail;
      // item.createdBy.wwid = this.userWwid;
      
      item.updatedBy = new Person();
      item.updatedBy.name = this.userName;
      item.updatedBy.email = this.userEmail;
      item.updatedBy.wwid = this.userWwid;

      const dataChanges = [        
        {value: item.workq, path: 'workq', op: 'replace'},
        {value: item.hsdNumber, path: 'hsdNumber', op: 'replace'},        
        {value: item.updatedBy, path: 'updatedBy', op: 'replace'},
      ];

      await this.createGateway.updateToolsIntegration(item, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          
        },
        error: (e) => {
          console.log('Recipe Management: Data Update Failed\n\n' + e.error?.toString());
        },
        complete: () => {
          console.log("Recipe Management: Data Updated Successfully\n\n");    

        }
      });
      
    }
    catch{
      console.log("Error occured..\n\n");
    }
    
  }

  public resetLMPopup(): void{
    this.selectedLMSite = "";        
    this.lmOwner = "";
    this.lmLab = ""; 
    this.lmVTName = "";
    this.lmProject = "";
    //this.lmProjectID = "";
    this.lmFocus = "";
    this.lmActivity = "";
    this.lmHostName = "";
    this.lmActiveState = "" ; 
    this.lmBoardSN = "";
    this.lmLocation = "";
    this.lmDescription = "";
    this.boolBulkTicket = false;
  }
  //Ken add this for a reset button 20/3/2024
  public btnResetValueLM():void{
    this.resetLMPopup();
    this.getUserConfig();
  }

  //================== End ====================
}
