import { Person } from "./person";

export class Lab {
  lab:string ="";
  id:string = "";
  createdBy: string = "";
  createdDate: Date|null = null;
  updatedBy: string = "";
  updatedDate: Date|null = null;
  revision: number = 0;
  application: string = "";
  isArchived: boolean|null = null;
}

export class LabInfo {
  name: string ="";
}

export class LabCreate {
  id: string = "";
  lab: string = "";
  createdBy: Person = new Person();
  createdDate: Date = new Date();
  updatedBy: Person = new Person();
  updatedDate: Date = new Date();
  application: string = "";
  revision: number|null = null;
  isArchived: boolean|null = null;
}
