import { Injectable } from '@angular/core';
import { NotificationService } from "@progress/kendo-angular-notification";

@Injectable()
export class RM_NotificationService {
  public notificationMessages: MessagePlus[] = [];
  public lastSeverityClass = 'btn, m-2, btn-info';

  public addErrorNotification(summary: string, detail: string) {
    this.notificationService.show({
      content: summary + ': \n\n' + detail,
      hideAfter: 4000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: 'error', icon: true },
    });
    this.addNotification(summary, detail, 'error');
  }
  public addInfoNotification(summary: string, detail: string) {
    this.notificationService.show({
      content: summary + ': \n\n' + detail,
      hideAfter: 4000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: 'info', icon: true },
    });
    this.addNotification(summary, detail, 'info');
  }

  public addWarningNotification(summary: string, detail: string) {
    this.notificationService.show({
      content: summary + ': \n\n' + detail,
      hideAfter: 4000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: 'warning', icon: true },
    });
    this.addNotification(summary, detail, 'warn');
  }
  public addNotification(summary: string, detail: string, severity: string) {

    const classBase = ['btn', 'm-2'];
    let trueSeverity = 'bg-info';
    switch (severity) {
      case 'info' : {
        trueSeverity ='bg-info';
        break;
      }
      case 'success' : {
        trueSeverity ='bg-success';
        break;
      }
      case 'warn' : {
        trueSeverity ='bg-warning';
        break;
      }
      case 'error' : {
        trueSeverity ='bg-danger';
        break;
      }
      default : {
        severity = 'none';
        break;
      }
    }

    classBase.push(trueSeverity);
    this.notificationMessages.push({isRead: false, severity: trueSeverity, summary: summary, detail: detail});

    this.lastSeverityClass = classBase.join(', ');
  }

  public removeNotification(delMessage: MessagePlus) {
    this.notificationMessages.splice(this.notificationMessages.indexOf(delMessage), 1);
    const classBase = ['btn', 'm-2'];
    if (this.notificationMessages.length > 0) {
      classBase.push(this.notificationMessages[this.notificationMessages.length - 1].severity)
    } else {
      classBase.push('btn-info');
    }
    this.lastSeverityClass = classBase.join(', ');
  }

  constructor(private notificationService: NotificationService) { }
}

export class MessagePlus {
  public isRead = false;
  public severity: any;
  public summary: any;
  public detail: any;
}
