import { DateTimePickerComponent } from "@progress/kendo-angular-dateinputs";
import { StringLiteralLike } from "typescript";
import { BoardType, BoardTypeInfo } from "./board-type";
import { Employee } from "./employee";
import { Lab, LabInfo } from "./lab";
import { Person } from "./person";
import { ParentInfo } from "./recipe";
import { ValidationTeam, ValidationTeamInfo } from "./validation-team";

export class BuildingBlock {
  id = '';
  buildingBlockNumber = '';
  buildingBlockName = '';
  buildingBlockId = '';
  type = '';
  validationTeams: ValidationTeamInfo[] = []; // ValidationTeam[] = [];
  siliconProgram = '';
  description = '';
  products: string[] = [];
  boardTypes: BoardTypeInfo[] = []; // BoardType[] = [];
  rampUp = '';
  module = '';
  owner = new Person; // Employee;
  coOwner: Person[] = []; // Employee;
  checkList: CheckListItem[] = [];
  //  labObj = new Lab();
  labs: LabInfo[] = [];
  comments = '';
  emailDistribution: string[] = [];
  content = '';
  createdBy = new Person; //  '';
  createdDate = new Date();
  updatedBy = new Person; // '';
  updatedDate = new Date();
  revision = 0;
  application = '';
  isArchived = false;
  isTemplate = false;
  isDraft = false;
  parentBuildingBlock = new ParentInfo;
}

export class CheckListItem {
  id = '';
  name = '';
  type = 'CheckList Item'
  isRequired = true;
  isChecked = false;
}
