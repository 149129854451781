import { Person } from "./person";

export class BuildingBlockType {
  id: string = "";
  application: string = "";
  revision: number|null = null;
  buildingBlockTypeName: string = "";
  createdBy: Person = new Person();
  createdDate: Date = new Date();
  updatedBy: Person = new Person();
  updatedDate: Date = new Date();
  isArchived: boolean|null = null;
}
