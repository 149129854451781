import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event as RouterEvent, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { StartupService } from './common/startup-service';
import { filter, timeout, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { appSettings } from './modules/shared/app-settings';
import { EventEmitterService } from './common/event-emitter-service';
import { environment } from 'environments/environment';
import { GraphUsersGateway } from './gateways/graph-users.gateway';
import { CreateGateway } from 'app/gateways/create.gateway';
import { ApiEnvironmentService, Environment } from '@apps-es/api';
import { LoggingService } from './logging/logging.service';

interface Payload extends AuthenticationResult {
  idTokenClaims: {
    tfp?: string
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public title = 'Recipe Management';
  public isIframe = false;
  public userData: string = "";
  public filterSubscription: Subscription = new Subscription();
  public filterGetConfigSubscription: Subscription = new Subscription();
  public hasConfig = false;
  public getConfigResults: any[] = [];

  private readonly _destroying$ = new Subject<void>();
  public currentUrl: string = "";
  public loading = false;

  private readonly applicationInsightsService = inject(LoggingService);

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
               private authService: MsalService, private startup: StartupService,
               private graphService: GraphUsersGateway,
               private eventEmitterService: EventEmitterService,
               private Routes: Router, private route: ActivatedRoute,
               private msalBroadcastService: MsalBroadcastService,
	             public createGateway: CreateGateway,
               private apiEnvironmentService: ApiEnvironmentService,
               private ApplicationInsightsService: LoggingService){

    this.Routes.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    })

    this.isIframe = window !== window.parent && !window.opener;

    if (!this.startup.startupData) {
      alert("Error: RM could not connect to initialization services. Are you connected to the Intel Network?");
      return;
    }
    this.Routes.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        sessionStorage.setItem("currentUrl", this.currentUrl);
      }
    });
  }
  refreshPage():void{
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        this.checkAndSetActiveAccount();

        try
        {
          this.getUserInformationFromAzureAD();
        }
        catch (error)
        {
            var e = error as Error;
            console.log(e.message);
        }
      });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {

      if (result.error instanceof AuthError) {
        console.log(result.error.errorMessage);
        alert(result.error.errorMessage);
      }
      else
      {
        console.log(result);
        alert(result);
      }
      // Adding a sessionStorage to capture error causing Single Sign On not to work.
      sessionStorage.setItem("LoginFailure", JSON.stringify(result));
    });

      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        try
        {
          if (this.authService.instance.getAllAccounts().length <= 0)
          {
              // Checking if Login Failure happened. If it did, avoid redirecting endlessly.

              if (sessionStorage.getItem("LoginFailure") == null)
              {
                  this.authService.loginRedirect();
              }
              else
              {
                  var loginFailureIssue = sessionStorage.getItem("LoginFailure");

                  if (loginFailureIssue != null)
                  {
                      var loginFailureIssueStr = JSON.parse(loginFailureIssue);
                      alert(loginFailureIssueStr.error.errorMessage);
                  }
                  this.eventEmitterService.onUnauthorizedUser();
                  this.Routes.navigate(['./access-denied']);
              }
          }
          else{
              try
              {
                this.getUserInformationFromAzureAD();
              }
              catch (error)
              {
                  var e = error as Error;
                  console.log(e.message);
              }
          }
        } catch(error)
        {
          var e = error as Error;
          console.log(e.message);
        }
      })
  }

  ngOnInit(): void {
    this.eventEmitterService.refreshPageVar = this.eventEmitterService.refreshPageFunction.subscribe((name: string) => {
      this.refreshPage();
    });
    this.refreshPage();

    if (environment.production){
      this.apiEnvironmentService.setEnvironment(Environment.prod);
      console.log("The @apps-es/api environment is PRODUCTION.")
    }
    else{
      this.apiEnvironmentService.setEnvironment(Environment.pre);
      console.log("The @apps-es/api environment is PRE-PRODUCTION.")
    }

    if (environment.test){
      document.getElementById('div_warning')!.style.display = 'block';
    }
    else{
      document.getElementById('div_warning')!.style.display = 'none';
    }
  }


  private setStartupPage( wwid: string): void {

    // 11302022: amr\vssaini updates
    // Part 1: check if the given wwid has a configuration, if exists, route as intended by role
    // Part 2: if no configuration, route to configuration page
    // Part 3: if any error due to missing configuration, read the error and route appropriately
    //         *Due to changing error codes, currently handling the routing if code is 401 & error states "item not found"
    //          ..this will be temporary until all error handling is in place & is consistent
    if (this.filterGetConfigSubscription){
      this.filterGetConfigSubscription.unsubscribe();
    }
   try {
     this.filterGetConfigSubscription =
       this.createGateway.getConfiguration(wwid).subscribe({
         next: (result) => {
          this.getConfigResults = JSON.parse(JSON.stringify(result));
          if(result == null ){
            this.hasConfig == false;
            this.Routes.navigate(['/configuration']);
            appInsights.trackEvent("RM: visited by "+ this.userData);
            appInsights.flush();
            timeout(1000);
          }
          else {
            if((appSettings.isUserAMemberOf("RM_L1_Technicians")))
            {
              this.Routes.navigate(['/work-q']);
              appInsights.trackEvent("RM: visited by "+ this.userData);
              appInsights.flush();
              timeout(1000);
            }
            else
            {
              this.Routes.navigate(['/mode/view']);
              appInsights.trackEvent("RM: visited by "+ this.userData);
              appInsights.flush();
              timeout(1000);
            }
          }

         },
         error: (e) => {
          if (e.status == 404){
            this.Routes.navigate(['/configuration']);
            appInsights.trackEvent("RM: visited by "+ this.userData);
            appInsights.flush();
            timeout(1000);
          }
          else {
            if((appSettings.isUserAMemberOf("RM_L1_Technicians")))
            {
              this.Routes.navigate(['/work-q']);
              appInsights.trackEvent("RM: visited by "+ this.userData);
              appInsights.flush();
              timeout(1000);
            }
            else
            {
              this.Routes.navigate(['/mode/view']);
              appInsights.trackEvent("RM: visited by "+ this.userData);
              appInsights.flush();
              timeout(1000);
            }
          }
     }});
   }
   catch(error)
         {
             var e = error as Error;
             console.log(e.message);
         };
 }

  private getUserInformationFromAzureAD(){

    var activeAccount = this.authService.instance.getAllAccounts().length > 0 ? this.authService.instance.getActiveAccount() : undefined;
    if (activeAccount != undefined){
      var name = activeAccount?.name;
      this.userData = name != undefined ? name : "";

      // Note - need to comment 4 lines below if want to impersonate user
      appSettings.appUser = this.userData.length > 0 ? (this.userData.split(",")[1]).trim() : "";
      appSettings.appUserFullname = this.userData;
      appSettings.appLocalAccountId = activeAccount.localAccountId;
      appSettings.appUserEmailId = activeAccount.username;

      /**user access might change, these are sample data for testing */
      // WH keep impersonate L1
      // appSettings.appUser = 'Mor';
      // appSettings.appUserFullname = 'Mazushan, Mor';
      // appSettings.appLocalAccountId = 'a5f84977-e6d3-4cbe-a2c5-91411e004ccc';
      // appSettings.appUserEmailId = 'mor.mazushan@intel.com';

      // WH keep impersonate L2 first
      // appSettings.appUser = 'Roei';
      // appSettings.appUserFullname = 'Shlagman, Roei';
      // appSettings.appLocalAccountId = 'ca2607c9-b26e-403f-ac0e-4c1acd53b820';
      // appSettings.appUserEmailId = 'roei.shlagman@intel.com';

      // WH keep impersonate L2 second
      // appSettings.appUser = 'Omer';
      // appSettings.appUserFullname = 'Farash, Omer';
      // appSettings.appLocalAccountId = '0dcfb1a5-9841-4096-8e9e-a60278de5adf';
      // appSettings.appUserEmailId = 'omer.farash@intel.com';

      sessionStorage.setItem("appSettings.appUser", appSettings.appUser);
      sessionStorage.setItem("appSettings.appUserFullname", appSettings.appUserFullname);
      sessionStorage.setItem("appSettings.appLocalAccountId", appSettings.appLocalAccountId);
      sessionStorage.setItem("appSettings.appUserEmailId", appSettings.appUserEmailId);

      this.figureOutAGSGroupMemberships(appSettings.appLocalAccountId);
    }
  }

  private getAdditionalDetailsAboutUser(){

    var fullUserName = this.userData == undefined ? sessionStorage.getItem("appSettings.appUserFullname") :  this.userData;

    // WH keep impersonate - disable 3 lines below if not using impersonation
    // fullUserName = 'Mazushan, Mor'; // WH keep impersonate L1 11944657
    // fullUserName = 'Shlagman, Roei'; // WH keep impersonate L2 first 12183534
    // fullUserName = 'Farash, Omer'; // WH keep impersonate L2 second 12118141

    if (fullUserName != undefined){
      var filterGetGraphUserForFilter = new Subscription();
      filterGetGraphUserForFilter = this.graphService.getUsers(fullUserName, 1, '')
        .subscribe(userData => {
          if (userData.length === 1) {
            appSettings.appUserWwid = userData[0].jobTitle;
            sessionStorage.setItem("appSettings.appUserWwid", appSettings.appUserWwid);
            appSettings.appUserObject = userData[0];
            sessionStorage.setItem("appSettings.appUserObject", JSON.stringify(appSettings.appUserObject));
            this.reRouteUser();
          }
        },((error => {
          console.log(error.error);
      })));
    }
  }

  private reRouteUser(){
    this.eventEmitterService.onUserInformationUpdate();
    if ("currentUrl" in sessionStorage)
    {
      var url = sessionStorage.getItem("currentUrl");
      var urlValue = url != undefined ? url : "";

      if (urlValue == '/access-denied' || urlValue == '/off-vpn'){
        this.Routes.navigate(['/']);
      }
    }
    //Note We will only redirect the page when the currentUrl is '/' i.e. when the user tries to access directly from base url
    //We will not redirect if the user explicitly provides page name on the url path
    if(sessionStorage.getItem("currentUrl") =='/')
    {
      var tmp =  sessionStorage.getItem("appSettings.appUserWwid");
      var wwid = '';
      if (tmp != null){
        wwid = tmp.toString();
      }
      this.setStartupPage(wwid);
    }
  }

  // private setStartUpPage()
  // {
  //     //Place holder to add code for first time L2 or Admin users, so that they will land in configuration page

  //     if (!hasConfig){
  //       this.Routes.navigate(['/configuration']);
  //     }
  //     else if((appSettings.isUserAMemberOf("RM_L1_Technicians")))
  //     {
  //       this.Routes.navigate(['/work-q']);
  //     }
  //     else
  //     {
  //       this.Routes.navigate(['/mode/view']);
  //     }
  // }

  private figureOutAGSGroupMemberships(userId: string){

    var rmAccessGroups: string[] = environment.rmAccess;
  //  var rmAccessGroupsStr = JSON.stringify(rmAccessGroups);

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    this.filterSubscription = this.graphService.getGroupNames(rmAccessGroups).subscribe(
      async (success) => {
        if (success == undefined){
          alert ("Error: RM was unable to check groups information!");
          console.log("Error: RM was unable to check groups information!");
        }
        var groupsSingleString = JSON.stringify(success).slice(1,-1);

        if (groupsSingleString.length > 0){
          var groupDetailsArray = groupsSingleString.split(',');

          if (groupDetailsArray.length <= 1){
              var groupInfo = groupsSingleString.split(":");
              var groupName = groupInfo[0];
              var groupId = groupInfo[1];
              var groupIdJson = "[" + groupId + "]";

              const isMemberOf$ = this.graphService.getIsMemberOf(userId, groupIdJson);
              var data = await lastValueFrom(isMemberOf$);

              if (data != undefined)
              {
                var response = JSON.stringify(data);

                if (response.toLowerCase() === "true"){
                  console.log("User" + appSettings.appUser + " is member of " + groupName + " with Id " + groupId + ".");
                  appSettings.addGroupInformation(groupName, groupId);
                }
              }
              if (appSettings.isAuthorizedRMUser() == false){
                console.log("User" + appSettings.appUser + " is NOT a member of any RM group in AGS.");

                this.eventEmitterService.onUnauthorizedUser();
                this.Routes.navigate(['/access-denied']);
              }
              else{
                this.getAdditionalDetailsAboutUser();
              }
          }
          else{
            for (var i = 0; i < groupDetailsArray.length; i++)
            {
              var groupInfo = groupDetailsArray[i].split(":");
              var groupName = groupInfo[0];
              var groupId = groupInfo[1];
              var groupIdJson = "[" + groupId + "]";

              const isMemberOf$ = this.graphService.getIsMemberOf(userId, groupIdJson);
              var data = await lastValueFrom(isMemberOf$);

              if (data != undefined)
              {
                var response = JSON.stringify(data);

                if (response.toLowerCase() === "true"){
                  console.log("User" + appSettings.appUser + " is member of " + groupName + " with Id " + groupId + ".");
                  appSettings.addGroupInformation(groupName, groupId);
                }
              }
            }
            if (appSettings.isAuthorizedRMUser() == false){
              console.log("User" + appSettings.appUser + " is NOT a member of any RM group in AGS.");

              this.eventEmitterService.onUnauthorizedUser();
              this.Routes.navigate(['/access-denied']);
            }
            else{
              this.getAdditionalDetailsAboutUser();
            }
          }
        }
        else
        {
          console.log("User" + appSettings.appUser + " is NOT a member of any RM group in AGS.");

          this.eventEmitterService.onUnauthorizedUser();
          this.Routes.navigate(['/access-denied']);
        }
      },
      err => {
        err as unknown as Error;
        console.log(err.message);
        sessionStorage.setItem("loginError", err.message);

        this.eventEmitterService.onUnauthorizedUser();
        this.Routes.navigate(['/off-vpn']);
      },
      () => {
        console.log("Completed");
      }
    );
  }

  private checkAndSetActiveAccount(){

    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  // If logout is needed, placeholder method is here. Not being used yet...
  public logout(popup?: boolean) {

    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
