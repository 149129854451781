<div class="main-content">
    <p style="color: red;">'{{userName}}': You do not have permission to access the Recipe Management (RM) application.</p>
    <p>Please apply for access through AGS at <a href="http://ags.intel.com">ags.intel.com</a>.</p>
    <p>Choose one of the following roles when searching for the entitlement to apply for:</p>
    <ul>
      <li>"<b>RM_Admins</b>" - This entitlement is for superusers or administrators for the Recipe Management system. This entitlement grants access to Recipe Management.</li>
      <li>"<b>RM_L1_Technicians</b>" - Allows users to perform L1 technician activities in Recipe Management. This entitlement grants access to Recipe Management.</li>
      <li>"<b>RM_L2_Technicians</b>" - Allows users to perform L2 technician activities in Recipe Management. This entitlement grants access to Recipe Management.</li>
      <li>"<b>RM_Lab_Customers </b>" - This entitlement allows lab customers to perform activities in Recipe Management. This entitlement grants access to Recipe Management.</li>
    </ul>
    <br/><br/>
  </div>
  