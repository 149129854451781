<nav class="navbar navbar-expand-xl navbar-dark bg-classic-blue">
  <div class="toolbar container-fluid">
    <!-- 		 -->
    <div class="navbar-brand">
      <!--  <a href="http://www.intel.com" target="blank" aria-current="page">
        <svg width="80%" height="80%" viewBox="0 0 350 350" id="Layer_1" data-bs-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.4 26.52">
          <defs>
            <style>
              .cls-1 {
                fill: #00c7fd;
              }

              .cls-2 {
                fill: #fff;
              }
            </style>
          </defs>
          <rect class="cls-1" y="0.36" width="4.96" height="4.96" />
          <path class="cls-2"
            d="M6.63,30.9V13.06H1.93V30.9Zm31.15.18V26.71a14.33,14.33,0,0,1-1.7-.11A2,2,0,0,1,35,26.11,1.94,1.94,0,0,1,34.49,25a10.16,10.16,0,0,1-.11-1.72V17.08h3.4v-4h-3.4V6.11h-4.7V23.35A15.29,15.29,0,0,0,30.05,27a4.69,4.69,0,0,0,3.55,3.66,13.86,13.86,0,0,0,3.58.39Zm26.93-.18V4.74H60V30.9ZM25.15,14.82a7.13,7.13,0,0,0-5.47-2.12,6.83,6.83,0,0,0-3.08.69,6.55,6.55,0,0,0-2.34,1.9l-.25.33V13.06H9.37V30.9H14V22.05c0-.1,0-.21,0-.32A5.36,5.36,0,0,1,15.29,18a4,4,0,0,1,3-1.26A3.8,3.8,0,0,1,21.35,18a5.26,5.26,0,0,1,1,3.42h0v0h0V30.9h4.74V20.77A8.49,8.49,0,0,0,25.15,14.82Zm32.41,7.12a9.53,9.53,0,0,0-.68-3.6A9.09,9.09,0,0,0,55,15.39a8.69,8.69,0,0,0-2.9-2,9.7,9.7,0,0,0-3.74-.71,9,9,0,0,0-3.62.73,9.29,9.29,0,0,0-4.93,4.93A9.15,9.15,0,0,0,39.07,22a9.49,9.49,0,0,0,.7,3.62,8.83,8.83,0,0,0,4.91,4.93,9.92,9.92,0,0,0,3.82.73,10.12,10.12,0,0,0,7.93-3.5l-3.38-2.58a6.18,6.18,0,0,1-4.52,2,5.36,5.36,0,0,1-3.24-.92,4.43,4.43,0,0,1-1.69-2.47l-.05-.17h14Zm-14-1.63c0-1.31,1.5-3.59,4.73-3.59S53.05,19,53.05,20.3Z"
            transform="translate(-1.8 -4.74)" />
          <path class="cls-2"
            d="M70.06,28.65a1.72,1.72,0,0,0-.37-.55,1.93,1.93,0,0,0-.55-.38,1.84,1.84,0,0,0-1.36,0,1.93,1.93,0,0,0-.55.38,1.9,1.9,0,0,0-.37.55,1.72,1.72,0,0,0-.14.68,1.67,1.67,0,0,0,.14.67,1.77,1.77,0,0,0,.37.55,1.75,1.75,0,0,0,.55.38,1.72,1.72,0,0,0,1.36,0,1.75,1.75,0,0,0,.55-.38,1.62,1.62,0,0,0,.37-.55,1.67,1.67,0,0,0,.14-.67A1.72,1.72,0,0,0,70.06,28.65Zm-.28,1.23a1.49,1.49,0,0,1-.31.46,1.37,1.37,0,0,1-.45.3,1.37,1.37,0,0,1-1.12,0,1.37,1.37,0,0,1-.45-.3,1.49,1.49,0,0,1-.31-.46,1.46,1.46,0,0,1,0-1.11,1.49,1.49,0,0,1,.31-.46,1.37,1.37,0,0,1,.45-.3A1.37,1.37,0,0,1,69,28a1.37,1.37,0,0,1,.45.3,1.49,1.49,0,0,1,.31.46,1.46,1.46,0,0,1,0,1.11Zm-1-.41a.6.6,0,0,0,.34-.16.53.53,0,0,0,.13-.39.61.61,0,0,0-.17-.45.78.78,0,0,0-.53-.15h-.77v2h.37v-.82h.26l.5.82h.38Zm-.2-.29h-.43v-.56h.43l.15,0a.19.19,0,0,1,.11.08.25.25,0,0,1,.05.16.25.25,0,0,1-.05.15.19.19,0,0,1-.11.08Z"
            transform="translate(-1.8 -4.74)" />
        </svg>
      </a> -->
      <a class="p-mr-1" [routerLink]="['/']" aria-current="page">
        <img class="p-d-flex-inline" src="../assets/Images/rm_logo.png"
          style="position:absolute; top:10px; z-index: 50;" height="80px">
      </a>
    </div>
    <div class="collapse navbar-collapse ">
      <h2 style="color: white; z-index: 100; margin-left: 50px;">Recipe Management</h2>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="true"
            id="navbarViewDropdownMenuLink">
            <i></i> View
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarViewDropdownMenuLink">
            <li><a class="dropdown-item" routerLink="bb/view">Building Block</a></li>
            <li><a class="dropdown-item" routerLink="recipe/view">Recipe</a></li>
            <li><a class="dropdown-item" routerLink="bb-template/view" [hidden]="!isUserAdmin()">Building Block
                Template</a></li>
            <li><a class="dropdown-item" routerLink="recipe-template/view" [hidden]="!isUserAdmin()">Recipe Template</a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="true"
            id="navbarCreateDropdownMenuLink" [hidden]="!isUserL2andAdmin()">
            Create
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarCreateDropdownMenuLink">
            <li><a class="dropdown-item" routerLink="bb/create">Building Block</a></li>
            <li><a class="dropdown-item" routerLink="recipe/create">Recipe</a></li>
            <li><a class="dropdown-item" routerLink="bb-template/create" [hidden]="!isUserAdmin()">Building Block
                Template</a></li>
            <li><a class="dropdown-item" routerLink="recipe-template/create" [hidden]="!isUserAdmin()">Recipe
                Template</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link text-nowrap" routerLink="work-q" [hidden]="!isUserLoggedIn()" aria-current="page">
            <i></i> Work-Q
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-nowrap" routerLink="administration" [hidden]="!isUserAdmin()" aria-current="page">
            <i></i> Administration
          </a>
        </li>

        <!-- <li class="nav-item">
          <a class="nav-link text-nowrap" routerLink="dashboard" [hidden]="!isUserAdmin()" aria-current="page">
            <i></i> Dashboard
          </a>
        </li> -->

        <!-- Rajesh added Support Menu on 13 Sep'23 -->
        <!-- <li class="nav-item">
          <a class="nav-link text-nowrap"
            href="https://intel.sharepoint.com/sites/materialmanagement/SitePages/Recipe-Management-Help.aspx"
            target="blank" aria-current="page">
            <i class="intelicon-help-solid"></i> Help
          </a>
        </li> -->

        <!-- Rajesh added Support Menu on 13 Sep'23 -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="true"
            id="navbarSupportDropdownMenuLink">
            Support
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarSupportDropdownMenuLink">
            <li><a class="dropdown-item"
                href="{{RmHelpUrl}}"
                target="blank" aria-current="page">
                Help
              </a></li>
            <li><a class="dropdown-item"
                href="{{RmGtsUrl}}"
                target="blank" aria-current="page"> Submit Ticket</a></li>
            <li><a class="dropdown-item"
                href="{{RmTicketsUrl}}" target="blank"
                aria-current="page"> Track Ticket(s)</a></li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link text-nowrap" role="button" data-bs-toggle="dropdown" aria-expanded="true"
            id="navbarViewDropdownMenuLink">
            <i class="intelicon-settings-solid"></i> Settings
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarViewDropdownMenuLink">
            <li><a class="dropdown-item" routerLink="configuration">Configuration Profile</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <!--  <div class="nav-item dropdown" style="margin-left: 10px; margin-right: 10px;" [hidden]="!isUserLoggedIn()">
      <ul class="navbar-nav me-auto">
        <li class="nav-item dropdown">
          <a class="nav-link text-nowrap" role="button" data-bs-toggle="dropdown" aria-expanded="true"
            id="navbarViewDropdownMenuLink">
            <i class="intelicon-settings-solid"></i> Settings
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarViewDropdownMenuLink">
            <li><a class="dropdown-item" routerLink="configuration">Configuration Profile</a></li>
          </ul>
        </li>
      </ul>
    </div> -->
    <div style="margin-left: 10px;">
      <kendo-badge-container>
        <img [src]="imageToShow" alt={{userName}} title="Welcome to RM: {{userName}}!"
          onerror="this.onerror=null;this.src='assets/Images/GenericUser.png';" [hidden]="isImageLoading"
          style="height:60px; padding:5px,15px,5px,15px" />
        <ng-template #noImageFound hidden="!isImageLoading">
          <img src="assets/Images/GenericUser.png" alt="User" style="height:60px; padding:5px,15px,5px,15px" />
        </ng-template>

        <kendo-badge *ngIf="this.currentRoles['Administrator']" [size]="'small'"
          [align]=" { vertical: 'top', horizontal: 'start' }" themeColor="warning" rounded="full"
          title="Administrator">A</kendo-badge>
        <kendo-badge *ngIf="this.currentRoles['L1 Technician']" [size]="'small'"
          [align]=" { vertical: 'bottom', horizontal: 'start' }" themeColor="primary" rounded="full"
          title="L1 Technician">L1</kendo-badge>
        <kendo-badge *ngIf="this.currentRoles['L2 Technician']" [size]="'small'"
          [align]=" { vertical: 'bottom', horizontal: 'end' }" themeColor="secondary" rounded="full"
          title="L2 Technician">L2</kendo-badge>
        <kendo-badge *ngIf="this.currentRoles['Lab Customer']" [size]="'small'"
          [align]=" { vertical: 'top', horizontal: 'end' }" themeColor="tertiary" rounded="full"
          title="Lab Customer">LC</kendo-badge>
      </kendo-badge-container>
    </div>
    <div style="white-space: nowrap;">
      <i>
        <font face="Intel Clear" font-style="normal" style="color: white; padding:5px,15px,5px,15px"> Welcome,
          {{userName}}!</font>
      </i>
    </div>
  </div>
</nav>