import { Injectable } from '@angular/core';
import { throwError as observableThrowError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { RM_NotificationService } from './notificationService';
@Injectable()
export class HttpErrorHandler {
  constructor(public notificationService: RM_NotificationService) { }

  public getHttpError(errorNumber: number): string {

    let retVal = '';
    switch (errorNumber) {
      case 200: { retVal = ' OK'; break; }
      case 201: { retVal = ' Created'; break; }
      case 202: { retVal = ' Accepted'; break; }
      case 203: { retVal = ' Non-Authoritative Information (since HTTP/1.1)'; break; }
      case 204: { retVal = ' No Content'; break; }
      case 205: { retVal = ' Reset Content'; break; }
      case 206: { retVal = ' Partial Content'; break; }
      case 207: { retVal = ' Multi-Status (WebDAV; RFC 4918)'; break; }
      case 208: { retVal = ' Already Reported (WebDAV; RFC 5842)'; break; }
      case 226: { retVal = ' IM Used (RFC 3229)'; break; }
      case 300: { retVal = ' Multiple Choices'; break; }
      case 301: { retVal = ' Moved Permanently'; break; }
      case 302: { retVal = ' Found (Previously "Moved temporarily")'; break; }
      case 303: { retVal = ' See Other (since HTTP/1.1)'; break; }
      case 304: { retVal = ' Not Modified'; break; }
      case 305: { retVal = ' Use Proxy (since HTTP/1.1)'; break; }
      case 306: { retVal = ' Switch Proxy'; break; }
      case 307: { retVal = ' Temporary Redirect (since HTTP/1.1)'; break; }
      case 308: { retVal = ' Permanent Redirect'; break; }
      case 400: { retVal = ' Bad Request'; break; }
      case 401: { retVal = ' Unauthorized'; break; }
      case 402: { retVal = ' Payment Required'; break; }
      case 403: { retVal = ' Forbidden'; break; }
      case 404: { retVal = ' Not Found'; break; }
      case 405: { retVal = ' Method Not Allowed'; break; }
      case 406: { retVal = ' Not Acceptable'; break; }
      case 407: { retVal = ' Proxy Authentication Required'; break; }
      case 408: { retVal = ' Request Timeout'; break; }
      case 409: { retVal = ' Conflict'; break; }
      case 410: { retVal = ' Gone'; break; }
      case 411: { retVal = ' Length Required'; break; }
      case 412: { retVal = ' Precondition Failed'; break; }
      case 413: { retVal = ' Payload Too Large'; break; }
      case 414: { retVal = ' URI Too Long'; break; }
      case 415: { retVal = ' Unsupported Media Type'; break; }
      case 416: { retVal = ' Range Not Satisfiable'; break; }
      case 417: { retVal = ' Expectation Failed'; break; }
      case 418: { retVal = ' I\'m a teapot (RFC 2324, RFC 7168)'; break; }
      case 421: { retVal = ' Misdirected Request'; break; }
      case 422: { retVal = ' Unprocessable Entity'; break; }
      case 423: { retVal = ' Locked (WebDAV; RFC 4918)'; break; }
      case 424: { retVal = ' Failed Dependency (WebDAV; RFC 4918)'; break; }
      case 425: { retVal = ' Too Early (RFC 8470)'; break; }
      case 428: { retVal = ' Precondition Required (RFC 6585)'; break; }
      case 429: { retVal = ' Too Many Requests (RFC 6585)'; break; }
      case 431: { retVal = ' Request Header Fields Too Large (RFC 6585)'; break; }
      case 451: { retVal = ' Unavailable For Legal Reasons (RFC 7725)'; break; }
      case 500: { retVal = ' Internal Server Error'; break; }
      case 501: { retVal = ' Not Implemented'; break; }
      case 502: { retVal = ' Bad Gateway'; break; }
      case 503: { retVal = ' Service Unavailable'; break; }
      case 504: { retVal = ' Gateway Timeout'; break; }
      case 505: { retVal = ' HTTP Version Not Supported'; break; }
      case 506: { retVal = ' Variant Also Negotiates (RFC 2295)'; break; }
      case 507: { retVal = ' Insufficient Storage (WebDAV; RFC 4918)'; break; }
      case 508: { retVal = 'Loop Detected (WebDAV; RFC 5842)'; break; }
      case 510: { retVal = ' Not Extended (RFC 2774)'; break; }
      case 511: { retVal = ' Network Authentication Required (RFC 6585)'; break; }
      default: {
        retVal = `Unknown Error ${errorNumber}`
      }
    }
    return retVal;
  }

  public handleError(error: HttpErrorResponse | any) {

    const httpError: ErrorInfo = new ErrorInfo();
    let errMsg: string;
    let err: string;
    const errField = 'errors';
    const msgField = 'message';
    // console.log(error);
    if (error instanceof HttpErrorResponse) {
      // const err = error.error !== null ? error.error.message : error.message;
      if (error.error !== null) {
        if (error.status === 400) {
          err = JSON.stringify(error);
        } else if (error.status === 404) {
          err = 'Item not found: ' + JSON.stringify(error);
        } else if (error.status === 500) {
          err = 'Internal Server Error';
        } else {
          err = error.message;
        }
      } else {
        err = error.message;
      }
      httpError.statusCode = error.status;
      httpError.statusText = error.statusText;
      httpError.message = err;
//      console.log(httpError);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      this.notificationService.addErrorNotification(`${error.status} - ${error.statusText || ''}`, errMsg);
    } else {
      errMsg = error.error !== null ? error.error.message : error.message;
      httpError.message = errMsg;
    }
    return throwError(() => httpError);
  }
}
export class ErrorInfo {
  statusCode: number | undefined;
  statusText: string | undefined;
  message: string | undefined;
}
