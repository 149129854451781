import { LabInfo } from "./lab";
import { Person } from "./person";

export class ValidationTeam {
    id: string = "";
    application: string = "";
    revision: number|null = null;
    validationTeamName: string = "";
    labs: LabInfo[] = [];
    createdBy: Person = new Person();
    createdDate: Date = new Date();
    updatedBy: Person = new Person();
    updatedDate: Date = new Date();
}

export class ValidationTeamInfo {
    name: string = "";
}
