import { GraphUsers } from './../modules/shared/model/graph-users';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, timeout } from 'rxjs/operators';
import { EnvironmentPlugin } from 'webpack';
import { HttpErrorHandler } from 'app/common/errorHandler';
import { ErrorHandlerService } from 'app/errorHandling/error-handling.service';
import { RM_NotificationService } from 'app/common/notificationService';

@Injectable({
  providedIn: 'root'
})

export class GraphUsersGateway {
  private graphUsersUrl = environment.services.GraphUsersUrl;
  private imageMetadataUrl = environment.services.ImageMetadataUrl;

  constructor(private _http: HttpClient, public httpErrorHandler: HttpErrorHandler, public notificationService: RM_NotificationService) {
  }
  getUsers(searchValue: string, pageSize: number, skipToken: string, supressNotification = false): Observable<GraphUsers[]> {

    return this._http.get<GraphUsers[]>(`${this.graphUsersUrl}api/users/${searchValue}?${pageSize}`)
      .pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            if (error.status === 404) {
              this.notificationService.addWarningNotification(`getUsers(${searchValue}): ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            } else {
              this.notificationService.addErrorNotification(`getUsers(${searchValue}): ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
          }
          console.log('Caught in getUsers. Returning 0');
          return throwError(() => error);
        })
      );
  }
  getGroupNames(groupNames: string[], supressNotification = false): Observable<string[]> {

    return this._http.post<string[]>(`${this.graphUsersUrl}api/users/groupnames/`, groupNames, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'charset': 'utf-8' }) })
      .pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {

          if (!supressNotification) {
            if (error.status === 404) {
              this.notificationService.addWarningNotification(`getGroupNames: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            } else {
              this.notificationService.addErrorNotification(`getGroupNames: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            }
          }
          console.log('Caught in getGroupNames. Returning 0');
          return throwError(() => error);
        })
      );
  }
  getGroupMembers(groupNames: string[], supressNotification = false): Observable<GraphUsers[]> {
    return this._http.post<GraphUsers[]>(`${this.graphUsersUrl}api/users/groupmembers/`, groupNames, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'charset': 'utf-8' }) })
      .pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {

          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getGroupMembers: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in getGroupMembers. Returning 0');
          return throwError(() => error);
        })
      );

  }
  getMemberOf(userId: string, supressNotification = false): Observable<string[]> {

    return this._http.get<string[]>(`${this.graphUsersUrl}api/users/id/${userId}/getmemberof`).pipe(
      retry(3),
      timeout(60000),
      // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
      map((data) => data),
      catchError(error => {

        if (!supressNotification) {
          this.notificationService.addErrorNotification(`getGroupMembers: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
        }
        console.log('Caught in getGroupMembers. Returning 0');
        return throwError(() => error);
      })
    );
  }
  getAppRegistrationName(appId: string, supressNotification = false): Observable<string[]> {

    return this._http.get<string[]>(`${this.graphUsersUrl}api/users/appregistrationname/${appId}`).pipe(
      retry(3),
      timeout(60000),
      // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
      map((data) => data),
      catchError(error => {

        if (!supressNotification) {
          this.notificationService.addErrorNotification(`getGroupMembers: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
        }
        console.log('Caught in getGroupMembers. Returning 0');
        return throwError(() => error);
      })
    );
  }
  getIsMemberOf(userId: string, body: string, supressNotification = false): Observable<string[]> {

    return this._http.post<string[]>(`${this.graphUsersUrl}api/users/id/${userId}/ismemberof`, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'charset': 'utf-8' }) }).pipe(
      retry(3),
      timeout(60000),
      // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
      map((data) => data),
      catchError(error => {

        if (!supressNotification) {
          this.notificationService.addErrorNotification(`getIsMemberOf: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
        }
        console.log('Caught in getIsMemberOf. Returning 0');
        return throwError(() => error);
      })
    );
  }
  getUserImageMetadata(supressNotification = false): Observable<any> {
    return this._http.get(this.imageMetadataUrl, {
      responseType: 'text',
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    })
      .pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {

          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getUserImageMetadata: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in getUserImageMetadata. Returning 0');
          return throwError(() => error);
        })
      )
  }
  getUserImage(supressNotification = false): Observable<Blob> {
    return this._http.get(this.imageMetadataUrl + "$value", {
      responseType: 'blob',
      headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
    })
      .pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getUserImage: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          console.log('Caught in getUserImage. Returning 0');
          return throwError(() => error);
        })
      )
  }
}
