import { CreateGateway } from 'app/gateways/create.gateway';
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { SearchGateway } from 'app/gateways/search.gateway';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { lastValueFrom, Observable } from "rxjs";
import { AppSettings, appSettings } from "../shared/app-settings";

@Injectable({
  providedIn: 'root'
})
export class CreateRequestResolverService  {

  constructor(public searchGateway: SearchGateway, public graphUsersGateway: GraphUsersGateway, public createGateway: CreateGateway) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any>> {

    let lists: any = {};

    const vt$ = this.searchGateway.getValidationTeams(false);
    const vtList = lastValueFrom(vt$);
    lists['rmValidationTeams'] = (await vtList).map((formItem: any) => ({ name: formItem.validationTeamName })); //, isArchived: !formItem.isArchived ? false : true

    const ll$ = this.searchGateway.getLabs(true);
    const llList = lastValueFrom(ll$);
    lists['rmLabList'] = (await llList).map((formItem: any) => { return { name: formItem.lab.toUpperCase() } }); //, isArchived: !formItem.isArchived ? false : true
    const bt$ = this.searchGateway.getBoardTypes(false);
    const btList = lastValueFrom(bt$);
    lists['rmBoardTypes'] = (await btList).map((formItem: any) => { return { name: formItem.boardTypeName } }); //, isArchived: !formItem.isArchived ? false : true

    const m$ = this.searchGateway.getBuildingBlockModules(false);
    const mList = lastValueFrom(m$);
    lists['rmModules'] = (await mList).map((formItem: any) => { return { name: formItem.buildingBlockModuleName } }); //, isArchived: !formItem.isArchived ? false : true

    const rmActiveModulesResult$ = this.searchGateway.getModulesFromBuildingBlocks({ isTemplate: false, isDraft: false, isArchive: false });
    const rmActiveModulesFinal = await lastValueFrom(rmActiveModulesResult$);
    lists['rmActiveModules'] = rmActiveModulesFinal.map((module) => ({ name: module, selected: false }));

    const p$ = this.searchGateway.getProducts(false);
    const pList = await lastValueFrom(p$);
    lists['masterProductsList'] = pList;
    lists['rmSiliconPrograms'] = [... new Set(pList.map(item => (item.siliconProgramName)))].sort((a, b) => (a < b ? -1 : 1));

    const bbt$ = this.searchGateway.getBuildingBlockTypes(false);
    const bbtList = lastValueFrom(bbt$);
    lists['rmTypes'] = (await bbtList).map((formItem: any) => { return { name: formItem.buildingBlockTypeName } }); //, isArchived: !formItem.isArchived ? false : true

    const u$ = this.graphUsersGateway.getGroupMembers(['RM_Admins', 'RM_L2_Technicians']);
    const uList = await lastValueFrom(u$);
    for (const u of uList) {
      u.accountEnabled = 'True';
    }
    lists['rmOwners'] = uList;

    const userEmail = sessionStorage.getItem('appSettings.appUserEmailId');
    if (userEmail) {
      const userData$ = this.graphUsersGateway.getUsers(userEmail, 10, '', true);
      const userData = await lastValueFrom(userData$);
      if (userData.length === 1) {
        // this.uploadHeaders = this.fileGateway.getHeaders().subscribe(headers => {
        //   this.uploadHeaders = headers;
        // });
        const currentUser = userData[0];
        let configData = {};

        if (currentUser) {
          try {
            const configData$ = this.createGateway.getConfiguration(currentUser.jobTitle);
            configData = await lastValueFrom(configData$);
          } catch {}

          lists['configData'] = configData;
        }
      }
    }
    return lists;
  }
}
