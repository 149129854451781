import { Component, Input, Output, EventEmitter, AfterViewInit} from "@angular/core";
import { distinct, filterBy, FilterDescriptor} from "@progress/kendo-data-query";
import { FilterService } from "@progress/kendo-angular-grid";

@Component({
  selector: "multicheck-filter",
  template: `
    <ul>
      <li *ngIf="showFilter">
        <input class="k-textbox" (input)="onInput($event)" />
      </li>
      <li #itemElementSelectAll (click)="onSelectAll()" [ngClass]="{ 'k-state-selected': isSelectAll }">
        <input type="checkbox" id="chk-select-all" (focus)="onFocus(itemElementSelectAll)" 
          [checked]="isSelectAll" />
        <label class="k-label lbl" for="chk-select-all">
          Select All
        </label>
      </li>
      <li #itemElement *ngFor="let item of currentData; let i = index"
        (click)="onSelectionChange(valueAccessor(item), itemElement)" [ngClass]="{ 'k-state-selected': isItemSelected(item) }">
        <input type="checkbox" id="chk-{{ valueAccessor(item) }}" (focus)="onFocus(itemElement)" 
          [checked]="isItemSelected(item)" />
        <label class="k-label lbl" for="chk-{{ valueAccessor(item) }}">
          {{ textAccessor(item) }}
        </label>
      </li>
    </ul>
  `,
  styles: [
    `
      ul {
        list-style-type: none;
        height: 200px;
        overflow-y: scroll;
        padding-left: 0;
        padding-right: 12px;
      }

      ul > li {
        padding: 8px 12px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-bottom: none;
        width:250px;
      }

      ul > li:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }

      .k-multiselect-checkbox {
        pointer-events: none;
        font-size: small;
      }

      .lbl {
        margin-left: 5px;
      }
    `,
  ],
})

export class MultiCheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean | undefined;
  @Input() public currentFilter: any;
  @Input() public data: unknown[] = [];
  @Input() public textField: unknown[] = [];
  @Input() public valueField: unknown[] = [];
  @Input() public filterService: any = undefined;
  @Input() public field: string = "";
  @Output() public valueChange = new EventEmitter<number[]>();

  private value: any[] = [];
  public currentData: any;
  public showFilter = true;
  public isSelectAll: boolean = false;
  public isShowEmpty: boolean = false;

  public textAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : dataItem[0];

  public valueAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : dataItem[0];

  public ngAfterViewInit() {
    this.currentData = this.data;
    this.value = this.currentFilter.filters.map(
      (f: FilterDescriptor) => f.value
    );

    this.showFilter =
      typeof this.textAccessor(this.currentData[0]) === "string";
  }

  public isItemSelected(item: any) {
    return this.value.some((x) => x === this.valueAccessor(item));
  }

  public onSelectionChange(item: any, li: any) {
    if (this.value.some((x) => x === item)) {
      this.value = this.value.filter((x) => x !== item);
    }
    else {
      this.value.push(item);
    }
    this.applyFilter();
    this.onFocus(li);
  }

  public onInput(e: any) {
    this.currentData = distinct(
      [
        ...this.currentData.filter((dataItem: any) =>
          this.value.some((val) => val === this.valueAccessor(dataItem))
        ),
        ...filterBy(this.data, {
          operator: "contains",
          //field: this.textField,
          value: e.target.value,
        }),
      ],
      //this.textField
    );
  }

  public onFocus(li: any): void {
    const ul = li.parentNode;
    const below =
      ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;

    // Scroll to focused checkbox
    if (below || above) {
      ul.scrollTop = li.offsetTop;
    }
  }

  public onSelectAll(): void {
    this.isSelectAll = !this.isSelectAll;
    if (this.isSelectAll == true) {
      for (let i = 0; i < this.currentData.length; i++) {
        if (this.value.some((x) => x === this.currentData[i]) == false) {
          this.value.push(this.currentData[i]);
        }
      }
    }
    else {
      this.value = [];
      this.isSelectAll = false;
    }
    this.applyFilter();
  }

  private applyFilter(): void {
    this.filterService.filter({
      filters: this.value.map((value) => ({
        field: this.field,
        operator: value == null ? "isnull" : "eq",
        value,
      })),
      logic: "or",
    });
  }
}
