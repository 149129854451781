import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'app/common/event-emitter-service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  public userName: string | null = "";
  constructor(private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.userName = sessionStorage.getItem("appSettings.appUser") != null ? sessionStorage.getItem("appSettings.appUser") : "Guest";
    this.eventEmitterService.onRefreshPage();
  }
}
