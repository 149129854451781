import { Lab, LabInfo } from "./lab";
import { Person } from "./person";
import { ProductInfo } from "./product";
import { ValidationTeam, ValidationTeamInfo } from "./validation-team";

export class Config {
  id: string = "";
  configurationNumber: number = 0;
  configurationName: string = "";
  profileName: string | null = "" ;
  profileId:string = "";
  labs: LabInfo[] = [];
  validationTeams:  ValidationTeamInfo[] = [];
  products: ProductInfo[] = [];
  defaultSite: string = "";
  application: string = "";
  createdBy: Person = new Person();
  revision: string = "";
  updatedBy: Person = new Person();
}
