import { MsalService } from '@azure/msal-angular';
import { GraphUsers } from './model/graph-users';

export class AppSettings {
    private static instance: AppSettings;
    private static _rmSite: string;
    private static _appUser: string;
    private static _appUserFullname: string;
    private static _appUserId: string;
    private static _appUserWwid: string;
    private static _appUserRMGroups: Map<string,string>;
    private static _appUserEmailId: string;
    private static _appLocalAccountId: string;
    private static _appUserObject: GraphUsers;

    private constructor(){}
    public static getInstance(): AppSettings{
        if (!AppSettings.instance) {
            AppSettings.instance = new AppSettings();
        }
        this._appUserRMGroups = new Map<string,string>();
        return AppSettings.instance;
    }
    public set rmSite(siteName: string){
        AppSettings._rmSite = siteName;
    }
    public get rmSite(){
        return AppSettings._rmSite;
    }
    public set appLocalAccountId(accountId: string){
        AppSettings._appLocalAccountId = accountId;
    }
    public get appLocalAccountId(){
        return AppSettings._appLocalAccountId;
    }
    public set appUser(user: string){
        AppSettings._appUser = user;
    }
    public get appUser(){
        return AppSettings._appUser;
    }
    public set appUserObject(userObj: GraphUsers){
        AppSettings._appUserObject = userObj;
    }
    public get appUserObject(){
        return AppSettings._appUserObject;
    }
    public get appUserFullname(){
        return AppSettings._appUserFullname;
    }
    public set appUserFullname(userFullname: string){
        AppSettings._appUserFullname = userFullname;
    }
    public set appUserEmailId(userEmailId: string){
        AppSettings._appUserEmailId = userEmailId;
    }
    public get appUserEmailId(){
        return AppSettings._appUserEmailId;
    }

    public set appUserId(userId: string){
        AppSettings._appUserId = userId;
    }
    public get appUserId(){
        return AppSettings._appUserId;
    }
    public set appUserWwid(wwId: string){
        AppSettings._appUserWwid = wwId;
    }
    public get appUserWwid(){
        return AppSettings._appUserWwid;
    }
    addGroupInformation(groupName: string, groupId: string){
        var groupNameCaps = groupName.toUpperCase();
        if (!AppSettings._appUserRMGroups.has(groupNameCaps)){
            AppSettings._appUserRMGroups.set(groupNameCaps,groupId);
        }
    }
    isUserAMemberOf(groupName: string): boolean{
        groupName = groupName.includes("\"") == false ? "\"" + groupName.toUpperCase() + "\"" : groupName.toUpperCase();
        return AppSettings._appUserRMGroups == undefined ? false : AppSettings._appUserRMGroups.has(groupName);
    }
    isAuthorizedRMUser(): boolean {
        if (AppSettings._appUserRMGroups == undefined)
        {
            return false;
        }
        else
        {
            if (AppSettings._appUserRMGroups.size > 0)
            {
                return true;
            }
            else
            {
                return false;
            }
        }
    }
}
export const appSettings = AppSettings.getInstance();
