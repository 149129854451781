import { Person } from './person';

export class ToolsIntegration {
    id: string = "";
    site: string = "";
    lab: string = "";
    project: string = "";
    group: string = "";
    hostName: string = "";
    description: string = "";
    owner: string = "";
    activity: string = "";
    activeState: string = "";
    
    validationTeam: string = "";
    focus: string = "";
    boardSN: string = "";
    location: string = "";
    workq: string = "";

    hsdNumber: string = "";
    recipeNumber: string = "";
    recipeName: string = "";
    createdBy = new Person;
    createdDate = new Date();
    updatedBy = new Person;
    updatedDate = new Date();
    revision = 0;
    isArchived = false;
    application: string = "";
  }