export class Site {
    labs: string[] = [];
    id:string = "";
    name: string = "";
    dnsSuffix: string[] = [];
  }
  
  export class Activities {    
    name: string = "";
  }
  
  export class Lab {
    site: string = "";
    lab: string = "";
  }

  export class Project {
    name: string = "";
    sitesList: string[] = [];
    lastModifiedBy: string = "";
    lastModifiedDate = new Date();     
    projectID: string = "";    
  }

  export class ValidationTeams {
    admins: string[] = [];
    id: string = "";
    name: string = "";
    site: string = "";   
    v: number|null = null;    
  }

  export class Owner {
    wwid: string = "";
    fullName: string = "";
    idsid: string = "";
    email: string = "";     
    defaultSite: string = "";    
  }
  

  export class Host {
    activeState: string = "";
    status: string = "";
    name: string = "";
    lab: string = "";
    location: string = "";
    site: string = "";
    group: string = "";
    owner: string = "";
    project: string = "";
    projectID: string = "";
    vtName: string = "";
    vtOwner: string = "";
    description: string = "";
    activity: string = "";
    focus: string = "";
    lastModifiedBy: string = "";      
    components: string[] = [];    
  }

  export class UpdateHost {
    ActiveState: string = "";
    Activity: string = "";
    Description: string = "";
    Focus: string = "";
    Owner: string = "";
    Project: string = "";
    ProjectID: string = "";
    VTName: string = "";
    RecipeID: string = "";
    RecipeURL: string = "";
    LastModifiedBy: string = "";    
  }