import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, from, Observable, throwError } from 'rxjs';
import { map, mergeMap, retry, tap, timeout } from 'rxjs/operators';
import { BuildingBlock } from '../modules/shared/model/building-block';
import { Employee } from '../modules/shared/model/employee';
import { Site } from '../modules/shared/model/site';
import { WorkQ } from '../modules/shared/model/work-q';
import { ValidationTeam } from '../modules/shared/model/validation-team';
import { BoardType } from '../modules/shared/model/board-type';
import { environment } from 'environments/environment';
import { Recipe } from 'app/modules/shared/model/recipe';
import { GraphUsers } from 'app/modules/shared/model/graph-users';
import { GraphUsersGateway } from './graph-users.gateway';
import { Config } from 'app/modules/shared/model/config';
import { HttpErrorHandler } from 'app/common/errorHandler';
import { Lab } from 'app/modules/shared/model/lab';
import { BuildingBlockModule } from 'app/modules/shared/model/building-block-module';
import { BuildingBlockType } from 'app/modules/shared/model/building-block-type';
import { Product } from 'app/modules/shared/model/product';
import { ConfigurationManager } from '@microsoft/applicationinsights-common';
import { ErrorHandlerService } from 'app/errorHandling/error-handling.service';
import { RM_NotificationService } from 'app/common/notificationService';
import { AccessToken } from 'app/modules/shared/model/access-token';
import { AuthenticationHeader } from 'app/common/authentication-header';

@Injectable({
  providedIn: 'root'
})

export class SearchGateway extends AuthenticationHeader {
  private baseUrl = environment.services.environmentPrefix;
  private searchUrl = environment.services.SearchUrl;
  // private baseUrl = 'https://localhost:44305/';
  // private searchUrl = 'api';

  constructor(private _http: HttpClient, private graphService: GraphUsersGateway, public httpErrorHandler: HttpErrorHandler, public notificationService: RM_NotificationService) {
    super(_http);
  }

  getIdsid(wwid: string, supressNotification: boolean = true): Observable<string[]> {
    return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/wwid/${wwid}`)
      .pipe(
        retry(1),
        timeout(60000),
        map((data) => data),
        // tap(data => console.log('getIdsid: ' + data )),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getIdsid: ${error.name}`, error.message);
          }
          console.log('Caught in getIdsid. Returning 0');
          return throwError(() => error);
        })
      );
  }

  getRecipesWithBB(buildingBlockId: string, supressNotification: boolean = true): Observable<Recipe[]> {

    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Recipe[]>(`${this.baseUrl}${this.searchUrl}/recipes/search/withspecificbb?buildingBlockId=${buildingBlockId}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getRecipes(parameters: any, supressNotification = false): Observable<Recipe[]> {

    var searchRecipeUrl = `${this.baseUrl}${this.searchUrl}/recipes/search?`;
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      let searchParams: string[] = [];
      Object.keys(parameters).forEach((key: string) => {
        if (parameters[key] != undefined && parameters[key].toString().toLowerCase() != 'all') {
          searchParams.push(`${key}=${encodeURIComponent(parameters[key])}`);
        }
      });
      return this._http.get<Recipe[]>(searchRecipeUrl + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getAllRecipeTemplatesForAllLabs(): Observable<Recipe[]> {
    return this.getRecipes({ isTemplate: true });
  }

  getAllRecipeTemplatesForSpecificLab(lab: string): Observable<Recipe[]> {
    return this.getRecipes({ lab: lab, sTemplate: true });
  }

  getAllRecipeDraftsForAllLabs(): Observable<Recipe[]> {
    return this.getRecipes({ isDraft: true });
  }

  getAllRecipeDraftsForSpecificLab(lab: string): Observable<Recipe[]> {
    return this.getRecipes({ lab: lab, isDraft: true });
  }

  getAllArchivedRecipesForAllLabs(): Observable<Recipe[]> {
    return this.getRecipes({ isArchived: true });
  }

  getAllArchivedRecipesForSpecificLab(lab: string): Observable<Recipe[]> {
    return this.getRecipes({ lab: lab, isArchived: true });
  }

  getLabsFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=labs`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data.map((x: string) => x.toUpperCase())),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getLabsFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getLabsFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBoardTypesFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=boardtypes`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBoardTypesFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getBoardTypesFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getProductsFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=products`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getProductsFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getProductsFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));

  }

  getOwnersFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=owners`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getOwnersFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getOwnersFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getCoOwnersFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=coowners`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getCoOwnersFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getCoOwnersFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getValidationTeamsFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=validationteams`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getValidationTeamsFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getValidationTeamsFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getSiliconProgramsFromRecipes(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/recipes/search/keyword?value=siliconprograms`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getSiliconProgramsFromRecipes: ${error.name}`, error.message);
            }
            console.log('Caught in getSiliconProgramsFromRecipes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBuildingBlock(lab: string, buildingBlockNumber: string, buildingBlockId: string, supressNotification = false): Observable<BuildingBlock> {

    if (buildingBlockNumber == "") {
      return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
        this.header = result;
        this.header = this.header.set('Content-Type', 'application/json');
        this.header = this.header.set('charset', 'utf-8');

        return this._http.get<BuildingBlock>(`${this.baseUrl}${this.searchUrl}/buildingblocks/search?buildingBlockId=${buildingBlockId}`, { headers: this.header })
          .pipe(
            retry(3),
            timeout(60000),
            // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
            map((data) => data),
            catchError(error => {
              if (!supressNotification) {
                this.notificationService.addErrorNotification(`getBuildingBlock by number: ${error.name}`, error.message);
              }
              console.log('Caught in getBuildingBlock by number. Returning 0');
              return throwError(() => error);
            })
          );
      }));
    }
    if (buildingBlockId == "") {
      return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
        this.header = result;
        this.header = this.header.set('Content-Type', 'application/json');
        this.header = this.header.set('charset', 'utf-8');

        return this._http.get<BuildingBlock>(`${this.baseUrl}${this.searchUrl}/buildingblocks/search?buildingBlockNumber=${buildingBlockNumber}`, { headers: this.header })
          .pipe(
            retry(3),
            timeout(60000),
            // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
            map((data) => data),
            catchError(error => {
              if (!supressNotification) {
                this.notificationService.addErrorNotification(`getBuildingBlock by id: ${error.name}`, error.message);
              }
              console.log('Caught in getBuildingBlock by id. Returning 0');
              return throwError(() => error);
            })
          );
      }));
    }
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');

      return this._http.get<BuildingBlock>(`${this.baseUrl}${this.searchUrl}/buildingblocks/search?buildingBlockNumber=${buildingBlockNumber}&id=${buildingBlockId}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlock by id and number: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlock by id and number. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBuildingBlockRevHistory(buildingBlockId: string, supressNotification = false): Observable<BuildingBlock[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<BuildingBlock[]>(`${this.baseUrl}${this.searchUrl}/buildingblocks/search/history?buildingBlockId=${buildingBlockId}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlockRevHistory: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlockRevHistory. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getRecipeRevHistory(recipeId: string, supressNotification = false): Observable<Recipe[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Recipe[]>(`${this.baseUrl}${this.searchUrl}/recipes/search/history?recipeId=${recipeId}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getRecipeRevHistory: ${error.name}`, error.message);
            }
            console.log('Caught in getRecipeRevHistory. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBuildingBlocks(parameters: any, supressNotification = false): Observable<BuildingBlock[]> {

    var searchBuildingBlockUrl = `${this.baseUrl}${this.searchUrl}/buildingblocks/search?`;
    let searchParams: string[] = [];
    Object.keys(parameters).forEach((key: string) => {
      if (parameters[key] != undefined && parameters[key].toString().toLowerCase() != 'all') {
        searchParams.push(`${key}=${encodeURIComponent(parameters[key])}`);
      }
    });
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<BuildingBlock[]>(searchBuildingBlockUrl + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBuildingBlockDetailsForBBList(buildingBlockIds: string[], supressNotification = false): Observable<BuildingBlock[]> {

    var buildingBlockIdsWithPipe = encodeURIComponent(buildingBlockIds.join('|')); // We use pipes to separate multiple building block ids.
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<BuildingBlock[]>(`${this.baseUrl}${this.searchUrl}/buildingblocks/search?buildingBlockId=` + buildingBlockIdsWithPipe, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlockDetailsForBBList: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlockDetailsForBBList. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getWorkQ(parameters: any, supressNotification = false): Observable<WorkQ[]> {

    var searchBuildingBlockUrl = `${this.baseUrl}${this.searchUrl}/workq/search?`;
    let searchParams: string[] = [];
    Object.keys(parameters).forEach((key: string) => {
      if (parameters[key] !== undefined && parameters[key].toLowerCase() != 'all') {
        searchParams.push(`${key}=${encodeURIComponent(parameters[key])}`);
      }
    });
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<WorkQ[]>(searchBuildingBlockUrl + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getWorkQ: ${error.name}`, error.message);
            }
            console.log('Caught in getWorkQ. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getAllBuildingBlockTemplatesForAllLabs(): Observable<BuildingBlock[]> {

    return this.getBuildingBlocks({ isTemplate: true }); // uses new more streamlined getBuildingBlock function - mamorrow
  }

  getAllBuildingBlockTemplatesForSpecificLab(lab: string): Observable<BuildingBlock[]> {

    return this.getBuildingBlocks({ lab: lab, isTemplate: undefined }); // uses new more streamlined getBuildingBlock function - mamorrow
  }

  getAllBuildingBlockDraftsForAllLabs(): Observable<BuildingBlock[]> {

    return this.getBuildingBlocks({ isDraft: true });
  }

  getAllBuildingBlockDraftsForSpecificLab(lab: string): Observable<BuildingBlock[]> {

    return this.getBuildingBlocks({ lab: lab, isDraft: true });
  }

  getAllArchivedBuildingBlocksForAllLabs(): Observable<BuildingBlock[]> {

    return this.getBuildingBlocks({ isActive: true });
  }

  getAllArchivedBuildingBlocksForSpecificLab(lab: string): Observable<BuildingBlock[]> {

    return this.getBuildingBlocks({ lab: lab, isArchive: true });
  }

  addFiltersToUrl(parameters?: any): string[] {

    let searchParams: string[] = [];
    if (parameters != undefined) {
      Object.keys(parameters).forEach((key: string) => {
        if (parameters[key] !== undefined) {
          searchParams.push(`${key}=${encodeURIComponent(parameters[key])}`);
        }
      });
    }
    return searchParams;
  }

  getLabsFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);

    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=labs`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data.map((x: string) => x.toUpperCase())),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getLabsFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getLabsFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getModulesFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=modules`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getModulesFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getModulesFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBoardTypesFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=boardtypes`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBoardTypesFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getBoardTypesFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getProductsFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=products`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getProductsFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getProductsFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getOwnersFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=owners`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getOwnersFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getOwnersFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getCoOwnersFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=coowners`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getCoOwnersFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getCoOwnersFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }
  getConfigurationByOwner(owner: Employee, supressNotification = true): Observable<Config> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Config>(`${this.baseUrl}${this.searchUrl}/configurations/?owner=${owner}`).pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getConfigurationByOwner: ${error.name}`, error.message);
          }
          console.log('Caught in getConfigurationByOwner. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  getConfiguration(configurationNumber: string, configurationId: string, supressNotification = true): Observable<Config> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Config>(`${this.baseUrl}${this.searchUrl}/configurations/?configurationNumber=${configurationNumber}&id=${configurationId}`)
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getConfiguration: ${error.name}`, error.message);
            }
            console.log('Caught in getConfiguration. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getValidationTeamsFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=validationteams`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getValidationTeamsFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getValidationTeamsFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getSiliconProgramsFromBuildingBlocks(parameters?: any, supressNotification = false): Observable<string[]> {

    let searchParams: string[] = this.addFiltersToUrl(parameters);
    var url = `${this.baseUrl}${this.searchUrl}/buildingblocks/search/keyword?value=siliconprograms`;
    url += searchParams.length > 0 ? "&" : "";
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(url + searchParams.join('&'), { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getSiliconProgramsFromBuildingBlocks: ${error.name}`, error.message);
            }
            console.log('Caught in getSiliconProgramsFromBuildingBlocks. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }


  getValTeamsForTelerikMultiSelectTreeControl(): Observable<any> {

    var valTeams: { text: string, id: number, items: any[] } = {
      text: '',
      id: 0,
      items: []
    };
    valTeams.text = "Validation Teams";
    valTeams.id = 1;
    return this.getValidationTeams().pipe(map((result => {
      var i = 1;
      result.forEach(element => {
        valTeams.items.push({ "text": element.validationTeamName, "id": ++i });
      })
      return valTeams;
    })));
  }

  getProductsForTelerikMultiSelectTreeControl(): Observable<any> {

    var Products: { text: string, id: number, items: any[] } = {
      text: '',
      id: 0,
      items: []
    };
    Products.text = "Products";
    Products.id = 1;
    return this.getProducts().pipe(map((result => {
      var i = 1;
      result.forEach(element => {
        Products.items.push({ "text": element.productName, "id": ++i });
      })
      return Products;
    })));
  }

  getSites(supressNotification = false): Observable<Site[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Site[]>(`${this.baseUrl}${this.searchUrl}/lab/search`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getSites: ${error.name}`, error.message);
            }
            console.log('Caught in getSites. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getLabs(supressNotification = false): Observable<Lab[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Lab[]>(`${this.baseUrl}${this.searchUrl}/lab/search`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getLabs: ${error.name}`, error.message);
            }
            console.log('Caught in getLabs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getLabsForTelerikMultiSelectTreeControl(): Observable<any> {
    var Labs: { text: string, id: number, items: any[] } = {
      text: '',
      id: 0,
      items: []
    };

    Labs.text = "Sites";
    Labs.id = 1;
    return this.getLabs().pipe(map((result => {
      var i = 1;
      result.forEach(element => {
        Labs.items.push({ "text": element.lab.toUpperCase(), "id": ++i });
      })
      return Labs;
    })));
  }

  getOwners(labs: Lab): Observable<GraphUsers[]> {

    return this.graphService.getUsers("all", 998, "");
  }

  getCoowners(labs: Lab): Observable<GraphUsers[]> {

    return this.graphService.getUsers("all", 998, "");
  }

  getValidationTeams(includeArchived?: boolean, supressNotification = false): Observable<ValidationTeam[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<ValidationTeam[]>(`${this.baseUrl}${this.searchUrl}/validationteam/search?includeArchived=${!includeArchived ? false : true}`, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getValidationTeams: ${error.name}`, error.message);
          }
          console.log('Caught in getValidationTeams. Returning 0');
          return throwError(() => error);
        })
      );
    }));

  }

  getBoardTypes(includeArchived?: boolean, supressNotification = false): Observable<BoardType[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<BoardType[]>(`${this.baseUrl}${this.searchUrl}/boardtype/search?includeArchived=${!includeArchived ? false : true}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBoardTypes: ${error.name}`, error.message);
            }
            console.log('Caught in getBoardTypes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getWorkQCases(lab: string, supressNotification = false): Observable<WorkQ[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<WorkQ[]>(`${this.baseUrl}${this.searchUrl}/workQCases/`, { headers: this.header }).pipe(
        retry(3),
        timeout(60000),
        // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getWorkQCases: ${error.name}`, error.message);
          }
          console.log('Caught in getWorkQCases. Returning 0');
          return throwError(() => error);
        })
      );
    }));
  }

  getWorkQSearch(lab: string, workqNumber: string, hsdId: string, mode: string, category: string, recipeNumber: string, hsdOwnerName: string, fromCreatedDate: string, toCreatedDate: string, completed: boolean = false, supressNotification = false): Observable<WorkQ[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      const url = `${this.baseUrl}${this.searchUrl}/workq/search?lab=${lab}&workqNumber=${workqNumber}&hsdId=${hsdId}&mode=${mode}&category=${category}&recipeNumber=${recipeNumber}&hsdOwnerName=${hsdOwnerName}&fromCreatedDate=${encodeURIComponent(fromCreatedDate)}&toCreatedDate=${encodeURIComponent(toCreatedDate)}&completed=${completed}`;
      return this._http.get<WorkQ[]>(url, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getWorkQSearch: ${error.name}`, error.message);
            }
            console.log('Caught in getWorkQSearch. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getWorkQHsdEsId( hsdId: string, supressNotification = false): Observable<WorkQ[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      const url = `${this.baseUrl}${this.searchUrl}/workq/search?hsdId=${hsdId}`;
      return this._http.get<WorkQ[]>(url, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getWorkQSearch: ${error.name}`, error.message);
            }
            console.log('Caught in getWorkQSearch. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  //Ken add this to get single updated row records 
  
  getWorkQRefreshSingleRow(workqNumber: string, supressNotification = false): Observable<WorkQ[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      const url = `${this.baseUrl}${this.searchUrl}/workq/search?workqNumber=${workqNumber}`;
      return this._http.get<WorkQ[]>(url, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getWorkQSearch: ${error.name}`, error.message);
            }
            console.log('Caught in getWorkQSearch. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getLabsFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=labs`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data.map((x: string) => x.toUpperCase())),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getLabsFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getLabsFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBoardTypesFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=boardtypes`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBoardTypesFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getBoardTypesFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getProductsFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=products`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getProductsFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getProductsFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getHsdOwnersFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=hsdowners`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getHsdOwnersFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getHsdOwnersFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getCoOwnersFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=coowners`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getCoOwnersFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getCoOwnersFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getValidationTeamsFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=validationteams`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getValidationTeamsFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getValidationTeamsFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getSiliconProgramsFromWorkQs(supressNotification = false): Observable<string[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<string[]>(`${this.baseUrl}${this.searchUrl}/workq/search/keyword?value=siliconprograms`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getSiliconProgramsFromWorkQs: ${error.name}`, error.message);
            }
            console.log('Caught in getSiliconProgramsFromWorkQs. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBuildingBlockModules(includeArchived?: boolean, supressNotification = false): Observable<BuildingBlockModule[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');

      return this._http.get<BuildingBlockModule[]>(`${this.baseUrl}${this.searchUrl}/buildingblockmodule/search?includeArchived=${!includeArchived ? false : true}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlockModules: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlockModules. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getBuildingBlockTypes(includeArchived?: boolean, supressNotification = false): Observable<BuildingBlockType[]> {

    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<BuildingBlockType[]>(`${this.baseUrl}${this.searchUrl}/buildingblocktype/search?includeArchived=${!includeArchived ? false : true}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getBuildingBlockTypes: ${error.name}`, error.message);
            }
            console.log('Caught in getBuildingBlockTypes. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }

  getProducts(includeArchived?: boolean, supressNotification = false): Observable<Product[]> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      this.header = this.header.set('Content-Type', 'application/json');
      this.header = this.header.set('charset', 'utf-8');
      return this._http.get<Product[]>(`${this.baseUrl}${this.searchUrl}/product/search?includeArchived=${!includeArchived ? false : true}`, { headers: this.header })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            if (!supressNotification) {
              this.notificationService.addErrorNotification(`getProducts: ${error.name}`, error.message);
            }
            console.log('Caught in getProducts. Returning 0');
            return throwError(() => error);
          })
        );
    }));
  }
}
