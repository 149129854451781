import { Component, OnInit, Injector, OnDestroy, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { EventEmitterService } from 'app/common/event-emitter-service';
import { SearchGateway } from 'app/gateways/search.gateway';
import { CreateGateway } from 'app/gateways/create.gateway';
import { Site } from 'app/modules/shared/model/site';
import { Subscription, timeout, takeUntil, Subject } from 'rxjs';
import { Lab, LabInfo } from '../shared/model/lab';
import { Config } from '../shared/model/config';
import { stringify } from 'querystring';
import { Person } from '../shared/model/person';
import { ProductInfo } from '../shared/model/product';
import { ValidationTeamInfo } from '../shared/model/validation-team';
import { Employee } from '../shared/model/employee';
import { config } from 'process';
import { Observable, of } from "rxjs";
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { NodeClickEvent } from "@progress/kendo-angular-treeview";
import { appSettings } from '../shared/app-settings';
import { MultiSelectTreeCheckableSettings } from '@progress/kendo-angular-dropdowns';
import {AppInsightsService, ApplicationInsightsModule} from "@markpieszak/ng-application-insights";
import { LoggingService } from 'app/logging/logging.service';
import {AppModule} from 'app/app.module';
import { ThirdPartyAppGateway } from 'app/gateways/third-party-app.gateway';

@Component({
  //selector: 'app-navigation-bar',
  templateUrl: './configuration.component.html',
  styleUrls: ['../layout-module/components/navigation-bar/navigation-bar.component.scss']
})
export class ConfigurationComponent implements OnInit {
  public rmSiteList: Site[] = [];
  public rmSite: Site = new Site();
  public enableSiteSelection: Boolean = true;
  private filterGetProductSubscription: Subscription = new Subscription;
  private filterGetValTeamSubscription: Subscription = new Subscription;
  private filterGetLabSubscription: Subscription = new Subscription;
  private filterGetUsersSubscription: Subscription = new Subscription;
  private filterCreateConfigSubscription: Subscription = new Subscription;
  private filterUpdateConfigSubscription: Subscription = new Subscription;
  private filterGetConfigSubscription: Subscription = new Subscription;
  private filterFillConfigSubscription: Subscription = new Subscription;
  public isImageLoading: boolean = false;
  public imageToShow: any;
  public userName: string = '';
  public userNameName?: string ;
  public userEmail: string = '';
  public lab: any[] = [];
  public valTeam: any[] = [];
  public prod: any[] = [];
  public employ: Employee = new Employee;
  public selectedLabs: LabInfo[] = [];
  public selectedValTeams: ValidationTeamInfo[] = [];
  public selectedProducts: ProductInfo[] = [];
  public userWwid: string = '';
  public getConfigResults: any[] = [];
  public configExists = false;
  public currentConfigId: string = '';
  public configRequest = new Config();
  public returnedConfig: any[] = [];
  public dataItemsProducts: any[] = [];
  public dataItemsLabs: any[] = [];
  public dataItemsValTeams: any[] = [];
  public copyDataItemsValTeams: any[] = [];
  public copyDataItemsProducts: any[] = [];
  public copyDataItemsLabs: any[] = [];
  public disableSubmit: Boolean = false;
  public submitDialogOpened = false;
  public alertDialogOpen = true;

  public destroy$: Subject<boolean> = new Subject<boolean>();
  public selectedHomeSite: string = "";
  public lmSiteList: any[] = [];
 

  currentRole = '';
  currentRoles: any = {};

  public firstTimeUserConfigNotice: boolean = false;


  constructor(private domSanitizer: DomSanitizer, private graphService: GraphUsersGateway, private msalService:MsalService,
    private eventEmitterService: EventEmitterService,  private graphUsersGateway: GraphUsersGateway, public thirdPartyAppGateway: ThirdPartyAppGateway,
    public searchGateway: SearchGateway, public createGateway: CreateGateway) {
      var SiteList = localStorage.getItem("RmSiteList");
      this.rmSiteList = SiteList != null ? JSON.parse(SiteList) : [];
      this.getProfilePicture();
      this.refreshRoles();
      if (localStorage.getItem("SelectedSite") != null)
      {
        var tmpSite =  localStorage.getItem("SelectedSite");
        this.rmSite = tmpSite != null ?  JSON.parse(tmpSite) : this.rmSiteList[0];
      }
      else
      {
        this.rmSite = this.rmSiteList[0];
      }

      this.eventEmitterService.refreshUserInformationVar = this.eventEmitterService.refreshUserInformationFunction.subscribe((name: string) => {
        this.getProfilePicture();
        this.refreshRoles();
    });

      this.fillLab();
      this.fillValTeam();
      this.fillProduct();
      this.setUserWwid();
      this.fillConfig();
      this.getLMSites();
      console.log(this.currentConfigId);


  }

  ngOnInit(): void {
    this.eventEmitterService.refreshUserInformationVar = this.eventEmitterService.refreshUserInformationFunction.subscribe((name: string) => {
        this.getProfilePicture();
        this.fillLab();
        this.fillValTeam();
        this.fillProduct();
        this.setUserWwid();
        this.fillConfig();
        this.refreshRoles();
        this.getLMSites();
        // appInsights.trackEvent("RM: CONFIG PAGE "+ this.userName);
        // // {User: this.userName}
        // appInsights.flush();
        // timeout(1000);
    });

    // Get current user's details.
    var account = this.msalService.instance.getActiveAccount();
    if (account != undefined) {
      var accountName = account.name;
      if (accountName != undefined) {
        this.userName = accountName;
        this.userEmail = account.username;
        this.setUserWwid();
      }
    }
}

public submitDialogClose() {
  this.submitDialogOpened = false;
}

public alertDialogClose() {
  this.alertDialogOpen = false;
}

public alertDialogAction()
{
    this.alertDialogOpen = false;
}

public btnCancelRequestClick(): void
{
    this.submitDialogOpened = true;
}

public submitDialogAction(status: string) {

  if(status =='exit')
    {
      this.submitDialogOpened = false;
      this.alertDialogOpen = false;
      //this.Routes.navigate(['/view-request']);
    }
    else
    {
      this.alertDialogOpen = false;
      this.submitDialogOpened = false;
      //this.Routes.navigate(['/view-request']);
    }
  }

  public lstMrsSiteSelection(): void {
      localStorage.setItem("SelectedSite", JSON.stringify(this.rmSite));
      alert("You have changed site selection. You are now on '" + this.rmSite.lab + "' site.");
  }
  public getProfilePicture(): void
  {
      this.isImageLoading = true;
      this.graphService.getUserImageMetadata(true).subscribe((result: any) => {
        if (result != null || result != 0)
        {
          var resultStr = result as string;
          if (resultStr.includes("metadata#users"))
          {
            this.graphService.getUserImage().subscribe(
              (blob: Blob) => {
                try{
                  this.isImageLoading = false;
                  var urlCreator = window.URL || window.webkitURL;
                  this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
                }
                catch(error)
                {
                  var e = error as Error;
                  console.log(e.message);
                }
              },
              (error: Error) => {
                this.isImageLoading = false;
                console.log(error.message);
              }
            );
          }
        }
        this.isImageLoading = false;
      },(error: Error) => {
          this.isImageLoading = false;
          console.log(error.message);
        }
      )
  }
  public isUserLoggedIn():boolean
  {
      if (this.userName != null){
        return true;
      }
      return false;
  }


 public fillLab(): void {
    if (this.filterGetLabSubscription){
      this.filterGetLabSubscription.unsubscribe();
    }
    this.filterGetLabSubscription = this.searchGateway.getLabsForTelerikMultiSelectTreeControl().subscribe({
      next: (result) => {
        this.lab = [result];
        return this.lab;
    },

    error: (e) => {
      alert('Search Error\n\n' + e.error.toString());
    }
  })
}

public fillValTeam(): void {
  if (this.filterGetValTeamSubscription){
    this.filterGetValTeamSubscription.unsubscribe();
  }
  this.filterGetValTeamSubscription = this.searchGateway.getValTeamsForTelerikMultiSelectTreeControl().subscribe({
    next: (result) => {
      this.valTeam = [result];
      return this.valTeam;
  },
  error: (e) => {
    alert('Search Error\n\n' + e.error.toString());
  }
})
}

public fillProduct(): void {
  if (this.filterGetProductSubscription){
    this.filterGetProductSubscription.unsubscribe();
  }
  this.filterGetProductSubscription = this.searchGateway.getProductsForTelerikMultiSelectTreeControl().subscribe({
    next: (result) => {
      this.prod = [result];
      //this.createConfig(result);
      return this.prod;
  },
  error: (e) => {
    alert('Search Error\n\n' + e.error.toString());
  }
})
}

public checkableSettings: MultiSelectTreeCheckableSettings = {
  checkChildren: false,
  checkOnClick: false,
};

public fillConfig(): void {

        if (this.filterFillConfigSubscription){
          this.filterFillConfigSubscription.unsubscribe();
        }

      var tmp =  sessionStorage.getItem("appSettings.appUserWwid");
      var wwid = '';
      if (tmp != null){
        wwid = tmp.toString();
      }

      try {
        this.filterFillConfigSubscription =
          this.createGateway.getConfiguration(wwid).subscribe({
            next: (result) => {
              if (result != null ) {
                this.returnedConfig = [result];
                this.getConfigResults = JSON.parse(JSON.stringify(result));

                  this.configExists = true;
                  this.currentConfigId = result.id;
                  this.configRequest.id = this.currentConfigId;
                  sessionStorage.removeItem("configNumber");
                  sessionStorage.setItem("configNumber", JSON.stringify(this.configRequest.id));
                  const js = JSON.stringify(this.returnedConfig);

                  this.dataItemsProducts = result.products;
                  this.dataItemsLabs = result.labs;
                  this.dataItemsValTeams = result.validationTeams;
                  this.selectedHomeSite = result.defaultSite;

                  this.copyDataItemsValTeams = [...result.validationTeams];
                  this.copyDataItemsProducts = [...result.products];
                  this.copyDataItemsLabs = [...result.labs];
                  //Author: Rajesh
                  //Date  : 17 July 2023
                  //Purpose: The selected dropdown values not updating properly when changes made and update to DB
                  //=======================================================
                  this.selectedLabs = [];
                  this.selectedValTeams = [];
                  this.selectedProducts=[];
                  
                  this.copyDataItemsLabs.forEach ((obj: any) =>{
                    this.selectedLabs.push(obj.name.toUpperCase());
                  })

                  this.copyDataItemsValTeams.forEach ((obj: any) =>{
                    this.selectedValTeams.push(obj.name);
                  })

                  this.copyDataItemsProducts.forEach ((obj: any) =>{
                    this.selectedProducts.push(obj.name);
                  })
                  //======================= End ============================
				  

                  result.products.forEach ((element: { name: any; }) => {
                    this.dataItemsProducts.push( { "text":element.name});
                    })
                  result.labs.forEach ((element: { name: any; }) => {
                  this.dataItemsLabs.push( { "text":element.name.toUpperCase()});
                  })
                  result.validationTeams.forEach ((element: { name: any; }) => {
                  this.dataItemsValTeams.push( { "text":element.name});
                  })

                  let arrProdLen = this.dataItemsProducts.length/2;
                  let arrLabLen = this.dataItemsLabs.length/2;
                  let arrValTeamLen = this.dataItemsValTeams.length/2;

                  for(let i = 0; i < arrProdLen; i++ ){
                    this.dataItemsProducts.shift();
                  }
                  for(let i = 0; i < arrLabLen; i++ ){
                    this.dataItemsLabs.shift();
                  }
                  for(let i = 0; i < arrValTeamLen; i++ ){
                    this.dataItemsValTeams.shift();
                  }

                  sessionStorage.removeItem("configValTeams");
                  sessionStorage.removeItem("configProducts");
                  sessionStorage.removeItem("configLabs");

                  if (this.dataItemsValTeams.length > 0) {
                    sessionStorage.setItem("configValTeams", JSON.stringify(this.dataItemsValTeams));
                  }
                  if (this.dataItemsProducts.length > 0) {
                    sessionStorage.setItem("configProducts", JSON.stringify(this.dataItemsProducts)); }
                  if (this.dataItemsLabs.length > 0) {
                    sessionStorage.setItem("configLabs", JSON.stringify(this.dataItemsLabs));
                  }


            }
              else {
                this.firstTimeUserConfigNotice = true;
              }

            },
            error: (e) => {
              if (e.status == 404){
                this.firstTimeUserConfigNotice = true;

              }
              else {
                alert('Get Config Error\n\n' + e.error.toString());

              }
                }
            });
   }
 catch(error)
       {
         const js = JSON.stringify(this.configRequest);
         console.log(js);
           var e = error as Error;
           console.log(e.message);
       };


}


public submit(): void {


  try {
    let arrLabs: any[] = [];
    let arrValTeams: any[] = [];
    let arrProducts: any[] = [];

    let x,y,z = 0;


    // let sLabs = sessionStorage.getItem("configLabs");
    // if (sLabs != undefined){
    //   this.selectedLabs = JSON.parse(sLabs);
    // }
    if (this.selectedLabs.length == 0){
      //arrLabs = this.copyDataItemsLabs;
      arrLabs = [];
    }
    else {
          for (x = 0; x < this.selectedLabs.length ; x++)
          {
            arrLabs.push({ name: this.selectedLabs[x]});
          }
    }


    // let teams = sessionStorage.getItem("configValTeams");
    // if (teams != undefined){
    //   this.selectedValTeams = JSON.parse(teams);
    // }
    if (this.selectedValTeams.length == 0){
      //arrValTeams = this.copyDataItemsValTeams;
      arrValTeams = [];
    }
    else {
          for (y = 0; y < this.selectedValTeams.length ; y++)
          {
            arrValTeams.push({ name: this.selectedValTeams[y]});
          }
    }



    // let sProducts = sessionStorage.getItem("configProducts");
    // if (sProducts != undefined){
    //   this.selectedProducts = JSON.parse(sProducts);
    // }
    if (this.selectedProducts.length == 0){
      //arrProducts = this.copyDataItemsProducts;
      arrProducts = [];
    }
    else {
        for (z = 0; z < this.selectedProducts.length ; z++)
        {
          arrProducts.push({ name: this.selectedProducts[z]});
        }
    }
    const indexOfObjectProducts = arrProducts.findIndex(object => {
      return object.name === "Products";
    });

    if (indexOfObjectProducts >= 0) {
            arrProducts.splice(indexOfObjectProducts, 1);
    }

    const indexOfObjectLabs = arrLabs.findIndex(object => {
      return object.name === "Labs";
    });

    if (indexOfObjectLabs >= 0) {
            arrLabs.splice(indexOfObjectLabs, 1);
    }

    const indexOfObjectVTeams = arrValTeams.findIndex(object => {
      return object.name === "Validation Teams";
    });

    if (indexOfObjectVTeams >= 0) {
            arrValTeams.splice(indexOfObjectVTeams, 1);
    }



    this.configRequest.labs = arrLabs;
    this.configRequest.validationTeams = arrValTeams;
    this.configRequest.products = arrProducts;
    this.configRequest.profileName = this.userName;
    this.configRequest.profileId = this.userWwid;
    this.configRequest.defaultSite = this.selectedHomeSite;
    this.configRequest.createdBy = new Person();
    this.configRequest.createdBy.name = this.userName;
    this.configRequest.createdBy.email = this.userEmail;
    this.configRequest.createdBy.wwid = this.userWwid;
    this.configRequest.configurationName = this.userName;
    this.configRequest.revision = '0';

    //this.fillConfig();
    this.getConfig(this.userWwid, true);


  } catch (e){
    var error = e as Error;
    alert("An error occurred while submitting your request: " + error.message.toString());
  }

}
/////////////////////////////////////////////////////////////////



private getConfig( wwid: string, update: boolean | null = false  ): void {

  this.disableSubmit = true;

   if (this.filterGetConfigSubscription){
     this.filterGetConfigSubscription.unsubscribe();
   }
  try {
    // this.filterGetConfigSubscription =
    //   this.createGateway.getConfiguration(wwid).subscribe({
    //     next: (result) => {
      let configNum = undefined;
      //sessionStorage.removeItem("configNumber")
      let temp = sessionStorage.getItem("configNumber");
      if (temp != undefined){
             configNum = JSON.parse(temp);
      }
          if (configNum == undefined){
            this.createConfig(this.configRequest);
          }
          else{
                this.getConfigResults = [this.configRequest]; //JSON.parse(JSON.stringify(result));
                this.configExists = true;
                this.currentConfigId = configNum != undefined ? configNum : "";
                this.configRequest.id = this.currentConfigId;
                //this.configRequest.configurationNumber = result.configurationNumber;
                var revision  = parseInt(this.configRequest.revision);
                revision++;
                var revisionToString = revision.toString();
                this.configRequest.revision = revisionToString;
                this.updateConfig(this.configRequest);
              }

          }

        // ,
        // error: (e) => {
        //     if (e.statusCode == 401){
        //       this.createConfig(this.configRequest);

        //     }
        //     else {
        //       alert('Get Config Error\n\n' + e.error.toString());

        //     }
        //       }
        // });

  catch(error)
        {
          const js = JSON.stringify(this.configRequest);
          console.log(js);
            var e = error as Error;
            console.log(e.message);
        };
}

private updateConfig( item: Config): void {

  let dataChanges: any[] = [];
  let dataChangeValTeams: any[] = [];

  //this.configRequest.validationTeams.forEach((element: any) => {dataChangeValTeams.push(element.name.text)});

  dataChanges.push({ value: this.configRequest.validationTeams, path: 'validationTeams', op: 'replace' });
  dataChanges.push({ value: this.configRequest.products, path: 'products', op: 'replace' });
  dataChanges.push({ value: this.configRequest.labs, path: 'labs', op: 'replace' });
  dataChanges.push({ value: this.configRequest.defaultSite, path: 'defaultSite', op: 'replace' });
  dataChanges.push({ value: item.createdBy, path: 'updatedBy', op: 'replace' });

  if (this.filterUpdateConfigSubscription){
    this.filterUpdateConfigSubscription.unsubscribe();
  }
  const js = JSON.stringify(item);
  console.log(js);
  const js2 = JSON.stringify(dataChanges);
  console.log(js2);
  try {

    this.filterUpdateConfigSubscription =this.createGateway.updateConfiguration(this.currentConfigId, dataChanges).subscribe({
      next: (result) => {
        this.submitDialogOpened = true;
        this.disableSubmit = false;

      },
      error: (e) => {
        this.disableSubmit = false;
        alert('Update Config Page Error\n\n' + e.message.toString());

      }
    })
  }
  catch
 (error)
        {
            var e = error as Error;
            console.log(e.message);
        };

}
/////////////////////////////////////////////////////////////////////
public createConfig(item: Config): void {

  if (this.filterCreateConfigSubscription){
    this.filterCreateConfigSubscription.unsubscribe();
  }
  try
  {
    this.filterCreateConfigSubscription =this.createGateway.createConfiguration(item).subscribe({
          next: (result) => {
            this.submitDialogOpened = true;
            this.disableSubmit = false;

          },
          error: (e) => {
            alert('Create Config Page Error\n\n' + e.message.toString());
          }
        })
  }
catch
 (error)
        {
            var e = error as Error;
            console.log(e.message);
        };
}

public fetchChildren(node: any): Observable<any[]> {
  // returns the items collection of the parent node as children
  return of(node.items);
}

  public allowCustom = true;
  public listItems: Array<string> = [
    "AN",
    "BA",
    "FM",
    "Global",
    "IS",
    "OR",
    "PG",
    "SC",
  ];

  public setUserWwid(): void {

    if (this.filterGetUsersSubscription){
      this.filterGetUsersSubscription.unsubscribe();
    }

    if (this.userEmail != '') {
      this.filterGetUsersSubscription = this.graphUsersGateway.getUsers(this.userEmail, 1, '', true).subscribe({
        next: (result) => {
          if (result[0].jobTitle != null) {
            this.userWwid = result[0].jobTitle;
          }
        },
        error: (e) => {
          alert('Get User Info Error\n\n' + e.error.toString());
        }
      });
    }
  }

  public setOwnerPlaceHolderFromMSAL(): void {
    var account = this.msalService.instance.getActiveAccount();
    if (account != undefined){
      this.userNameName = account.name;

    }
  }


  refreshRoles() {
    this.currentRoles['Administrator'] =  appSettings.isUserAMemberOf("RM_Admins");
    this.currentRoles['L2 Technician'] =  appSettings.isUserAMemberOf("RM_L2_Technicians"); // L2 Owners/CoOwners
    this.currentRoles['L1 Technician'] =  appSettings.isUserAMemberOf("RM_L1_Technicians"); // Readonly
    this.currentRoles['Lab Customer'] =  appSettings.isUserAMemberOf("RM_Lab_Customers"); // ReadOnly
    this.currentRoles['No Role'] =
      !this.currentRoles['Administrator'] && !this.currentRoles['L1 Technician'] &&
      !this.currentRoles['L2 Technician'] && !this.currentRoles['Lab Customer'];

    this.currentRole = Object.keys(this.currentRoles).filter(key => this.currentRoles[key] === true).join(', ');
  }


  public getLMSites(): void {    
    this.thirdPartyAppGateway.getLMSites(true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let sites: any[] = [];
        let sitesList: any[] = [];
        sitesList = JSON.parse(JSON.stringify(result));
        sitesList.forEach(item => {
          sites.push(item.name);
        });
        this.lmSiteList = sites.filter((n, i) => sites.indexOf(n) === i).sort();
        //console.log(this.lmSiteList);
      },
      error: (e) => {       
        alert('Sites Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        
      }
    });
  }

  public ddlHomeSiteChange(value: any) {
    this.selectedHomeSite = value;
  }

}
