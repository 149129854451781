import { Injectable } from '@angular/core';
import { map, Observable, tap, catchError, retry, from, timeout, mergeMap, of, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler } from 'app/common/errorHandler';
import { ErrorHandlerService } from 'app/errorHandling/error-handling.service';
import { RM_NotificationService } from 'app/common/notificationService';
import { AccessToken } from 'app/modules/shared/model/access-token';

@Injectable({
  providedIn: 'root'
})

export class FileGateway {
  [x: string]: any;
  private baseUrl = environment.services.environmentPrefix;
  public fileUrl = environment.services.FilesUrl;

  private header: HttpHeaders = new HttpHeaders();
  constructor(private _http: HttpClient, public httpErrorHandler: HttpErrorHandler, public notificationService: RM_NotificationService) {
  }

  // public downloadFile(data: HttpResponse<Blob>) {
  //   const contentDisposition = data.headers.get('content-disposition');
  //   const filename = this.getFilenameFromContentDisposition(contentDisposition);
  //   const blob = data.body;
  //   const url = window.URL.createObjectURL(blob);
  //   const anchor = document.createElement("a");
  //   anchor.download = filename;
  //   anchor.href = url;
  //   anchor.click();
  // }

  // private getFilenameFromContentDisposition(contentDisposition: string): string {
  //   const regex = /filename=(?<filename>[^,;]+);/g;
  //   const match = regex.exec(contentDisposition);
  //   const filename = match.groups['filename'];
  //   return filename;
  // }

  getUrl(modifier: string, filename: string): string {
    if (modifier) {
      modifier = '/' + modifier;
    }
    const url = `${this.fileUrl}${modifier}/${encodeURIComponent(filename)}`;
    return url;
  }

  getUrlforFiles(modifier: string, filenames: string[], bbNumbers?: string[], recipeId?: string): string {
    if (modifier) {
      modifier = '/' + modifier;
    }

    const docList = filenames.map((file) => (`docList=${encodeURIComponent(file)}`)).join('&');
    const bbList = bbNumbers?.map((bb) => (`bbList=${bb}`)).join('&');
    // const url = `${this.fileUrl}${modifier}?${docList}`; // backup
    let url;
    // WH 25Jul2023 - pass bbNumbers as doc separator
    // WH 31Oct2023 - pass recipeId to API
    if (!recipeId) {
      recipeId = "NA";
    }
    url = `${this.fileUrl}${modifier}?${docList}&${bbList}&recipeId=${recipeId}`;
    return url;
  }

  getFiles(): Observable<any> {
    // console.log('Getting all Sites');
      return this._http.get<Blob>(this.fileUrl)
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data) => data),
          catchError(error => {
            this.notificationService.addErrorNotification(`getFiles: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            console.log('Caught in getFiles. Returning 0');
            return throwError(() => error);
          })
        );
  }

  getFile(filename: string): Observable<Blob> {
      this.header = this.header.set('Content-Type', 'application/pdf');
      const url = `${this.fileUrl}/${encodeURIComponent(filename)}`;
      return this._http.get(url, { headers: this.header, responseType: 'blob'})
       .pipe(
            retry(3),
            timeout(60000),
            // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
            map((data: Blob) => {
              return data;
            }),
            catchError(error => {
              this.notificationService.addErrorNotification(`getFile: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
              console.log('Caught in getFile. Returning 0');
              return throwError(() => error);
            })
          );
  }

  getCombinedPdfs(filenames: string[], bbNumbers: string[], recipeId: string): Observable<any> {
      this.header = this.header.set('Content-Type', 'application/pdf');
      const docList = filenames.map((file) => (`docList=${encodeURIComponent(file)}`)).join('&');
      const bbList = bbNumbers.map((bb) => (`bbList=${bb}`)).join('&'); // WH 26July2023 - pass bbNumbers as doc separator
      // const url = `${this.fileUrl}/combinedpdfs?${docList}`; // original

      // WH 26Jul2023 - pass bbNumbers as doc separator
      // WH 31Oct2023 - pass recipeId
      let url;
      if (!recipeId) {
        recipeId = "NA";
      }
      url = `${this.fileUrl}/combinedpdfs?${docList}&${bbList}&recipeId=${recipeId}`;

      return this._http.get(url, { headers: this.header, responseType: 'blob' })
        .pipe(
          retry(4),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data: Blob) => {
            return data;
          }),
          catchError(error => {
            this.notificationService.addErrorNotification(`getCombinedPdfs: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            console.log('Caught in getCombinedPdfs. Returning 0');
            return throwError(() => error);
          })
        );
  }

  getPdfFile(name: string): Observable<any> {
      this.header = this.header.set('Content-Type', 'application/pdf');

      const url = `${this.fileUrl}/PDF/${encodeURIComponent(name)}`;
      return this._http.get(url, { headers: this.header, responseType: 'blob' })
        .pipe(
          retry(3),
          timeout(60000),
          // tap(data => console.log('getJobs: ' + JSON.stringify(data) )),
          map((data: Blob) => {
            return data;
          }),
          catchError(error => {
            this.notificationService.addErrorNotification(`getPdfFile: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
            console.log('Caught in getPdfFile. Returning 0');
            return throwError(() => error);
          })
        );
  }
/*
  private setAuthenticationHeader(): Promise<HttpHeaders> {
    let accessToken: string | null;
    let tokenType: string | null;

    if ('AppTokenExpiration' in localStorage) {
      let expirationDate: any = localStorage.getItem('AppTokenExpiration');
      let tokenExpirationDate: Date = new Date(expirationDate);
      let newExpirationDate = new Date(tokenExpirationDate.setMinutes(tokenExpirationDate.getMinutes() - 10));
      let todayDate: Date = new Date();

      // If token is about to expire get a new token.
      if (todayDate.getTime() < newExpirationDate.getTime()) {
        accessToken = localStorage.getItem('AppToken');
        tokenType = localStorage.getItem('AppTokenType');
        this.header = new HttpHeaders().set('Authorization', tokenType + ' ' + accessToken);
        return Promise.resolve(this.header);
      }
      else {
        return this.getAccessTokenSubscriptionResult().then((value) => {
          return Promise.resolve(this.header)
        })
          .catch(err => {
            alert('Unable to retrieve access token.\n' + err.toString());
            return Promise.reject(err)
          });
      }
    }
    else {
      return this.getAccessTokenSubscriptionResult().then((value) => {
        return Promise.resolve(this.header)
      })
        .catch(err => {
          alert('Unable to retrieve access token.\n' + err.toString());
          return Promise.reject(err)
        });
    }
  }

  private getAccessTokenSubscriptionResult(): Promise<HttpHeaders> {
    let accessToken: string | null;
    let tokenType: string | null;

    return new Promise<HttpHeaders>(resolve => {
      this.getAccessToken(true).subscribe({
        next: (result) => {
          localStorage.setItem('AppToken', result.accessToken);
          localStorage.setItem('AppTokenType', result.tokenType);
          localStorage.setItem('AppTokenExpiration', result.expiresOn.toString());
        },
        error: (err) => {
          return Promise.reject(err);
        },
        complete: () => {
          accessToken = localStorage.getItem('AppToken');
          tokenType = localStorage.getItem('AppTokenType');
          this.header = new HttpHeaders().set('Authorization', tokenType + ' ' + accessToken);
          resolve(this.header);
        }
      });
    });
  } */
}
