import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccessToken } from "app/modules/shared/model/access-token";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AuthenticationHeader {
  public header: HttpHeaders = new HttpHeaders();
  private baseUrlLink = environment.services.environmentPrefix;
  private createUrlLink = environment.services.CreateUrl;

  constructor(private _httpClient: HttpClient) {

  }

  public getAccessToken(forceRefresh: boolean = false) : Observable<AccessToken> {
    return this._httpClient.get<AccessToken>(`${this.baseUrlLink}${this.createUrlLink}/authentication/accesstoken?clientId=7616e52c-77ae-4b81-979b-2333272fefad&forceRefresh=${forceRefresh}`);
  }

  public setAuthenticationHeader(): Promise<HttpHeaders> {
    let accessToken: string | null;
    let tokenType: string | null;

    if ('AppTokenExpiration' in localStorage) {
      let expirationDate: any = localStorage.getItem('AppTokenExpiration');
      let tokenExpirationDate: Date = new Date(expirationDate);
      let newExpirationDate = new Date (tokenExpirationDate.setMinutes(tokenExpirationDate.getMinutes() - 10));
      let todayDate: Date = new Date();

      // If token is about to expire get a new token.
      if (todayDate.getTime() < newExpirationDate.getTime()) {
        accessToken = localStorage.getItem('AppToken');
        tokenType = localStorage.getItem('AppTokenType');
        this.header = new HttpHeaders().set('Authorization', tokenType + ' ' + accessToken);
        return Promise.resolve(this.header);
      }
      else {
        return this.getAccessTokenSubscriptionResult().then((value) => {
          return Promise.resolve(this.header)
        })
        .catch(err => {
          alert('Unable to retrieve access token.\n' + err.toString());
          return Promise.reject(err)
        });
      }
    }
    else {
      return this.getAccessTokenSubscriptionResult().then((value) => {
        return Promise.resolve(this.header)
      })
      .catch(err => {
        alert('Unable to retrieve access token.\n' + err.toString());
        return Promise.reject(err)
      });
    }
  }

  private getAccessTokenSubscriptionResult() : Promise<HttpHeaders> {
    let accessToken: string | null;
    let tokenType: string | null;

    return new Promise<HttpHeaders>(resolve => {
      this.getAccessToken(true).subscribe({
        next: (result) => {
          localStorage.setItem('AppToken', result.accessToken);
          localStorage.setItem('AppTokenType', result.tokenType);
          localStorage.setItem('AppTokenExpiration', result.expiresOn.toString());
        },
        error: (err) => {
          return Promise.reject(err);
        },
        complete: () => {
          accessToken = localStorage.getItem('AppToken');
          tokenType = localStorage.getItem('AppTokenType');
          this.header = new HttpHeaders().set('Authorization', tokenType + ' ' + accessToken);
          resolve(this.header);
        }
      });
    });
  }
}
