//import { environment } from '../../../environments/environment';
import { Injectable, inject } from '@angular/core';
//import { SeverityLevel } from './severity-level.model';
//import { AppConfig } from '../../app.config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
//import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'environments/environment';
import { StringParsingService } from '../common/string-parsing.service';
import { Router } from '@angular/router';

// API Documentation
// https://github.com/microsoft/applicationinsights-js
@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private readonly appInsights: ApplicationInsights | undefined;
  //private readonly angularPlugin = new AngularPlugin();
  private readonly router = inject(Router);
  constructor(private stringParsingService: StringParsingService) {

    const key = this.stringParsingService.parseConnectionString(environment.appInsights.connectionString, 'InstrumentationKey')
    console.log(key);
    //const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        
        // instrumentationKey: "", //environment.appInsights.instrumentationKey,
        instrumentationKey: key, // WH - resolve 'track'(network log) call invalid instrumentationKey error
        //extensions: [<any>this.angularPlugin],
        enableAutoRouteTracking: true, // option to log all route changes
        // extensionConfig: {
        //   [this.angularPlugin.identifier]: { router: Router  } // You can pass the Angular router here if needed
        // }
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights!.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights!.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights!.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights!.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights!.trackTrace({ message: message}, properties);
  }
}
