import { Employee } from "./employee";
import { Site } from "./site";
import { Person } from './person';
import { SelectedBuildingBlock } from './recipe';
import { LabInfo } from './lab';

export class WorkQ {
    id: string = "";
    workqNumber: string = "";
    labs: LabInfo[] = [];
    recipeId: string = "";
    recipeNumber: string = "";    
    recipeName: string = "";    
    buildingBlocks: SelectedBuildingBlock[] = [];        
    hsdId: string = "";
    hsdTitle: string = "";    
    hsdDescription: string = "";
    hsdReason: string = "";    
    hsdOwner = new Person;    
    hsdPriority: string = "";    
    mode: string = "";
    category:string = "";
    rampUpStartDate = new Date();
    rampUpEndDate = new Date();
    daysInWip: number = 0;
    revision = 0;
    application: string = '';    
    isArchived = false;
    createdBy = new Person;
    createdDate = new Date();
    updatedBy = new Person;
    updatedDate = new Date();
}