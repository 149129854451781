import { RM_NotificationService } from 'app/common/notificationService';
import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
// @Injectable({
//   providedIn: 'root',
// })
@Injectable()
export class ErrorHandlerService { //extends ErrorHandler {

    constructor(private loggingService: LoggingService, public notificationService: RM_NotificationService) {
//        super();
    }

    public handleError(error: Error) {
        this.loggingService.logException(error); // Manually log exception
        const originalError = this.getOriginalError(error);
        if (originalError !== error) {
            this.loggingService.logException(originalError); // Manually log original exception
            this.notificationService.addErrorNotification(error.name, error.message);
        }
        return throwError(() => error);
    }

    public handleHttpError(error: HttpErrorResponse | any) {
      const httpError: Error = new Error();
      let errMsg: string;
      let err: string;
      const errField = 'errors';
      const msgField = 'message';
      // console.log(error);
      if (error instanceof HttpErrorResponse) {
        // const err = error.error !== null ? error.error.message : error.message;
        if (error.error !== null) {
          if (error.status === 400) {
            err = JSON.stringify(error);
          } else if (error.status === 404) {
            err = 'Item not found: ' + JSON.stringify(error);
          } else if (error.status === 500) {
            err = 'Internal Server Error';
          } else {
            err = error.message;
          }
        } else {
          err = error.message;
        }
        httpError.name = err;
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        httpError.message = errMsg;
        console.log(httpError);
      } else {
        errMsg = error.error !== null ? error.error.message : error.message;
        httpError.message = errMsg;
      }

      // this.loggingService.logException(error); // Manually log exception
      // const originalError = this.getOriginalError(error);
      // if (originalError !== error) {
      //     this.loggingService.logException(originalError); // Manually log original exception
      //     this.notificationService.addErrorNotification(error.name, error.message);
      // }
      try {
        this.notificationService.addErrorNotification(error.name, httpError.message);
      } catch {

      }
      return throwError(() => error);
    }

    private getOriginalError(error: any) {
        while (error && error.originalError) {
            error = error.originalError;
        }
        return (error);
    }
}
