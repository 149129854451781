import { Component, OnInit } from '@angular/core';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { BadgeAlign } from '@progress/kendo-angular-indicators';
import { DrawerItem, DrawerMode } from '@progress/kendo-angular-layout';
import { RM_NotificationService } from 'app/common/notificationService';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  commitVersion: string = "";

  public toggleText = "Hide";
  public show = false;

  notificationIcon = 'arrow-chevron-up'

  constructor(public rm_notificationService: RM_NotificationService) { }

  ngOnInit(): void {
    this.commitVersion = environment.commitVersion;

    // included for demo purposes
    // this.notificationService.addErrorNotification('This is an Error', 'No Really it is.');
    // this.notificationService.addInfoNotification('This is an Error', 'No Really it is.');
    // this.notificationService.addWarningNotification('This is an Error', 'No Really it is.');
  }

  public onToggle(): void {
    this.show = !this.show;
    this.show ? 'arrow-chevron-dn' : 'arrow-chevron-up'
    this.toggleText = this.show ? "Hidе" : "Show";
  }

  public get align(): BadgeAlign {
    return { vertical: 'top', horizontal: 'start' };
  }
}
