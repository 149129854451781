<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<!-- Toolbar -->
<app-navigation-bar id="topNavigationBar"></app-navigation-bar>
<!-- <kendo-loader class="loading" *ngIf="loading" type="converging-spinner" size="medium"></kendo-loader> -->
<app-overlay *ngIf="loading"></app-overlay>

<div class = "test_message"  id = "div_warning">
  WARNING: This is TEST environment only for testing. Do not create production BB/Recipe/WQ/CCs here.  Please start work on RM Production environment. <a href="https://rm.laas.intel.com/#/" class="rm_link_prod"> Click Here </a> 
</div>
<!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
<div class="mb-3">
  <router-outlet></router-outlet>
</div>

<app-footer id="bottomFooter"></app-footer>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

