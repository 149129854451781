import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationHeader } from "app/common/authentication-header";
import { HttpErrorHandler } from "app/common/errorHandler";
import { RM_NotificationService } from "app/common/notificationService";
import { ItemBoardType, ItemProduct, ItemProgram } from "app/modules/shared/model/item";
import { Site, Activities, Lab, Project, ValidationTeams, Owner, Host, UpdateHost } from "app/modules/shared/model/lab-manager";
import { ValidationTeam } from "app/modules/shared/model/validation-team";
import { environment } from "environments/environment";
import { from, Observable, mergeMap, retry, timeout, map, catchError, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class ThirdPartyAppGateway extends AuthenticationHeader {
  private baseUrl = environment.services.environmentPrefix;
  private thirdPartyAppUrl = environment.services.ThirdPartyAppUrl;

  constructor(private _http: HttpClient, private httpErrorHandler: HttpErrorHandler, private notificationService: RM_NotificationService) {
    super(_http);
  }

  public getSiliconPrograms(keyword: string, supressNotification = false) : Observable<ItemProgram> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<ItemProgram>(`${this.baseUrl}${this.thirdPartyAppUrl}/items/programs/search?keyword=${encodeURIComponent(keyword)}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getSiliconPrograms: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  public getProducts(programs: string[], supressNotification = false) : Observable<ItemProduct> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.post<ItemProduct>(`${this.baseUrl}${this.thirdPartyAppUrl}/items/products/search`,  programs, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getProducts: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  public getBoardTypes(boardPbaID: string, supressNotification = false) : Observable<ItemBoardType> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<ItemBoardType>(`${this.baseUrl}${this.thirdPartyAppUrl}/items/boardtype/search?boardPbaID=${boardPbaID}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getBoardTypes: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Sites
  public getLMSites(supressNotification = false) : Observable<Site> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Site>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/site/search`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMSites: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Activities
  //Valid Names - Debug, Development-No Target, Execution, FPGA Farm, Generator, Lab Infrastructure, Power-On, Test & Measurement

  public getLMActivities(supressNotification = false) : Observable<Activities> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Activities>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/activities/search`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMActivities: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Project Details
  public getLMProjects(siteName: string, supressNotification = false) : Observable<Project> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Project>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/project/search/${siteName}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMActivities: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Labs
  public getLMLabs(siteName: string, supressNotification = false) : Observable<Lab> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Lab>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/lab/search/${siteName}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMActivities: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Validation Teams
  public getLMValidationTeams(siteName: string, supressNotification = false) : Observable<ValidationTeams> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<ValidationTeams>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/validationteams/search/${siteName}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMValidationTeams: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Owners Detail
  public getLMOwners(siteName: string, supressNotification = false) : Observable<Owner> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Owner>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/owner/search/${siteName}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMOwners: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  // Get Lab Manager Host Details
  public getLMHostDetails(hostName: string, supressNotification = false) : Observable<Host> {
    return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
      this.header = result;
      return this._http.get<Host>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/host/search/${hostName}`, {headers: this.header}).pipe(
        retry(3),
        timeout(60000),
        map((data) => data),
        catchError(error => {
          if (!supressNotification) {
            this.notificationService.addErrorNotification(`getLMOwners: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
          }
          return throwError(() => error);
        })
      );
    }));
  }

  public putLMHostDetails(hostName: string, updates: any[], supressNotification = false) : Observable<any> {
  return from(this.setAuthenticationHeader()).pipe(mergeMap(result => {
    this.header = result;
    return this._http.put<any>(`${this.baseUrl}${this.thirdPartyAppUrl}/configurationmanagement/host/${hostName}`, updates, {headers: this.header}).pipe(
      retry(3),
      timeout(60000),
      map((data) => data),
      catchError(error => {
        if (!supressNotification) {
          this.notificationService.addErrorNotification(`Update Host Details: ${error.name} - ${this.httpErrorHandler.getHttpError(error.status)}`, error.message);
        }
        return throwError(() => error);
      })
    );
  }));

}  

//  public getHSDSites(): Observable<any> {
//   return this._http.get<any>(`https://gts2-api.apps1-pg-int.icloud.intel.com/AdminManagement/GetSite'`);
// }

  
}
