<div class = "view-content">
  <div class="mb-5">
    <br>
    <div class="pane-content" style="text-align: right; padding: 10px">
      <button type="button" class="btn btn-primary" style="width: 250px; height: 250x; font-size: large"
        (click)="submit()" [disabled]="disableSubmit">
        <i class="intelicon-passed-completed-outlined menu-icon" aria-hidden="true" style="font-size: large"></i>
        Save Configuration
      </button>
    </div>

    <kendo-dialog title="Success!" *ngIf="submitDialogOpened" (close)="submitDialogClose()" [minWidth]="250" [width]="450"
      (close)="submitDialogAction('exit')">
      <i aria-hidden="true"></i> Configuration has been saved to your profile!
      <kendo-dialog-actions>
        <button type="button" class="k-button k-primary" (click)="submitDialogAction('exit')" primary="true">Done</button>
      </kendo-dialog-actions>
    </kendo-dialog>

    <div>
      <h1 style="color:#0071C5; text-align:center;" font face="Intel Clear">Welcome, {{userName}}!</h1>
      <br>
      <br>
      <div class="nav-item dropdown" align="middle" *ngIf="isUserLoggedIn()">
        <img [src]="imageToShow" style="width:10%;" *ngIf="!isImageLoading; else noImageFound" />
        <ng-template #noImageFound>
        </ng-template>
      </div>
      <br>
      <div style="width: 75%;margin: 0px auto;">
        <kendo-expansionpanel title=" User Roles: [{{currentRole}}]" [expanded]="false" style="">
          <div class="pane-content" style="text-align: center;">
            <h3>Current User Roles :</h3>
            <p>{{currentRole}}</p>
            <p>Go to <a target="_new" href="https://ags.intel.com/identityiq/home.jsf">AGS</a> to add or remove Recipe
              Management roles and search as follows:</p>

            <table class="table" style=" width:50%; margin: 0px auto; ">
              <thead class="thead-dark">
                <tr>
                  <th>Role</th>
                  <th>AGS Search Term</th>
                </tr>
              </thead>
              <tr style="text-align:center; vertical-align: top;">
                <td>Administrator</td>
                <td>RM_Admin</td>
                <td style="text-align:left">This entitlement is for superusers or administrators for the Recipe
                  Management
                  system. This entitlement
                  grants access to Recipe Management.</td>
              </tr>
              <tr style="text-align:center; vertical-align: top;">
                <td>L2 Technician</td>
                <td style="text-align:left">RM_L2_Technicians</td>
                <td style="text-align:left">Allows users to perform L2 technician activities in Recipe Management. This
                  entitlement grants access to Recipe Management.</td>
              </tr>
              <tr style="text-align:center; vertical-align: top;">
                <td>L1 Technician</td>
                <td>RM_L1_Technicians</td>
                <td style="text-align:left">Allows users to perform L1 technician activities in Recipe Management. This
                  entitlement grants access to Recipe Management.</td>
              </tr>
              <tr style="text-align:center; vertical-align: top;">
                <td>Lab Customers</td>
                <td>RM_Lab_Customers</td>
                <td style="text-align:left">This entitlement allows lab customers to perform activities in Recipe
                  Management.
                  This entitlement grants access to Recipe Management.</td>
              </tr>
            </table>


          </div>
        </kendo-expansionpanel>
        <kendo-expansionpanel title="Default Values" [expanded]="true" style="margin: 0px auto;">
          <div class="pane-content" [style.display]="firstTimeUserConfigNotice ? 'block' : 'none'">
            <h2 style="color:#0071C5; text-align:center;" font face="Intel Clear">You do not have a default
              configuration
              saved.
            </h2>
          </div>

          <h2 style="color:#0071C5; text-align:center;" font face="Intel Clear"> Select default sites, validation teams,
            products.</h2>
          <div class="pane-content" [style.display]="firstTimeUserConfigNotice ? 'block' : 'none'">

            <h2 style="font-size:  x-large; color:#0071C5; text-align:center;" font face="Intel Clear">(This can be
              modified
              by
              visiting the settings section)</h2>
          </div>
          <br>
          <div style="display:flex">

            <div class="example-wrapper" style="margin-left: 8%; margin-right: 10px; width: 250px;">
              
              <div style = "display:flex;">
                <p>CM Default Site</p> 
                <div id = "hover_default_site" style="flex: 1" kendoTooltip class="intelicon-information-outlined">
                  <span class="tooltiptext">The CM default site represents the user's home site location.</span>
                </div>
              </div>
              <kendo-combobox [data]="lmSiteList" [value]="selectedHomeSite" (valueChange)="ddlHomeSiteChange($event)">
              </kendo-combobox>
            </div>

            <div class="example-wrapper" style="margin-left: 5%; margin-right: 10px; width: 250px;">
              <!-- Selected Values: {{ selectedLabs | json }} -->
              <p>Site(s):</p>
              <kendo-multiselecttree kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="lab"
                textField="text" valueField="text" childrenField="items" [valuePrimitive]="true" [(value)]="selectedLabs"
                [fetchChildren]="fetchChildren" [expandedKeys]="['0']" class="configs" [dataItems]="dataItemsLabs">
              </kendo-multiselecttree>
            </div>

            <br>

            <div class="example-wrapper" style="margin-left: 5%; margin-right: 10px; width: 300px;">
              <p>Validation Team(s):</p>
              <kendo-multiselecttree kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="valTeam"
                textField="text" valueField="text" childrenField="items" [valuePrimitive]="true"
                [(value)]="selectedValTeams" [fetchChildren]="fetchChildren" [expandedKeys]="['0']" class="configs"
                [dataItems]="dataItemsValTeams">
              </kendo-multiselecttree>
            </div>

            <br>
            <!-- [checkableSettings]="checkableSettings" -->
            <div class="example-wrapper" style="margin-left: 5%; margin-right: 10px; width: 400px;">
              <p>Product(s):</p>
              <kendo-multiselecttree kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="prod"
                textField="text" valueField="text" childrenField="items" [valuePrimitive]="true"
                [(value)]="selectedProducts" [fetchChildren]="fetchChildren" [expandedKeys]="['0']" class="configs"
                [dataItems]="dataItemsProducts">
              </kendo-multiselecttree>
            </div>

          </div>


        </kendo-expansionpanel>
      </div>
    </div>
  </div>
